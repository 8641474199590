import { prefixObjectKeys } from '../prefixObjectKeys'
import { Messages } from './types'

export const root = {
  accumulatedManYears: 'Akkumulerte årsverk siden stiftelse',
  add: 'Legg til',
  addImage: 'Legg til bilde',
  amount: 'Beløp (NOK)',
  appraisal: 'Verdsettelse',
  approvalMessages: 'Seksjonen har {count} endringsforespørsler',
  approve: 'Godkjenn',
  approved: 'Godkjent',
  autoSaved: 'Automatisk lagret',
  autoSaving: 'Endringer blir lagret automatisk',
  back: 'Tilbake',
  backToTop: 'Tilbake til toppen',
  cancel: 'Avbryt',
  change: 'Endre',
  changeRequested: 'Endringer forespurt',
  changesSaved: 'Endringene ble lagret',
  chooseFile: 'Velg fil',
  chooseAnotherFile: 'Velg en annen fil',
  close: 'Lukk',
  comment: 'Kommentar',
  commentPlural: 'Kommentarer',
  complete: 'Fullfør',
  completeProfile: 'Fullfør profil',
  confirm: 'Bekreft',
  confirmDelete: 'Er du sikker?',
  connect: 'Koble til',
  contactInformation: 'Kontakt{softHyphen}informasjon',
  contributor: 'Bidragsyter',
  contributors: 'Bidragsytere',
  copied: 'Kopiert',
  copiedToClipboard: 'Kopiert til utklippstavlen',
  countryCode: 'NO',
  currencySuffix: 'kr',
  date: 'Dato',
  declined: 'Avvist',
  delete: 'Slett',
  deleteAll: 'Slett all data',
  deleted: 'Slettet',
  describeYourInquiry: 'Gi en kort beskrivelse av din henvendelse.',
  disconnect: 'Koble fra',
  documentation: 'Dokumentasjon',
  download: 'Last ned',
  downloadTemplate: 'Last ned mal',
  drop: 'Kanseller',
  dropped: 'Kansellert',
  edit: 'Rediger',
  egenKapital: 'Egenkapital (NOK)',
  email: 'Epost',
  emissionProcessNotFound: 'Fant ikke emisjons-prosess',
  errorMessage: 'Feilmelding',
  explanation: 'Forklaring',
  requiredFieldIsValid: 'Påkrevd felt har gyldig innhold',
  fileUploadUnavailable: '<gray>Opplasting ikke tilgjengelig</gray>',
  folkeinvest: 'Folkeinvest',
  from: 'Fra',
  fundraisingTarget: 'Anslått kapitalbehov (målsum) (NOK)',
  google: 'Google',
  goToDashboard: 'Gå til dashboard',
  hide: 'Skjul',
  hideHistory: 'Skjul historikk',
  id: 'id',
  import: 'Importer',
  importFromCSV: 'Importer fra CSV-fil',
  incompleteProfile: 'Brukerprofil mangler obligatoriske felt.',
  inProgress: 'Pågående',
  institution: 'Institusjon',
  issuer: 'Utsteder',
  itemCountSuffix: '{count} stk',
  limitEquity: 'Aksjekapital',
  limitValuation: 'Selskapets verdi',
  loading: 'Laster inn',
  loadingFiles: 'Laster opp filer ...',
  locked: 'Låst',
  logIn: 'Logg inn',
  logOut: 'Logg ut',
  missing: 'Mangler',
  mustBeLoggedIn: 'Du må være logget inn',
  myEmissions: 'Mine emisjoner',
  name: 'Navn',
  nationalId: 'Fødselsnummer',
  no: 'Nei',
  noData: 'Ingen data',
  noFacilitators: 'Fant ingen tilretteleggere',
  nominalValuePerShare: 'Pålydende per aksje',
  noOptions: 'Ingen valg tilgjengelig',
  notAvailable: 'Ikke tilgjengelig',
  notGiven: 'Ikke oppgitt',
  notSubmitted: 'Ikke innsendt',
  notVerified: 'Ikke verifisert',
  numberOfFulltimeEmployees: 'Antall fulltidsansatte',
  numberOfItemsMarked: '{count} markert',
  numberOfItemsSelected: '{count} valg',
  numberOfMonths: 'Antall måneder',
  numberOfParttimeEmployees: 'Antall deltidsansatte',
  numberOfShareholders: 'Antall aksjonærer',
  numberOfShares: 'Antall aksjer',
  ok: 'OK',
  open: 'Åpne',
  options: 'valg',
  or: 'eller',
  owner: 'Eier',
  password: 'Passord',
  pricePerShare: 'Pris per aksje',
  readAndUnderstood: 'Jeg bekrefter at informasjonen er lest og forstått',
  reasoning: 'Begrunnelse',
  register: 'Registrer',
  reject: 'Avslå',
  rejected: 'Avslått',
  remove: 'Fjern',
  removeFilter: 'Slett filter',
  required: 'Kreves',
  roles: 'Roller',
  save: 'Lagre',
  saved: 'Lagret',
  saving: 'Lagrer',
  select: 'Velg',
  selectFile: 'Velg fil',
  selectOneOrMore: 'Velg en eller flere',
  send: 'Send',
  sendForApproval: 'Send til godkjenning',
  shareEmitted: 'Andel utstedt',
  shares: 'Aksjer',
  show: 'Vis',
  signUp: 'Registrer ny profil',
  somethingWentWrong: 'Noe gikk galt, vennligst prøv igjen',
  couldNotSaveContactSupport: 'Noe gikk galt, endringen ble ikke lagret. Ta kontakt med support.',
  title: 'Tittel',
  understand: 'Ok, jeg forstår',
  unknown: 'Ukjent',
  unknownStatus: 'Ukjent status',
  unsure: 'Usikker',
  until: 'Til',
  uploading: 'Laster opp...',
  uploaded: 'Lastet opp',
  uploadFailed: 'Feil ved opplasting av fil',
  valuationPostMoney: 'Verdsettelse (Post-money)',
  valuationPreMoney: 'Verdsettelse (Pre-money)',
  valueDifferentialPerShare: 'Overkurs',
  verifiedProfile: 'Verifisert brukerprofil',
  viewDetails: 'Se detaljer',
  viewAllHistory: 'Se all historikk',
  wait: 'Vent...',
  yes: 'Ja',
  yesConfirm: 'Ja, godkjenn',
}

export const homepage = {
  'intro.header': 'Invester i det <ul>du</ul> tror på',
  'intro.description': 'Plattform for kjøp av aksjer i unoterte selskap',
  'intro.seeInvestmentOffers': 'Se investeringsmuligheter',
  'intro.myCompanySeeksCaptital': 'Mitt selskap søker egenkapital',
}

export const statistics = {
  target: 'Mål',
  max: 'Maks',
  min: 'Min',
  invested: 'Investert',
  followers: 'Følgere',
  pageViews: 'Visninger',
  publishingDate: 'Publiseringsdato',
  subscriptionWindow: 'Tegningsvindu',
  subscriptionWindowClosed: 'Tegningsvindu lukkes',
  subscriptionWindowClosedMobile: 'Start',
  subscriptionWindowOpened: 'Tegningsvindu åpnes',
  subscriptionWindowOpenedMobile: 'Slutt',
}

export const auth = {
  login: 'Logg inn',
  loginWithGoogle: 'Logg inn med Google',
  newUser: 'Ny profil',
  registerNewUser: 'Registrer ny profil',
  registerWithEmail: 'Registrer med e-post',
  registerWithGoogle: 'Registrer med Google',
  resetPassword: 'Nullstill passord',
  isRequired: ' (påkrevd)',
  invalidEmail: 'Må være gyldig e-post adresse',
  noPassword: 'Vennligst oppgi passord',
  passwordTooShort: 'Passord for kort',
  rememberMe: 'Husk meg / Forbli innlogget',
  goToLogin: 'Gå til innlogging',
  forgotPassword: 'Glemt passord?',
}

export const editInvestmentOffer = {
  'campaignCard.admin.publishCard': 'Publiser emisjonskort',
  'campaignCard.admin.updatePublishedCard': 'Oppdater publisert emisjonskort',
  'campaignCard.admin.copyEmbedCode': 'Kopier html-kode for å bygge inn emisjonskort',
  'campaignCard.admin.copyComingSoonLink': 'Kopier lenke til kommer-snart-kort',
  'campaignCard.admin.comingSoonLink': 'Lenke til kommer-snart-kort',
  'campaignCard.admin.embedCode': 'Bygg inn kommer-snart-kort',
  'campaignCard.pageTitle': 'Emisjonskort',
  'campaignCard.pageIntro':
    'Emisjonskort må ha tekst og bilde for at det kan vises på nettsiden. Kortet kan redigeres senere fra dashboardet. Emisjonskortet bør ferdigstilles så snart som mulig for å sikre tilstrekkelig interesse til emisjonen.',
  'campaignCard.uploadImage': 'Last opp bilde',
  'campaignCard.changeImage': 'Rediger bilde',
  'campaignCard.chooseFile': 'Dra fil hit eller <a>velg fil</a>',
  'campaignCard.dragToCrop':
    'Forstørr, forminsk og dra bilde for å få ønsket utsnitt. <reset>Velg en annen fil</reset>',
  'campaignCard.wantToApprove': 'Ferdig med utfylling av emisjonskort?',
  'campaignCard.submitForApproval': 'Lever emisjonskort',
  'campaignCard.reSubmitForApproval': 'Seksjonen er allerede godkjent. Lever oppdatert emisjonskort til godkjenning?',
  'campaignCard.submittedForApproval': 'Emisjonskort er sendt til kontroll',
  'campaignCard.backToDashboard': 'Gå til dashboard',
  'campaignCard.textAndImage': 'Tekst og bilde til emisjonskort',
  investmentOfferNotFound: 'Investeringstilbudet finnes ikke',
  investmentOfferTerminated:
    'Prosessen er avsluttet, ta kontakt med tilrettelegger dersom du har spørsmål knyttet til dette',
  'dashboard.companyNamePlaceholder': 'Selskapsnavn',
}

export const investmentOfferPresentation = {
  video: 'Video',
  coverImage: 'Investeringstilbudbilde',
  introduction: 'Introduksjon',
  'introduction.description':
    'Gi en overordnet beskrivelse av selskapet, slik at investorer får innblikk i hvem dere er. Vær kort og presis, og tenk “executive summary”.',
  whyInvest: 'Hvorfor investere?',
  'whyInvest.description':
    'En klar, balansert og ikke villedende beskrivelse som gir investorer kjennskap til hvorfor dette er en investeringsmulighet som en bør vurdere. Punktliste kan gjerne benyttes.',
  problemsToSolve: 'Problem som skal løses',
  'problemsToSolve.description': 'Beskriv problemet/utfordringen som selskapet skal løse.',
  singlePurposeVehicle: 'Single Purpose Vehicle (SPV)',
  'singlePurposeVehicle.description':
    'Beskriv hvorfor selskapet har valgt å tilrettelegge for SPV, hva dette innebærer for emisjonen og enkelte investorer. <templateLink>Se mal her</templateLink>',
  ourSolution: 'Vår løsning',
  'ourSolution.description':
    'Gi en beskrivelse av hva dere tilbyr for å løse problemet/utfordringen. Hva er tjenesten eller produktet dere tilbyr kundene - og hvem er kundene? Beskrivelsen bør være overordnet, men samtidig detaljert nok til å gi investorer god nok innsikt.',
  teamAndOrganization: 'Team',
  'teamAndOrganization.description': 'Gi en introduksjon av teamet og en beskrivelse av organisasjonen, inkl styret.',
  businessModel: 'Forretningsmodell',
  'businessModel.description': 'Beskriv selskapets forretningsmodell og eventuelt planer for utvikling av denne.',
  milestones: 'Milepæler',
  'milestones.description':
    'Hvilke milepæler har selskapet oppnådd så langt, og hvilke konkrete resultater er levert? Hvilke milepæler har dere planlagt for i den kommende perioden? Bruk gjerne illustrasjoner.',
  strategyAndGoals: 'Strategi og målsetning',
  'strategyAndGoals.description':
    'Hva er strategien til selskapet og hvilke mål har dere for fremtiden? Gi investorer innblikk i planer for videreutvikling, skalering og vekst av selskapet, herunder hva kapitalen i denne emisjonen skal gå til.',
  markedAndTrends: 'Marked og trender',
  'markedAndTrends.description':
    'Hvordan ser markedet dere opererer i ut per i dag, og hva er aktuelle trender i markedet? Hva er dagens markedsposisjon, og hvilken posisjon sikter selskapet etter? Benytt gjerne illustrasjoner som fremstiller faktabaserte data med kildehenvisninger.',
  competition: 'Konkurrenter',
  'competition.description':
    'Gi en balansert beskrivelse av konkurransesituasjonen, hvor nøytrale beskrivelser av relevante konkurrenter inkluderes. Henvis gjerne til faktabaserte data. Benytt kildehenvisninger.',
  businessCriticalAgreements: 'Virksomhetskritiske avtaler',
  'businessCriticalAgreements.description':
    'Beskriv selskapet virksomhetskritiske avtaler. Beskriv tilknytning, vesentlighet og på hvilken måte relevante parter integreres i virksomhetsstyringen. Her kan også beskrivelser knyttet til for eksempel patenter og varemerkeregistreringer inkluderes.',
  economy: 'Økonomi',
  'economy.description':
    'Gi en beskrivelse av status på selskapets økonomi, samt ledelsens betraktninger knyttet til prognoser og budsjetter, og forutsetningene for disse i årene fremover. Beskriv planlagt/estimert utvikling på inntektssiden og kostnadssiden, samt investeringer. Henvis til avsnittet “finansiering” for detaljer knyttet til finansieringsaktiviteter.',
  financials: 'Finansiering',
  'financials.description':
    'Inkluder historiske finansieringsaktiviteter (gjerne en tabell som viser dato, type finansiering og aktør, og kapital tilført), målet med denne emisjonen, plan ved minimumsbeløp og maksimumsbeløp og fremtidige planer for finansiering (emisjoner, lån, soft-funding osv.). Opsjonsprogram eller konvertible lån som kan påvirke fremtidige eiersits må også beskrives.',
  appraisal: 'Verdsettelse',
  'appraisal.description':
    'Gi en oppsummering av verdsettelsesdokumentet som er utarbeidet i forbindelse med emisjonen. {br}Formuleringer knyttet til usikkerhet i prognoser skal beskrives',
  issueConditions: 'Emisjonstekniske forhold',
  'issueConditions.description':
    'Hvordan er emisjonen tenkt gjennomført? Skal dere bruke styrefullmakt eller generalforsamlingsvedtak. Er det andre relevante forhold som bør belyses (f.eks. splitt)? Se ellers under fanen Grunnleggende Info for ytterligere emisjonstekniske detaljer. <templateLink>Se mal her</templateLink>',
  exitStrategy: 'Exit-strategi',
  'exitStrategy.description':
    'Planlegger selskapet for exit? Er det planer om en utbyttestrategi, oppkjøp, salg eller en børsnotering? Beskriv gjeldende strategi, og eventuelt vurderingskriterier for fremtidig valg av annen strategi.',
  sustainability: 'Bærekraft',
  'sustainability.description':
    'På hvilken måte bidrar selskapet og/eller produktet/tjenesten til å redusere utslipp og å nå klimamålene? Inkluder balansert info knyttet til FNs bærekraftsmål dersom relevant. Henvis eventuelt til dokumentasjon som legges ved.',
  risks: 'Risiko',
  'risks.description':
    'Hvilke særlige risikoer er det viktig å trekke frem som en investor bør kjenne til? Følgende risikoer bør inkluderes; økonomisk og finansiell, aksjens omsettelighet, marked, operasjonell, politisk og omdømme. <templateLink>Veiledning risikovurdering</templateLink>',
  additionalInformation: 'Ytterligere informasjon',
  'additionalInformation.description':
    'Her kan informasjon som ikke kommer godt nok frem i de overstående seksjonene inkluderes.',
  welcome: 'Velkommen som aksjonær',
  'welcome.description':
    'Legg inn informasjon om hvordan dere kan nås, om det kan bookes møter ol. Her kan det også inkluderes informasjon om hvordan aksjonærer vil bli fulgt opp (Caplist). Legg gjerne inn en hilsen til de potensielle aksjonærene som gjør at de føler at de er velkomne med på laget.',
  selectCategory: 'Velg kategori',
  videoTitle: 'Videotittel',
  uploadImageCaption: '{hasValue, select, true {Endre bilde} other {Last opp bilde} }',
  uploadVideoCaption: '{hasValue, select, true {Endre video} other {Last opp video} }',
  uploadVideoPreviewImage: 'Endre visningsbilde',
  imageAspectRatio: 'Bildeforhold: {width}px * {height}px',
  imageTypeLabel: 'F.eks : JPG, PNG, WEBP',
  videoTypeLabel: 'F.eks : MP4, AVI, MOV',
  videoProcessing: 'Laster inn video-data, vennligst vent',
  'resetField.link': 'Nullstill felt',
  'resetField.confirmDialog.title': 'Nullstill felt?',
  'resetField.confirmDialog.description': `
    Dette vil sette feltet tilbake til utgangspunktet når tilgang til veileder blir gitt.
    {br}
    Resultatet er i de fleste tilfeller kun et felt uten innhold.
    {br}
    Denne funksjonaliteten bør kun brukes i situasjoner der en feil har oppstått slik at feltet ikke lengre lar seg redigere/ikke blir lagret.
    {br}
    {br}
    <b>Det er <u>ikke</u> mulig å angre denne handlingen.</b>`,
  'resetField.confirmDialog.fixComingSoon':
    'Dette er en midlertidig løsning frem til redigeringsverktøyet blir oppdatert',
  'resetField.successToast': 'Felt nullstilt',
}

export const investorLanding = {
  heading: 'Invester i\n<grul>fremtiden</grul>',
  subtitle:
    'Folkeinvest er Nordens ledende\nplattform for aksjebasert offentlig\nfinansiering av oppstarts - og\nvekstbedrifter',
  ctaButton: 'Bli en investor',
  'testimonial.title': 'Karen Elene Thorsen, Sulten AS',
  'testimonial.text':
    'Folkeinvest.no gjorde det mulig for studenter og unge voksne å investere i Sulten på en trygg måte.',
  joinTheJourney:
    'Folkeinvest lykkes enormt med å skaffe kapital til selskaper som skal hente inntil 30 millioner norske kroner. Vil du være med på reisen?',

  statsHeading: 'Er du også en folkeinvestor?',
  statsSubtitle:
    'Vi ønsker til enhver tid flere investorer velkomne til å være med på reisen til nye spennende nordiske selskap. Vil du være en del av denne statistikken?',
  raisedCapitalHeading: 'Innhentet kapital',
  registeredInvestorsHeading: 'Registrerte\ninvestorer',
  totalCampaignsHeading: 'Antall\nemisjoner',
  raisedCapitalYTDHeading: 'Innhentet kapital i år',
  registeredInvestmentFirmsHeading: 'Registrerte\ninvesteringsforetak',
  campaignYTDHeading: 'Antall emisjoner i år',

  testimonialsHeading: 'Hva sier folk om oss?',

  explanationHeading: 'På Folkeinvest.no kan du investere i oppstarts- og vekstbedrifter.',
  explanationSubtitle:
    'På Folkeinvest kjøper du aksjer i selskap som gjennomfører en emisjon. Dette betyr at selskapene øker antall aksjer for å selge dem til deg slik at de kan skape mer verdi.',
  explanationHighRiskRewardHeading: '«High risk, high reward»',
  explanationHighRiskRewardSection:
    'Selskapene som selger aksjer på Folkeinvest er som regel unge selskap, og investering i slike har høy risiko. Samtidig har du muligheten til å kjøpe aksjer tidlig, og dermed få stor gevinst hvis selskapet lykkes med sine planer.  Du bør kun investere penger du har råd til å tape, og det kan være lurt å heller investere litt i flere selskap, enn mye i ett. Du kan lese mer om investeringsrisiko på <link>denne siden.</link>',
  explanationHowToMakeMoneyHeading: 'Hvordan og når du kan tjene penger',
  explanationHowToMakeMoneySection:
    'Du kan tjene penger på investeringen din på to måter. Den vanligste er at du selger aksjene til en høyere pris senere, enten til en interessert du har funnet eller ved at selskapet kjøpes opp eller registreres på en børs. Den andre måten du kan tjene penger på er utbytte.',

  getStartedHeading: 'Kom igang',
  getStartedSubtitle: 'Med <grul>tre</grul> enkle steg er du klar',

  getStartedFirstHeading: 'Opprett investorprofil',
  getStartedFirstContent:
    'Gå til «<b>Ny profil</b>» og følg stegene. Last opp firmaattest dersom du ønsker å kjøpe aksjer på vegne av et selskap.',
  getStartedFirstCTA: 'Opprett en profil',

  getStartedSecondHeading: 'Utforsk',
  getStartedSecondContent:
    'Gå til «<b>Invester</b>» se på spennede selskap og les i diskusjonsforumet, her kan du få svar på dine spørsmål.',
  getStartedSecondCTA: 'Se alle investeringsmuligheter',

  getStartedThirdHeading: 'Invester',
  getStartedThirdContent:
    'Fant du et tilbud du liker? På investeringstilbud-siden registrerer du aksjer du vil kjøpe. Invester kun det du har råd til å tape.',
  getStartedThirdCTA: 'Mer informasjon',
  openInvestmentOpportunitiesHeading: 'Åpne investerings&shy;muligher',
  seeAllOpportunitiesCTA: 'Se alle muligheter',
}

export const entrepreneurLanding = {
  //Top Section
  heading: 'Sitter du på det neste store norske selskapet?',
  subtitle: 'Nordens ledende tilrettelegger for mindre kapitalutvidelser i unoterte selskap.',
  ctaButton: 'Søk kapital',
  'testimonial.title': 'Geir Arne Solheim, Havkraft AS',
  'testimonial.text':
    'Folkeinvest var den perfekte finansieringspartner. Vi fikk 900 nye eiere, noe som gjør oss til et solid norsk folkeselskap!',
  joinTheJourney:
    'Folkeinvest har hentet om lag 1 mrd kroner til selskap som har hentet mellom 1 og 80 millioner kroner. Planlegger du å hente kapital til selskapet ditt?',

  //Stats section
  statsHeading: 'Sitter du på det neste store selskapet?',
  statsSubtitle: 'Vi ønsker å komme i kontakt med kapitalsøkende selskap som vil bli en del av denne statistikken:',

  raisedCapitalHeading: 'Tilrettelagt kapital',
  registeredInvestorsHeading: 'Registrerte\ninvestorer',
  totalCampaignsHeading: 'Antall\nemisjoner',
  raisedCapitalYTDHeading: 'Innhentet kapital i år',
  registeredInvestmentFirmsHeading: 'Registrerte\ninvesteringsforetak',
  campaignYTDHeading: 'Antall emisjoner i år',

  testimonialsHeading: 'Hva sier folk om oss?',

  //Process section
  processHeading: 'Prosessen trinn for trinn',
  processSubheading: 'Med <grul>seks</grul> enkle steg henter du kapital til en rimelig pris',
  process: [
    {
      heading: '1.',
      title: 'Onboarding og introduksjonsmøte',
      text: 'Vi gjennomfører introduksjonsmøte for å bli kjent og avgjøre om et samarbeid er gunstig for begge parter.',
    },
    {
      heading: '2.',
      title: 'Signering, forberedelse og DD',
      text: 'Vi signerer avtale og påstarter forberedelser til et klargjort investeringstilbud gjennom en fullstendig DD.',
    },
    {
      heading: '3.',
      title: 'Investordialog og validering',
      text: 'Dere fortsetter investordialoger som har pågått og starter nye dialoger for å sikre validerende kapital.',
    },
    {
      heading: '4.',
      title: 'Markedsføring',
      text: 'Markedsføring av den nærstående kapitalutvidelsen påbegynnes og intensiveres.',
    },
    {
      heading: '5.',
      title: 'Åpning av tegningsperioden',
      text: 'Tegningsperioden åpner for alle interesserte risikokapitalmiljøer, tidligfaseinvestorer og engleinvestorer.',
    },
    {
      heading: '6.',
      title: 'Stenge tegningsperioden og etterarbeid',
      text: 'Når tegningsperioden stenger, påstarter vi etterarbeidet og følger dere frem til emisjonen er registrert i Brreg.',
    },
  ],
  //Does it fit section
  doesItFitHeading: 'Et samarbeid med Folkeinvest gir dere:',
  doesItFitSubtitle: '',
  requirements: [
    'Tilgang på risikokapital',
    'Investorer som investerer i flere transjer',
    'Tilgang til kompetente juridiske og finansielle rådgivere',
    'Eksponering av selskapet (markedsføring)',
    'Datadrevne prosesser der dere styrer kapitalinnhentingsprosessen med datadrevet rådgivning',
    'Tilgang til digitale verktøy for smidige prosesser (veileder)',
    'Abonnement på Caplist som bistår dere i investorkommunikasjon etter suksessfull kapitalutvidelse/emisjon',
    'Abonnement på Caplist som gir støtte til annenhåndstransaksjoner og digitale generalforsamlinger',
    'Mulighet for oppsett og forvaltning av SPV-struktur',
    'Fullstendig juridisk og finansiell DD',
  ],

  accessToHeading: 'Med Folkeinvest får du tilgang til:',

  accessToList: [],

  doesItFitCTAButton: 'Kom i gang',

  //Contact form section
  contactFormHeading: 'La oss ta en kaffe!',
  contactFormSubheading: 'Fyll ut kontaktskjema, så tar en av våre erfarne finansielle rådgivere kontakt med deg.',

  contactFormLabels: {
    name: 'Navn',
    email: 'E-post',
    phone: 'Telefonnummer',
    companyName: 'Selskapsnavn',
    button: 'Jeg ønsker å bli kontaktet',
  },
  //Succsessful Issues fix
  'successfulIssues.subtitle': 'Her er noen av de selskapene som har hatt stor suksess.',
}

export const successfulIssues = {
  heading: 'Vellykkede emisjoner i det norske markedet',
  subtitle: 'Her er noen av de selskapene som har hatt stor suksess. Bli med da vel og invester nå!',

  clientLabel: 'Klient',
  countryLabel: 'Land',
  categoryLabel: 'Kategori',
  typeLabel: 'Type',
  sizeLabel: 'Størrelse',
  yearLabel: 'År',
  investorsLabel: 'Antall investorer',

  categoryTurism: 'Reiseliv og turisme',
  categoryConsumption: 'Konsum',
  categoryTechnology: 'Teknologi',
  categoryMaritime: 'Maritimt',
  categoryTechApp: 'Teknologi/App',
  categoryFoodTechnology: 'Teknologi/Mat',
  categoryEnergy: 'Energi',
  categoryGaming: 'Gaming',
  categoryMedical: 'Teknologi/Medisinsk',
  categoryNature: 'Natur',
  typeEmission: 'Emisjon',

  sizeCurrency: '{size} MNOK',
}

export const infoMeeting = {
  props: {
    title: 'Ønsker du å hente penger med Folkeinvest?',
    description:
      'Book et møte med meg nedenfor eller fyll ut vårt <link>registreringsskjema</link>, så tar vi en prat om din bedrift. Sammen kan vi finne ut hvorvidt vår plattform er en god arena for dere!',
    buttonText: 'Ta en prat med oss',
    url: 'https://calendly.com/folkeinvest/informasjonsmote-2?month=2022-02',
  },
}

export const contractApplication = {
  loadingMessage: 'Henter søknad...',
  'header.applicationInfo': 'Søknadsinformasjon',
  'tooltip.copyLinkToClipboard': 'Kopier link til søknadsskjema',
  'header.noApplication': 'Ingen søknad funnet',
  'info.noApplication': 'Vi fant ingen søknader i databasen for denne leaden.',
  createApplication: 'Opprett søknad',
  'info.goBack': 'Tilbake til oversikten',
  'form.companyInfoHeading': 'Om selskapet',
  'form.teamAndStaffHeading': 'Team og personal',
  'form.companyValuesHeading': 'Selskapsverdi og kapitalbehov',
  'form.incomesHeading': 'Inntekter',
  'form.investmentsHeading': 'Hvor mye kapital er tidligere innskutt',
  'form.capital': 'Kapital',
  'form.convertibleLoansOptionsAddMore': 'Legg til flere',
  'form.shareholdersAgreementHeading': 'Aksjonæravtale',
  'form.shareholdersAgreementInfo': 'Aksjonæravtalen må deles som en del av investeringstilbudet ved publisering',
  'form.contactPersonEmail': 'Kontaktpersons e-post',
  'form.incubatorHeading': 'Inkubator',
  'form.incubatorInfo': 'Er bedriften per i dag medlem av en inkubator?',
  'form.incubatorName': 'Navn på inkubator?',
  missingOrganizationNumber: 'Mangler organisasjonsnummer for å søke om avtale',
  submittedContractApplicationSuccess: 'Søknaden er sendt',
  submittedContractApplicationFailed: 'Feil ved sending av søknad. Kontakt Folkeinvest om problemet vedvarer',
  requiredFieldsMissing: 'Kan ikke sende søknadskjema som mangler påkrevde felter',
}

export const bookMeeting = {
  'confirmSlot.addGuest': 'Legg til gjest',
}

export const companySearch = {
  dataFrom: 'Vi henter informasjon som er offentlig tilgjengelig via Brønnøysundregistrene',
  organizationNumber: 'Organisasjonsnummer',
}

export const formidlingSection = {
  title: 'Det digitale meglerhuset Folkeinvest introduserer låneprodukter i markedet.',
  question:
    'Er ditt selskap interessert i finansiering som ikke utvanner din eierandel? Eller er du interessert i å investere i lån til norske selskap?',
  readAboutOpportunities: 'Les mer om mulighetene',
}

// This can be removed when pre-meeting related features have been removed from facilitator.
export const preMeeting = {
  orgNumber: 'Org.nummer',
  noOrgGiven: 'Organisasjonsnummer ikke oppgitt',
  formTitle: 'Før informasjonsmøte',
  formSubtitle: 'Til informasjonsmøtet, er det fint om vi kan få litt ekstra informasjon.',
  formAutosaves: 'Endringer blir automatisk lagret.',
  applyForAgreement: 'Søk om avtale',
  aboutCompanyTitle: 'Litt om selskapet',
  companyValuationLabel: 'Anslått selskapsverdi',
  fundraisingTargetLabel: 'Anslått kapitalbehov',
  estimatedTimePeriodLabel: 'Når trengs kapitalen?',
  companyValuationPlaceholder: '14 millioner',
  fundraisingTargetPlaceholder: '4 millioner',
  estTimePeriodLtThreeMonths: 'Under 3 mnd',
  estTimePeriodThreeToSixMonths: '3-6 mnd',
  estTimePeriodGtSixMonths: 'Over 6 mnd',
  numberOfSharesLabel: 'Antall aksjer',
  shareCapitalLabel: 'Aksjekapital',
  confirmationDialogTitle: 'Du skal nå levere informasjonen',
  confirmationDialogText: 'Dette betyr at de ikke lenger vil være mulig å gjøre endringer i skjemaet.',
  bookingInfoTitle: 'Fra bookingskjema',
  bookingInfoSubtitle: 'Ser alt riktig ut, trenger du ikke å gjøre endringer',

  invalidLink: 'Ugyldig lenke, kontakt support for hjelp',
}

export const riskEvaluation = {
  'title.riskEvaluation': 'Risikovurdering',
  'title.companyInfo': 'Selskapsinformasjon',
  'title.emission': 'Kapitalutvidelse',
  'title.mandate': 'Innstilling',
  'title.generalComment': 'Generell kommentar',
  'label.approver': 'Hvem skal godkjenne mandatet?',
  'label.creditScore': 'Kredittscore',
  'label.companyValuation': 'Ans. selskapsverdi',
  'label.conflictOfInterest': 'Interessekonflikt',
  'label.depositedCapital': 'Innskutt kapital i nok',
  'label.isPepCompany': 'PEP utsteder',
  'label.fundraisingTarget': 'Anslått kapitalbehov (målsum)',
  'label.shareholdersAgreement': 'Aksjonæravtale',
  'label.expectedLoanConversion': 'Forventet lånekonvertering',
  'label.hasChangedStatutes': 'Endre vedtekter',
  'label.hasConvertedLoans': 'Konvertering',
  'label.regularAgreement': 'Ordinær avtale',
  'label.companyName': 'Utstederselskap',
  'label.incomePreviousFiscal': 'Inntekter siste år',
  'label.organizationNumber': 'Org. nummer',
  'label.chairmanName': 'Styreleder',
  'label.foundedDate': 'Stiftelsesdato',
  'label.ceoName': 'Daglig leder',
  'label.countryArea': 'Landsdel',
  'label.nrOfEmployees': 'Faste ansatte',
  'label.industry': 'Bransjebeskrivelse',
  'label.incubator': 'Inkubator',
  'label.approvalDescription': 'Hvem skal godkjenne?',
  'error.existingBeneficialOwner': 'Rettighetshaver eksisterer allerede',
  'error.invalidSSN': 'Ugyldig fødselsnummer',
  'error.noRecordsFound': 'Fant ingen treff i søk, sørg for at personnummer er korrekt og prøv igjen',
  'warning.requiredExplanationField': 'Forklaring kreves',
}

const issuerDashboardCommon = {
  toContinue: 'For å gå videre',
  accountingReview: 'Lever Underlag',
  campaignCardReview: 'Lever emisjonskort',
  campaignCardCanEdit:
    'Emisjonskortet kan redigeres på et senere tidspunkt. Dersom dere ikke har alt til emisjonskortet klart kan andre seksjoner påstartes, hvor man da bør starte på seksjonen Grunnleggende info.',
  campaignCardRequired: 'Emisjonskortet må ha blitt ferdigstilt og levert inn til kontroll.',
}

export const issuerDashboard = {
  'overview.header.title': 'Utforming av investeringstilbudet',
  'overview.header.subtitle':
    'Du kan jobbe parallelt med alle delene av investeringstilbudet, men vi anbefaler at veiledningen til høyre følges.',
  'overview.header.subtitle.small':
    'Du kan jobbe parallelt med alle delene av investeringstilbudet, men følg veiledning nedenfor.',
  'description.step': '{step} av 6',
  'alert.ACCOUNTING': 'Før man starter på denne seksjonen bør underlagsmøtet gjennomføres.',
  'alert.ACCOUNTING_REVIEW': 'Det er behov for endringer i underlag før dette kan godkjennes.',
  'alert.INVESTMENT_OFFER': 'Det er behov for endringer i investeringstilbudet før dette kan godkjennes.',
  'step.button.ACCOUNTING': 'Gå til Underlag',
  'step.button.ACCOUNTING_REVIEW': issuerDashboardCommon.accountingReview,
  'step.button.CAMPAIGN_CARD': 'Rediger emisjonskort',
  'step.button.CAMPAIGN_CARD_REVIEW': issuerDashboardCommon.campaignCardReview,
  'step.button.EMISSION_INFORMATION': 'Gå til Grunnleggende informasjon',
  'step.button.TEAM_AND_NETWORK': 'Gå til Team og nettverk',
  'step.button.INVESTMENT_OFFER': root.sendForApproval,
  'step.button.PRESENTATION': 'Gå til Presentasjon',
  'step.button.SHAREHOLDERS': 'Gå til Aksjonærer',
  'step.header.ACCOUNTING': 'Underlag',
  'step.header.ACCOUNTING_REVIEW': issuerDashboardCommon.accountingReview,
  'step.header.CAMPAIGN_CARD': 'Emisjonskort',
  'step.header.CAMPAIGN_CARD_REVIEW': issuerDashboardCommon.campaignCardReview,
  'step.header.EMISSION_INFORMATION': 'Grunnleggende informasjon',
  'step.header.TEAM_AND_NETWORK': 'Grunnleggende informasjon',
  'step.header.INVESTMENT_OFFER': 'Fullfør investeringstilbudet',
  'step.header.INVESTMENT_OFFER_DONE': 'Investeringstilbudet er godkjent',
  'step.header.INVESTMENT_OFFER_REVIEWING': 'Investeringstilbudet er til kontroll',
  'step.header.PRESENTATION': 'Presentasjonstekst, preallokeringer og tilleggsdokumenter',
  'step.header.SHAREHOLDERS': 'Aksjonærer',

  'step.text.ACCOUNTING': `
    <bold>Regnskap, prognoser og budsjetter</bold>
    <para>Regnskap, herunder siste årsregnskap, eventuelle bemerkninger fra revisor, og avstemte perioderapporter kontrolleres av Folkeinvest for eventuelle feil. Detaljert budsjett skal lastes opp i et standardisert format (spør din tilrettelegger for mal). I budsjettet skal det også gå frem ved hjelp av tekst hvilke forutsettninger ledelsen har lagt til grunn for både inntekter og kostnader. Et ekstrakt fra dette budsjettet og regnskap vil fremstilles i presentasjonsteksten i et standardisert oppsett.</para>
    <bold>Runwayanalyse</bold>
    <para>Regnskap, prognoser og budsjetter benyttes også til å utføre en runwayanalyse, for å sikre at laveste emisjonsbeløp er tilstrekkelig gitt selskapets økonomiske status og plan for eventuel fremtidig finansiering.</para>
    <bold>${issuerDashboardCommon.toContinue}</bold>
    <para>All påkrevd dokumentasjon må ha blitt lastet opp.</para>
  `,

  'step.text.ACCOUNTING_REVIEW': `
    <bold>Godkjenning av underlag</bold>
    <para>Dokumentasjonen må kontrolleres av Folkeinvest før investeringstilbudet blir godkjent for å kvalitetssikre underlaget som ligger til grunn for investeringstilbudet. Dokumentasjonen bør bli sendt inn så tidlig som mulig i prosessen med utforming av investeringstilbudet.</para>
    <bold>Tilbakemelding</bold>
    <para>Om det skulle være behov for endringer, eller tillegg til underlaget så vil underlagsseksjonen bli avslått. Seksjonen vil da bli gjenåpnet med kommentarer som detaljerer hva som må bli endret. Når endringene er gjennomført kan dokumentasjonen igjen bli levert inn.</para>
    <bold>${issuerDashboardCommon.toContinue}</bold>
    <para>Lever underlag.</para>
  `,

  'step.text.CAMPAIGN_CARD': `
    <bold>Utarbeidelse av emisjonskort</bold>
    <para>Legg til tekst og bilde på emisjonskortet. Tekst og bilde skal formidle investeringsmuligheten og vekke interesse for å følge kapitalutvidelsen. Emisjonskortet vil bli synlig på folkeinvest.no etter at det er godkjent av Folkeinvest. Hvis det blir avslått av Folkeinvest vil du få en kommentar om hva som er feil og kan sende kortet inn på nytt når det har blitt utbedret.</para>
    <bold>Du kan redigere kampanekjortet senere</bold>
    <para>${issuerDashboardCommon.campaignCardCanEdit}</para>
    <bold>${issuerDashboardCommon.toContinue}</bold>
    <para>${issuerDashboardCommon.campaignCardRequired}</para>
  `,

  'step.text.CAMPAIGN_CARD_REVIEW': `
    <bold>Godkjenning av emisjonskort</bold>
    <para>For at emisjonskortet skal bli vist på Folkeinvest.no så må innholdet først leveres inn til godkjenning. Det betyr at tilrettelegger ser igjennom innholdet og forsikrer at alt er som det skal. Hvis det blir avslått av Folkeinvest vil du få en kommentar om hva som er feil og etter å ha utbedret dette kan kortet bli sendt inn på nytt.</para>
    <bold>Du kan redigere emisjonskortet senere</bold>
    <para>${issuerDashboardCommon.campaignCardCanEdit}</para>
    <bold>${issuerDashboardCommon.toContinue}</bold>
    <para>${issuerDashboardCommon.campaignCardRequired}</para>
  `,

  'step.text.EMISSION_INFORMATION': `
    <bold>Anbefalt rekkefølge</bold>
    <para>Det anbefales å starte med seksjonene Grunnleggende info, Team og nettverk. Disse seksjonene bør ferdigstilles før man starter på andre seksjoner, da disse inneholder grunnleggende informasjon for investeringstilbudet.</para>
    <bold>${issuerDashboardCommon.toContinue}</bold>
    <para>Grunnleggende informasjon må ha informasjon i alle påkrevde felter. Team og nettverk seksjonene må ha minst en person i team og i styret.</para>
  `,

  'step.text.TEAM_AND_NETWORK': `
    <para>Det anbefales å starte med seksjonene Grunnleggende info, Team og nettverk. Disse seksjonene bør ferdigstilles før man starter på andre seksjoner, da disse inneholder grunnleggende informasjon for investeringstilbudet.</para>
    <bold>${issuerDashboardCommon.toContinue}</bold>
    <para>Grunnleggende informasjon må ha informasjon i alle påkrevde felter. Team og nettverk seksjonene må ha minst en person i team og i styret.</para>
  `,

  'step.text.INVESTMENT_OFFER': `
    <bold>Fullfør investeringstilbudet før godkjenning</bold>
    <para>For å be om godkjenning av investeringstilbudet må alle de obligatoriske feltene i alle seksjoner være fylt ut.</para>
    <bold>Be om godkjenning av investeringstilbudet</bold>
    <para>Når investeringstilbudet godkjennes vil Folkeinvest gå gjennom investeringstilbudet og gi tilbakemeldinger. Under kontrollen blir alle seksjoner låst. Seksjoner som har behov for endringer vil bli åpnet etter å ha vært til kontroll og må bli sendt inn på nytt etter endringer er utført.</para>
    <bold>${issuerDashboardCommon.toContinue}</bold>
    <para>Lever investeringstilbudet til kontroll.</para>
  `,

  'step.text.INVESTMENT_OFFER_DONE': `
    <bold>Investeringstilbudet er godkjent av en tilrettelegger</bold>
    <para>Folkeinvest har nå gått igjennom og sjekket at alt er som det skal.</para>
    <bold>Hva skjer nå?</bold>
    <para>Ved godkjenning blir det sendt e-post til styreleder for signering av fullstendighet. Book et møte med tilrettelegger for å få innføring i hvordan tegningsprosessen fungerer og avtale planen videre.</para>
    <bold>${issuerDashboardCommon.toContinue}</bold>
    <para>Styreleder hos utsteder signerer for fullstendighet.</para>
  `,

  'step.text.INVESTMENT_OFFER_REVIEWING': `
    <bold>Investeringstilbudet er til kontroll hos en tilrettelegger</bold>
    <para>Tilrettelegger går nå gjennom investeringstilbudet for å sjekke om alt er på plass.</para>
    <bold>Om det er behov for endringer</bold>
    <para>Dersom det er behov for endringer vil tilrettelegger legge inn kommentarer som beskriver hva som må endres. Om det finnes kommentarer, vil dere se antall kommentarer både på kortene til venstre og i den relevante seksjonen.</para>
    <bold>${issuerDashboardCommon.toContinue}</bold>
    <para>Folkeinvest godkjenner eller sender tilbake investeringstilbudet.</para>
  `,

  'step.text.PRESENTATION': `
    <bold>Oppsummering av informasjonen i de andre seksjonene i presentasjonsteksten</bold>
    <para>Presentasjonsteksten er et omfattende sammendrag av investeringstilbudet. Informasjonen som deles her rettes mot ikke-profesjonelle investorer, og skal presenteres klart, balansert og ikke villedende. Dersom det brukes kilder må disse fremkomme i presentasjonsteksten, gjerne ved hjelp av lenker.</para>
    <bold>Legg inn lånekonvertering, inklusive eventuelle konvertible lån</bold>
    <para>Lånekonvertering dere har fått sikret legges inn i tabellen.  En oversikt over eventuelle konvertible lån skal legges inn i egen tabell. Tabellene kan oppdateres frem til investeringstilbudet godkjennes.</para>
    <bold>${issuerDashboardCommon.toContinue}</bold>
    <para>Fyll inn alle påkrevde felter i presentasjonsseksjonen.</para>
  `,

  'step.text.SHAREHOLDERS': `
    <bold>Legg inn dagens aksjonærer</bold>
    <para>Først legges de aksjonærene selskapet allerede har på tidspunktet for utarbeidelse av investeringstilbudet.</para>
    <bold>${issuerDashboardCommon.toContinue}</bold>
    <para>Minst en aksjonær må ha blitt lagt til og alle nåværende aksjer må være registrert.</para>
  `,
  submitInvestmentOfferError:
    'Emisjonskort og Underlag må være godkjent før resten av investeringstilbudet kan sendes til godkjenning.',
  emissions: 'Emisjoner',
  investor: 'Investor',
  equity: 'Kapital',
  aboutUs: 'Om oss',
  myProfile: 'Min profil',
  personalia: 'Personalia',
  registerInvestmentCompany: 'Registrere investeringsselskap',
}

export const accounting = {
  information: 'Regnskapsinformasjon',
  accountingFirm: 'Regnskapsbyrå',
  'accountingFirm.description': 'Benytter selskapet regnskapsbyrå?',
  annualAccounts: 'Signert årsregnskap',
  'annualAccounts.description': `
    Signert årsregnskap for siste tre regnskapsår, som inneholder:
    <li>Resultatregnskap, balanse og noter</li>
    <li>Styrets beretning (ikke et krav for små foretak)</li>
    <li>Revisjonsberetning (hvis revisjonspliktig/revisor)</li>
    {br}
    For konsern kreves konsolidert årsregnskap, samt årsregnskap for samtlige selskap i konsernet. 
  `,
  annualAccountsPreviousYears: 'Kontospesifisert årsregnskap',
  'annualAccountsPreviousYears.description':
    'Kontospesifisert årsregnskap for foregående år, som inneholder resultatregnskap og balanse.',
  auditor: 'Revisjonspliktig/revisor',
  auditorName: 'Navn på revisjonsselskap',
  agreementType: 'Avtaletype',
  isCompanySubjectToAudit: 'Er selskapet revisjonspliktig?',
  hasCompanyAuditor: 'Har selskapet revisor?',
  auditReports: 'Revisorbekreftelser, attestasjoner og nummererte brev',
  'auditReports.description': 'Last opp eventuelle revisorbekreftelser, attestasjoner og nummererte brev.',
  companyIsNotSubjectToAuditing: 'Firma er ikke revisjonspliktige eller ble stiftet for under ett år siden.',
  budgetPrognosis: 'Budsjett/prognose',
  'budgetPrognosis.description':
    'Beskrivelse av ledelsens forventninger knyttet til utvikling i viktige inntekts- og kostnadselementer; eksempelvis salgsinntekter (splitt på pris og volum), andre inntekter, lønnskostnad, og andre sentrale kostnadselementer, avskrivninger og investeringer, forventet fremtidig arbeidskapitalbehov.',
  missionCriticalAgreements: 'Virksomhetskritiske avtaler',
  'missionCriticalAgreements.description': `
    Virksomhetskritiske avtaler og dokumenter som potensielle investorer og Folkeinvest kan være tjent med å ha innblikk i. Eksempler kan være: 
    <li>Patenter</li>
    <li>Opphavsrettigheter</li>
    <li>Merkenavn</li>
    <li>Oversikt over betydelige kunderelasjoner</li>
    <li>Oversikt over betydelige leverandørrelasjoner</li>
  `,
  captable: 'Cap table',
  'captable.description': 'Cap table med oversikt over tidligere kapitalforhøyelser og transaksjoner.',
  legalMatters: 'Juridiske forhold - nøkkelpersoner',
  legalMattersHasCriminalBackground:
    'Har medlemmer av styret, ledelse eller andre sentrale personer tilknyttet selskapet domfellelser eller vært involvert i rettsprosesser innenfor fem siste år?',
  nationalProspectus: 'Nasjonalt prospekt',
  'nationalProspectus.description':
    'Det skal utarbeides prospekt ved tilbud om tegning eller kjøp av omsettelige verdipapirer som rettes til 150 personer eller flere i det norske verdipapirmarkedet, og gjelder et beløp på minst 1 000 000 euro beregnet over en periode på 12 måneder.',
  notFound: 'Kunne ikke finne underlagseksjon',
  otherObligations: 'Juridiske forhold',
  'otherObligations.description':
    'Oversikt over eventuelle øvrige forpliktelser selskapet har, inkludert ikke-balanseførte eiendeler eller gjeld (eksempelvis tvister, krav, garantisaker og rettsaker). Inkluder forfallsdato, vilkår og betingelser, hvis aktuelt.',
  previousSalesProcesses: 'Tidligere salgsprosesser',
  'previousBidsOrSalesOfCompany.description':
    'Har det tidligere vært diskusjoner omkring salg av selskapet? Har dere i så fall mottatt bud?',
  previousTransactionProcesses: 'Tidligere kjøpsprosesser',
  'previousTransactionsRelatedToTradingOtherCompanies.description':
    'Har selskapet vært involvert i oppkjøp av andre selskaper?',
  previousValuations: 'Tidligere verdivurderinger',
  'companyHasPreviousValuations.description': 'Har selskapet tidligere verdivurderinger?',
  reconciledAccountProfitReport: 'Kontospesifisert resultatrapport',
  'reconciledAccountProfitReport.description':
    'Kontospesifisert resultatrapport for inneværende år, som er maks 1 måned gammel. Beløp per måned må fremkomme, samt akkumulert beløp for perioden.',
  reconciledAccountBalanceReport: 'Kontospesifisert balanserapport',
  'reconciledAccountBalanceReport.description': `
    Kontospesifisert balanse for inneværende år, som er maks 1 måned gammel. Den må inneholde: 
    <li>Inngående balanse (IB) per 01.01, og IB må være lik utgående balanse (UB) per 31.12 foregående år</li>
    <li>Bevegelse per måned eller saldo ved hver månedsslutt må fremkomme</li>
    <li>Balansen må være avstemt per månedsslutt – aktuelle aktiveringer, avskrivninger og periodiseringer må være gjennomført. Varelager må være oppdatert til dagens verdi, hvis selskapet har varer av betydning på lager, og eventuelle ukurante varer må være hensyntatt</li>
  `,
  shareholderAgreement: 'Aksjonæravtale',
  companyHasShareholderAgreement: 'Har selskapet en aksjonæravtale som nye investorer skal tilslutte seg?',
  valuationDocument: 'Verdivurderingsdokument',
  'valuationDocument.description': `
    Verdivurderingsdokumentet skal tydeliggjøre usikkerhet knyttet til verdivurderingen, hvilke forutsetninger som er lagt til grunn, samt en presisering av usikkerhet knyttet til aksjens omsettelighet. 
    {br}
    Verdivurderingen, inklusive rasjonale og underlag, skal i alle tilfeller godkjennes av Folkeinvest.
  `,
  addFile: 'Legg til fil her eller <link>bla gjennom</link>',
  addFileMinimal: 'Dra hit eller <link>velg fil</link>',
  addMore: 'Legg til flere',
  wantToApprove: 'Ferdig med utfylling av underlag?',
  submitForApproval: 'Lever underlag',
  submittedForApproval: 'Underlag er sendt til kontroll',
  brandProtection: 'Merkevarebeskyttelse',
  collaboration: 'Samarbeid',
  contracts: 'Kontrakter',
  copyright: 'Opphavsrettigheter',
  ip: 'IP',
  loi: 'LOI',
  misc: 'Annet',
  patents: 'Patenter',
  powerOfAttorney: 'Fullmakt',
  supplier: 'Leverandør',
}

export const statusCard = {
  reviews: '{reviews, plural, one {1 endring} other {{reviews} endringer forespurt}}',
  missing: 'Mangler {missing} felt',
  'title.campaignCard': 'Emisjons{shy}kort',
  'title.emission': 'Grunn{shy}leggende info',
  'title.teamAndNetwork': 'Team og nettverk',
  'title.shareholders': 'Aksjonærer',
  'title.accounting': 'Underlag',
  'title.presentation': 'Presentasjon',
  'title.documents': 'Tilleggs{shy}dokumenter',
  'title.loanConversions': 'Låne{shy}konvertering',
  'title.singlePurposeVehicle': 'SPV',
  'badge.approved': 'Godkjent',
  'badge.review': 'Til kontroll',
  'badge.error': 'Error',
  'badge.ready': 'Klar for godkjenning',
  'badge.readyForApproval': 'Godkjent - klar til ferdigstilling',
  'badge.readyForDenial': 'Avvist - klar til ferdigstilling',
}

export const teamAndNetwork = {
  name: 'Navn',
  title: 'Tittel',
  employmentType: 'Stillingstype',
  'employment.notSet': 'velg stillingstype',
  'employment.fullTime': 'Heltid',
  'employment.partTime': 'Deltid',
  'employment.contractor': 'Innleid',
  ownership: 'Andel aksjer',
  bio: 'Biografi',
  partnerBio: 'Beskrivelse',
  addNew: 'Legg til ny',
  someLink: '{some} lenke',
}

export const singlePurposeVehicle = {
  'spvBankInformation.title': 'Emisjonskonto for SPV',
  'spvBankInformation.description': 'Legg inn kontoinformasjon til bankkonto som skal brukes i forbindelse med SPV',
  'spvBankInformation.label.spvBankAccountNumber': 'Kontonummer',
  'spvBankInformation.label.spvBankSwiftNumber': 'SWIFT (BIC) nummer',
  'spvBankInformation.label.spvBankIbanNumber': 'IBAN nummer',
  'spvBankInformation.label.spvBankDocumentation': 'Dokumentasjon',

  'spvCompanyInformation.title': 'Selskapsinformasjon',
  'spvCompanyInformation.description': 'Legg inn generell informasjon om holdingselskapet.',
  'spvCompanyInformation.label.spvCompanyName': 'Selskapsnavn',
  'spvCompanyInformation.label.spvOrganizationNumber': 'Organisasjonsnummer',

  'spvLimitValueShareAmount.title': 'Grenseverdi for SPV',
  'spvLimitValueShareAmount.description':
    'Definer antall aksjer som trengs for å bli registrert i ordinær aksjonærliste for selskapet.',
  'spvLimitValueShareAmount.label': 'Grenseverdi (antall aksjer)',
  'spvLimitValueShareAmount.label.correspondingSum': 'Tilsvarende sum (NOK)',

  'spvNominalShareValue.title': 'Pålydende aksjeverdi for SPV',
  'spvNominalShareValue.description': 'Holdingselskapets pålydende aksjeverdi fylles ut av Folkeinvest.',
  'spvNominalShareValue.label': 'Pålydende aksjeverdi (NOK)',

  'spvFoundingDocument.title': 'Stiftelsesdokument',
  'spvFoundingDocument.description':
    'Last opp holdingsselskapets stiftelsesdokument. Hvis dere ikke har tilgang, så vil Folkeinvest laste opp dette dokumentet',

  'spvStatutes.title': 'Vedtekter',
  'spvStatutes.description':
    'Last opp holdingselskapets vedtekter. Hvis dere ikke har tilgang til vedtektene, så vil folkeinvest laste opp dette dokumentet',

  'spvOperatingAgreement.title': 'Driftsavtale - Caplist',
  'spvOperatingAgreement.description':
    'Last opp holdingselskapets driftsavtale med Caplist. Hvis dere ikke har tilgang til driftsavtale, så vil Folkeinvest laste opp dette dokumentet',

  'spvShareholderAgreement.title': 'Aksjonæravtale',
  'spvShareholderAgreement.description':
    'Last opp holdingselskapets aksjonæravtale. Hvis dere ikke har tilgang til aksjonæravtale, så vil Folkeinvest laste opp dette dokumentet',
}

export const publicInvestmentOfferSinglePurposeVehicle = {
  'spvCompanyInformation.title': 'Selskapsinformasjon',
  'spvCompanyInformation.description': 'Generell informasjon om holdingselskapet',
  'spvCompanyInformation.label.spvCompanyName': 'Selskapsnavn',
  'spvCompanyInformation.label.spvOrganizationNumber': 'Organisasjonsnummer',

  'spvLimitValueShareAmount.title': 'Grenseverdi for SPV',
  'spvLimitValueShareAmount.description':
    'Antall aksjer som trengs for å bli registrert i ordinær aksjonærliste for selskapet.',
  'spvLimitValueShareAmount.label': 'Grenseverdi (antall aksjer)',
  'spvLimitValueShareAmount.label.correspondingSum': 'Tilsvarende sum (NOK)',

  'spvStatutes.title': 'Vedtekter',
  'spvStatutes.description': 'Holdingselskapets vedtekter.',

  'spvOperatingAgreement.title': 'Driftsavtale - Caplist',
  'spvOperatingAgreement.description': 'Holdingselskapets driftsavtale med Caplist.',

  'spvShareholderAgreement.title': 'Aksjonæravtale',
  'spvShareholderAgreement.description': 'Holdingselskapets aksjonæravtale.',
}

export const forms = {
  'labels.name': 'Navn',
  'labels.firstName': 'Fornavn',
  'labels.lastName': 'Etternavn',
  'labels.email': 'Epost',
  'labels.phoneNumber': 'Telefonnummer',
  'labels.guestEmail': 'Gjest {guestNumber} epost',
  'labels.orgNumber': 'Org nr.',
  'labels.companyShortDescriptiption': 'Kort beskrivelse av selskapet',
  'labels.companyName': 'Firmanavn',
  'labels.noOrgNumber': 'Ikke org nr.?',
  'labels.orgOrCompanyName': 'Org nr. eller firmanavn',
  'labels.chairmanName': 'Styreleders navn',
  'labels.ceoName': 'Daglig leders navn',
  'labels.numberOfEmployees': 'Antall ansatte',
  'labels.incomePreviousFiscal': 'Inntekter foregående år',
  'labels.incomeCurrentFiscal': 'Inntekter så langt i år',
  'labels.paidUpCapital': 'Tidligere emisjoner',
  'labels.softFunding': 'Støtte',
  'captions.softFunding': 'Støtte fra Innovasjon Norge, Forskningsrådet eller lignende',
  'labels.shareCapitalFromTeamAndStaff': 'Innskutt fra gründere/team',
  'labels.other': 'Annet',
  'labels.description': 'Beskrivelse',
  'labels.assumedPreallocatedAmount': 'Ny kapital',
  'captions.assumedPreallocatedAmount':
    ' Hvor mye kapital forventer dere å ha på plass innen ferdigstillelse av investeringstilbudet?',
  'labels.convertibleLoansOptions': 'Konvertering',
  'captions.convertibleLoansOptions': `Skal det gjennomføres konverteringer i emisjonen? 
    {br}Eksempler på konvertering som kan gjøres er lån, opsjonsavtaler, påløpte fakturaer ol. 
    {br}Spesifiser de ulike konverteringer nedenfor. 
    {br}Dersom det ikke skal gjennomføres konvertering fylles 0 inn som beløp.`,
  'labels.amount': 'Beløp',
  'labels.shareholdersAgreement': 'Aksjonæravtale',
  'captions.shareholdersAgreement': 'Har bedriften per i dag en aksjonæravtale som nye aksjonærer må ta stilling til?',
  'labels.campaignCardImage': 'Bilde til emisjonskort',
  'labels.title': 'Tittel',
  'labels.campaignCardTitle': 'Selskapsnavn, evt merkevarenavn',
  'labels.website': 'Nettside',
  'labels.caption': 'Innsalgstekst',
  'captions.maxLength': 'maks {maxLength} tegn',

  'errors.fieldIsRequired': 'Dette felted er påkrevd',
  'errors.enterValidEmail': 'Vennligst oppgi gyldig epost',
  'errors.enterValidOrgNumber': 'Vennligst oppgi et gyldig organiasjonsnummer',
  'errors.enterValidPhoneNumber': 'Vennligst oppgi et gyldig telefonnummer',
  'errors.orgOrNameRequired': 'Vennligst oppgi en organisasjon eller et firmanavn',
  'errors.leadAlreadyExists': 'Lead eksisterer allerede',
  'errors.tooLong': 'For lang tekst, maks lengde er {maxLength}',
}

export const adminNavBar = {
  capitalExpansionOverview: 'Kapitalutvidelsesoversikt',
  companyInformation: 'Selskapinformasjon',
  agreement: 'Avtaleinngåelse',
  'agreement.applicationInformation': 'Søknadsinformasjon',
  'agreement.riskEvaluation': 'Risikovurdering',
  'agreement.mandateSetting': 'Innstilling til mandat',
  'agreement.signContract': 'Avtalesignering',
  investmentOfferInformationToolAccess: 'Veiledertilgang',
  investmentOfferInformationStatistics: 'Statistikk',
  approval: 'Godkjenning',
  'approval.dashboard': 'Dashboard',
  'approval.campaignCard': 'Emisjonskort',
  'approval.emission': 'Grunnleggende info',
  'approval.teamAndNetwork': 'Team og nettverk',
  'approval.shareholders': 'Aksjonærer',
  'approval.accounting': 'Underlag',
  'approval.singlePurposeVehicle': 'Single Purpose Vehicle',
  'approval.presentation': 'Presentasjon',
  'approval.approvalDocuments': 'Dokumenter',
  'approval.loanConversions': 'Lånekonvertering',
  'approval.approvalRiskEvaluation': 'Risikovurdering II',
  declarationOfCompleteness: 'Fullstendighetserklæring',
  subscriptionPeriod: 'Tegningsvindu',
  postCampaign: 'Sluttfase',
  'postCampaign.dashboard': 'Dashboard',
  'postCampaign.subscriptions': 'Tegninger',
  'postCampaign.documents': 'Dokumenter',
  backToOldAdmin: 'Til gamle admin',
}

export const adminTopBar = {
  handledBy: 'Behandlet av',
  openInvestmentOfferInformationTool: 'Åpne veileder',
  investmentOfferInformationToolOpened: 'Veileder åpnet',
  terminateProcess: 'Avslutt prosess',
  rejectApplication: 'Avslå søknad',
  emissionProcessTerminated: 'Emisjonsprosess terminert',
  emissionProcessAlreadyTerminated: 'Emisjonsprosess allerede terminert',
  rejectedReason: 'Begrunnelse for avslag',
  rejected: 'Avslått',
  reasonValidationText: 'Oppgi grunn til avslag',
  orgNumber: 'Org. nummer',
  'tooltip.meetings': 'Møter',
  'tooltip.links': 'Lenker',
  'tooltip.settings': 'Innstillinger',
  'tooltip.comments': 'Kommentarer',
  changeFacilitator: 'Endre tilrettelegger',
  selectUser: 'Velg tilrettelegger:',
}

export const adminLogin = {
  title: 'Tilrettelegger-verktøy',
  login: 'Logg inn',
  twoFactorCode: 'Skriv inn 2-faktor-kode',
  'errors.enterValidEmail': 'Vennligst skriv inn din epost',
  'errors.passwordMissing': 'Vennligst skriv inn ditt passord',
  'errors.invalidTwoFactorCode': 'Feil kode. Prøv igjen',
  'errors.loginFailed': 'Innlogging feilet.',
}

export const adminMandateSetting = {
  title: 'Innstilling til mandat',
  approvalModeTitle: 'Forespørsel om godkjenning av mandat',
  approvalModeParagraph: '{name} har sendt deg en forespørsel om godkjenning av mandatet for {companyName}',
  notAuthorizedToConclude: 'Mangler rettigheter for å behandle mandatet.',
  mandateAlreadyConcluded: 'Mandatet er allerede behandlet.',
  mandateNotFound: 'Fant ikke mandat',
  sendForApproval: root.sendForApproval,
  sendForApprovalAgain: 'Send på nytt',
  waitingForAnswer: 'Venter på svar',
  goToApproval: 'Gå til godkjenning',
  approvedAtBy: 'Godkjent {date} av {name}',
  rejectedAtBy: 'Avslått {date} av {name}',
  'error.emissionProcessNotFound': 'Fant ikke emisjon-prosess. Sjekk at URL er skrevet riktig og prøv igjen.',
  'error.riskEvaluationNotFound': 'Det er ingen risikovurdering i prosess.',
}

export const adminCompanyInformation = {
  noDataAlert: 'Selskapsinformasjon blir tilgjengelig etter at søknaden er opprettet',
  'section.companyInformation': 'Selskapinformasjon',
  'subsection.contactInformation': 'Kontaktinformasjon',
  campaignOwner: 'Emisjonseier',
  contributor: 'Bidragsyter',
  addContributor: 'Legg til bidragsyter',
  'contributor.position': 'Rolle',
  'subsection.sectorAndIndustry': 'Sektor og Næring',
  industryCode: 'Næringskode(r)',
  sectorCode: 'Sektorkode',
  foundationDate: 'Stiftelsesdato',
  'section.accounting': 'Regnskap',
  publicAnnualAccounts: 'Offentlig årsregnskap',
  numberOfShares: 'Antall aksjer',
  equity: 'Aksjekapital',
  estimatedCompanyValue: 'Anslått selskapverdi',
  'section.rolesAndOwners': 'Roller og Eiere',
  chairmanName: 'Styreleders navn',
  ceoName: 'Dagligleders navn',
  numberOfEmployees: 'Antall Ansatte',
  pep: 'PEP',
  beneficialOwners: 'Reelle rettighetshavere',
  'header.noCompanyInformation': 'Ingen selskapsinformasjon funnet',
}

export const commonLabels = {
  name: 'Navn',
  address: 'Adresse',
  contactPerson: 'Kontaktperson',
  email: 'E-post',
  phone: 'Telefon',
  companyName: 'Firmanavn',
  organizationNumber: 'Organisasjonsnummer',
  nationalId: 'Personnummer',
  search: 'Søk',
}

export const statusMessages = {
  mandatoryComplete: '{complete} av {total} påkrevde er utfylt',
}

export const adminRiskEvaluation = {
  noContractApplication: 'Vi fant ingen søknader i databasen for denne leaden',
  contractApplicationNotSubmitted: 'Søknad må være sendt inn for å starte risikovurdering',
  startEvaluation: 'Klar for å komme i gang med risikovurdering?',
  startEvaluationButton: 'Start risikovurdering',
  companysAssessmentSection: 'Selskapsvurdering (automatisk sjekk)',
  fiAssessmentSection: 'Folkeinvest sin vurdering (manuell sjekk)',
  beneficialOwnersSection: 'Rettighetshavere (manuell sjekk)',
  striseSection: 'Strise (manuell sjekk)',
  conflictsOfInterestSection: 'Interessekonflikter (manuell sjekk)',
  reputationRiskSection: 'Omdømmerisiko (manuell sjekk)',

  heading: 'Risikovurdering',
  signed: 'Signert av {user} med <risk></risk> risiko, kl {time} den {date}',
  'totalRisk.LOW': 'Lav',
  'totalRisk.MEDIUM': 'Middels',
  'totalRisk.HIGH': 'Høy',
  updateData: 'Oppfrisk data',
  seeEvaluation: 'Se vurdering',
  usefulLinks: 'Nyttige lenker',
  bankruptcyCheck: 'Konkurssjekk',
  strise: 'Strise',
  completeRiskEvaluation: 'Fullfør risikovurdering',
  newBeneficialOwner: 'Ny rettighetshaver',
  editBeneficialOwner: 'Rediger rettighetshaver',
  createBeneficialOwner: 'Opprett rettighetshaver',
  addBeneficialOwner: 'Legg til rettighetshaver',
  deleteBeneficialOwner: 'Slett rettighetshaveren',
  lowRisk: 'Lav risiko',
  increasedRisk: 'Økt risiko',
  isCorporation: 'Selskapet er AS',
  orgNumberRegistered: 'Organisasjonsnummer registrert',
  enterpriseNameProvided: 'Fullt foretaksnavn oppgitt',
  validatedWithBankId: 'Søker er validert med BankId',
  businessAddressRegistered: 'Forretningsadresse registrert',
  chairmanMatches: 'Styreleder matcher med Brreg',
  noMLOrTerrorismFinancing: 'Utsteders produkt og tjenester gir ikke økt risiko for hvitvasking og terrorfinansiering',
  noSignificantCashUse: 'Ikke vesentlig bruk av kontanter',
  notInFishing: 'Ikke innenfor fiskeri',
  notInRealEstateDevelopment: 'Ikke innenfor eiendomsutvikling',
  notInConstruction: 'Ikke innenfor bygg og anlegg',
  activitiesConsideredLegal: 'Folkeinvest vurderer utsteders virksomhet til å være lovlig.',
  withinLowCorruptionAndCrimeCountry:
    'Selskapet opererer i EØS-stater eller tredjeland med lavt nivå av korrupsjon og annen kriminalitet',
  clearCommunicationAndInformation: 'Selskapet fremstår ryddig i foreløpig forelagt informasjon og kommunikasjon',
  checked: 'Sjekket',
  riskIdentified: 'Risiko\nidentifisert',
  handled: 'Håndtert',
  'beneficialOwners.nationalId': 'Personnummer',
  'beneficialOwners.name': 'Navn',
  'beneficialOwners.company': 'Selskap',
  'beneficialOwners.share': 'Andel',
  'beneficialOwners.pep': 'Pep',
  'beneficialOwners.aml': 'Aml',
  'beneficialOwners.yearOfBirth': 'Fødselsår',
  'beneficialOwners.sanctionCase': 'Sanksjonssak',
  'beneficialOwners.noMoneyLaundring': 'Ingen hvitvasking/\nterror Risiko',
  'strise.ceoAndBoard': 'Daglig leder og styret',
  'strise.relatedCompanies': 'Relaterte selskaper',
  'strise.paymentMarkings': 'Betalingsanmerkninger ',
  'strise.kyc': 'KYC',
  'strise.lastEventsCapitalSeeker': 'Siste hendelser for kapitalsøkende selskap ',
  'strise.bankruptcyCheck': 'Konkurssjekk på relevante personer ',
  'strise.creditCheck': 'Kredittsjekk',
  'conflictsOfInterest.interestInIssuer': 'Personer tilknyttet Folkeinvest har interesse i utsteder',
  'conflictsOfInterest.concurrentIssues':
    'Samtidige emisjoner med utstedere som retter seg mot samme marked/investorer.',
  'conflictsOfInterest.intrinsicCommunity':
    'Iboende interessefellesskap mellom utstederkunde og Folkeinvest som tilrettelegger',
  'conflictsOfInterest.valuationOfOwnBusiness': 'Utsteders verdivurdering av egen virksomhet',
  'conflictsOfInterest.assessmentOfRiskFactors': 'Utsteders vurdering av risikoforhold forbundet med egen virksomhet',
  'conflictsOfInterest.productionOfEconomicConditions':
    'Utsteders fremstilling av økonomiske forhold, samt fremtidsutsikter',
  'conflictsOfInterest.lackOfTransparency': 'Utsteders manglende transparens vedrørende historikk',
  'conflictsOfInterest.propensityToProduceInvestment':
    'Utsteders tilbøyelighet til å fremstille investeringsmuligheten unyansert',
  'conflictsOfInterest.desireForQuickProcess':
    'Utsteders ønske om en rask prosess og en vellykket plassering til høyest mulig verdsettelse',
  'signRiskEvaluation.title': 'Signering av Risikovurdering',
  'signRiskEvaluation.automaticEvaluation': 'Automatisk vurdering',
  'signRiskEvaluation.setCustomEvaluation': 'Sett egen vurdering med begrunnelse',
  'signRiskEvaluation.reasoning': 'Begrunnelse',
  'signRiskEvaluation.signEvaluation': 'Signer vurdering',
  'signRiskEvaluation.sign': 'Signer',
  'signRiskEvaluation.successfullySigned': 'Risikovurderingen ble signert',
  'reputationRisk.issuerReputationRisk': 'Omdømmerisiko forbundet med utstederkunden',
}

export const contract = {
  'cancelContract.description': 'Er du sikker på at du vil avbryte signeringsprosesen?',
  'cancelContract.header': 'Avbryt signeringsprosess',
  'contributor.hasRightsAlone': 'Har signaturrett alene',
  'contributor.hasRightsInCombinationWith': 'Har signaturrett i fellesskap med',
  'contributor.noRights': 'Har <bold>ikke</bold> signaturrett i selskapet',
  abortSignature: 'Avbryt signering',
  agreement: 'Avtale',
  awaitingSignature: 'Venter på signatur',
  clickToCopySigningUrlToClipboard: 'Klikk for å kopiere signerings-lenke til utklippstavlen',
  completedAt: '{date} - Avtalen er signert av begge parter.',
  creatingSignatureProcess: 'Oppretter signeringsprosess',
  customerAgreement: 'Kundeavtale',
  gettingDocumentReady: 'Klargjør dokument, vennligst vent',
  openContractSigningInNewWindow: 'Åpne avtalesignering i nytt vindu',
  peopleChosen: '{amount} personer valgt',
  positiveMandateRequired: 'Positiv innstilling til mandat kreves for å opprette kundeavtale',
  showSignedContract: 'Vis signert avtale',
  signContract: 'Signer avtale',
  signed: 'Signert',
  signedByFolkeinvest: 'Signert av Folkeinvest',
  signedByIssuer: 'Signert av utsteder',
  signedCustomerAgreement: 'Signert kundeavtale',
  startSignatureProcess: 'Send til signering',
  terminateSignatureProcess: 'Avbryt signeringsprosess',
  title: 'Avtalesignering',
  peopleWithSignatureRights: 'Personer med signaturrettigheter i selskapet',
  dataFetchedFromRoaring: 'Data hentet fra roaring',
  couldNotFindRightPerson: 'Fant ikke riktig person?',
  searchDatabaseForProfile: 'Søk i databasen etter brukerprofil for signering',
  informationAboutChosenPerson: 'Informasjon om valgt person',
  started: 'Startet',
  activeSignature: 'Signeringsprosess aktiv',
  'signeeStatus.notSigned': 'Ikke signert',
  'signeeStatus.signed': 'Signert',
  'signeeStatus.signatureStatus': 'Signeringsstatus',
  'signatureStatus.cancelled': 'Kansellert',
  'signatureStatus.expired': 'Utgått',
  'signatureStatus.partiallySigned': 'Delvis signert',
  'signatureStatus.created': 'Opprettet',
  'signatureStatus.none': 'Ingen',
  'signatureStatus.signed': 'Signert',
  'signatureStatus.packaged': 'Signert og ferdigstilt',
  'declarationOfCompleteness.previousSignatures': 'Tidligere signeringsprosesser og oppdateringer',
  'declarationOfCompleteness.lastSignatureCompleted': 'Siste signering gjennomført {datetime}',
  'declarationOfCompleteness.createNew': 'Opprett ny signeringsprosess',
  'declarationOfCompleteness.cancelCreate': 'Avbryt opprettelse',
  'declarationOfCompleteness.noActiveSignatures': 'Ingen aktive signeringsprosesser',
  'declarationOfCompleteness.noPreviousSignatures': 'Ingen signeringsprosesser påstartet',
  'declarationOfCompletenessTable.createdAt': 'Opprettet',
  'declarationOfCompletenessTable.name': 'Navn',
  'declarationOfCompletenessTable.status': 'Status',
  'declarationOfCompletenessTable.document': 'Dokument / Begrunnelse',
}

export const meetings = {
  defaultMeetingTitle: 'Nytt møte',
  editableTitlePlaceholder: 'Hva heter dette møtet?',
  editableTitleSubmitButtonText: 'Lagre',
  textAreaPlaceholder: 'Skriv et notat',
  textAreaSubmitButtonText: 'Lagre',
  'drawer.notes': '{notes, plural, one {1 notat} other {{notes} notater}}',
  'drawer.createNew': 'Opprett nytt',
  'drawer.meetings': 'Møter',
  'type.INTRODUCTION': 'Førstemøte',
  'type.KICKOFF': 'Oppstartsmøte',
  'type.MARKETING': 'Markedsføringsmøte',
  'type.ACCOUNTING': 'Underlagsmøte',
  'type.STATUS': 'Statusmøte',
  'type.GENERIC': 'Generisk møte',
  completeMeeting: 'Fullfør møte',
  requiredMeetings: 'Obligatoriske møter',
  notFound: 'Fant ikke møtet',
}

export const emissionProcessContributor = {
  infoText:
    'Legg til eller slett bidragsytere, eller overføre emisjonens eierskap. Etter du har valgt hvem som skal bli den nye eieren, vil du automatisk bli lagt til i listen over bidragsytere. Velg hvilke som skal motta varsler i systemet.',
  receiveNotifications: 'Motta varsler',
  notifications: 'Varsler',
  ownersAndContributors: 'Eiere og bidragsytere',
  owner: 'Eier',
  contributorsEmail: 'Bidragsyters epost',
  accountNeeded: 'En bidragsyter må ha en Folkeinvest-konto med BankID-verifisert profil',
  addContributor: 'Legg til bidragsyter',
  failedToAdd: 'Kunne ikke legge til bidragsyter',
  failedToAddDueToUserNotFound:
    'Kunne ikke legge til bidragsyter. Fant ingen verifisert brukerprofil på denne eposten.',
  changeOwnerHeader: 'Du er i ferd med å overføre eierskap',
  changeOwnerText1:
    'Etter du har valgt hvem som skal bli den nye eieren, vil du automatisk bli lagt til i\n' +
    '            listen over bidragsytere.',
  changeOwnerText2:
    'Vær oppmerksom på at du mister rettigheten til å legge til/fjerne bidragsytere ved å\n' +
    '            utføre denne handlingen.',
  transferCampaignOwner: 'Overfør emisjon',
  deleteContributor: 'Slett bidragsyter',
  deleteConfirmMessage: 'Er du sikker på at du vil fjerne bidragsyter?',
}

export const emissionApprovalHeaders = {
  dashboard: 'Investeringstilbud-godkjenning',
  campaignCard: 'Emisjonskort',
  emission: 'Grunnleggende informasjon',
  'emission.companyInfo': 'Selskap',
  'emission.financialInfo': 'Finansiell informasjon',
  'emission.equityOffering': 'Kapitalutvidelse',
  teamAndNetwork: 'Team og nettverk',
  'teamAndNetwork.board': 'Styre',
  'teamAndNetwork.partners': 'Partnere',
  'teamAndNetwork.team': 'Team',
  shareholders: 'Aksjonærer',
  accounting: 'Underlag',
  presentation: 'Presentasjon',
  approvalDocuments: 'Dokumenter',
  'approvalDocuments.additionalDocuments': 'Tilleggsdokumenter',
  'approvalDocuments.publishedDocuments': 'Dokumentpublisering',
  loanConversions: 'Lånekonverteringer',
  singlePurposeVehicle: 'Single Purpose Vehicle (SPV)',
  reviews: 'Tilbakemeldinger',
  reviewHistory: 'Meldingshistorikk',
}

export const issuerShareholders = {
  existingShareholders: 'Eksisterende aksjonærer',
  sharesRemaining: 'av {shares} tilgjengelige',
  dontCloseDialogAfterCreate: 'Ikke lukk vinduet etter lagring',
  dontCloseDialogAfterCreateInfo: 'Kan benyttes dersom man ønsker å legge til flere etter hverandre',
  notAllocatedShares: '* Gjenværende aksjer: {sharesRemaining}',
  tooManySharesAllocated: 'For mange aksjer registrert. {sharesRemaining} aksjer over selskapets totale antall.',
  shareholderSaved: 'Aksjonær lagret',
  shareholderDeleted: 'Aksjonær slettet',
  'field.name': 'Navn',
  'field.email': 'E-post',
  'field.shares': 'Aksjer',
  'field.share': 'Andel (%)',
  editShareholder: 'Rediger aksjonær',
  newShareholder: 'Ny aksjonær',
  'importFromCSV.rowsContainsErrors': 'Rader med feil (blir ikke importert)',
  'importFromCSV.footer.rowsWithErrors': 'Rader med feil',
  'importFromCSV.rowContainsErrors': 'Rad inneholder feil og blir ikke lagt til i listen',
  'importFromCSV.helpText': `
  Her kan du importere eksisterende aksjonærer fra CSV-fil (Comma-separated values).{br}
  Formatet som støttes er "NAVN, EPOST, ANTALL AKSJER".{br}
  3 kolonner, der EPOST må være en gyldig epost-adresse og ANTALL AKSJER må være heltall.{br}
  Disse må være separert med komma, semikolon eller tabulator.
  Hver aksjonær må ligge på sin egen rad/linje.{br}{br}
  Eksempel:{br}
  <example>
  Ola Nordmann,ola.nordmann@epost.no,500{br}
  Kari Nordmann,kari.nordmann@epost.no,250{br}
  Hanne Normann,hanne.nordmann@epost.no,750{br}
  </example>{br}{br}
  Etter valg av fil vil du få mulighet til å se over hva som blir importert før dette lagres i veileder.
  `,
  'importFromCSV.shareholdersAdded': '{count, plural, one {{count} aksjonær} other {{count} aksjonærer}} importert',
}

export const issuerLoanConversions = {
  'convertibleLoan.title': 'Konvertible lån som skal konverteres i denne emisjonen',
  'convertibleLoan.amount': 'Beløp på konvertering av lån: {amount}',
  'convertibleLoan.saved': 'Konvertibelt lån lagret',
  'convertibleLoan.deleted': 'Konvertibelt lån slettet',
  'convertibleLoan.edit': 'Rediger konvertibelt lån',
  'convertibleLoan.new': 'Nytt konvertibelt lån',

  'field.name': 'Navn',
  'field.orgNumber': 'F.nr./org.nr.',
  'field.numberOfShares': 'Antall',
  'field.pricePerShare': 'Kurs',
  'field.amount': 'Beløp',
}

export const statisticsPage = {
  viewMoreVideos: 'Se flere videoer',
}

export const countries = {
  norway: 'Norge',
}

export const adminRoles = {
  mentor: 'Mentor',
  solo: 'Solo',
  trainee: 'Trainee',
}

export const comments = {
  header: 'Kommentar',
  external: 'Ekstern',
  hidden: 'Skjult',
  hideComment: 'Skjul kommentar',
  showComment: 'Vis kommentar',
  edit: 'Rediger',
  goTo: 'Gå til kommentaren',
  'drawer.title': 'Kommentarer',
  'drawer.sortBy': 'Sortér etter',
  'drawer.sortByDate': 'Dato',
  'drawer.sortByPage': 'Side',
  'drawer.showHidden': 'Se skjulte kommentarer',
  'drawer.noComments': 'Ingen kommentarer',
  'drawer.hiddenComment': '{comments, plural, one {1 skjult kommentar} other {{comments} skjulte kommentarer}}',
  'drawer.showComment': 'Vis',

  'paths.contractApplication': contractApplication['header.applicationInfo'],
  'paths.contractApplication.companyInfo': contractApplication['form.companyInfoHeading'],
  'paths.contractApplication.teamAndStaff': contractApplication['form.teamAndStaffHeading'],
  'paths.contractApplication.aboutCompany': contractApplication['form.companyValuesHeading'],
  'paths.contractApplication.incomes': contractApplication['form.incomesHeading'],
  'paths.contractApplication.incubator': contractApplication['form.incubatorHeading'],
  'paths.contractApplication.investments': contractApplication['form.investmentsHeading'],
  'paths.contractApplication.capitalAndLoans': contractApplication['form.capital'],
  'paths.contractApplication.shareholdersAgreement': contractApplication['form.shareholdersAgreementHeading'],

  'paths.riskEvaluation': adminRiskEvaluation.heading,
  'paths.riskEvaluation.isCorporation': adminRiskEvaluation.isCorporation,
  'paths.riskEvaluation.orgNumberRegistered': adminRiskEvaluation.orgNumberRegistered,
  'paths.riskEvaluation.enterpriseNameProvided': adminRiskEvaluation.enterpriseNameProvided,
  'paths.riskEvaluation.validatedWithBankId': adminRiskEvaluation.validatedWithBankId,
  'paths.riskEvaluation.businessAddressRegistered': adminRiskEvaluation.businessAddressRegistered,
  'paths.riskEvaluation.chairmanMatches': adminRiskEvaluation.chairmanMatches,

  'paths.riskEvaluation.activitiesConsideredLegal': 'Lovlig virksomhet',
  'paths.riskEvaluation.clearCommunicationAndInformation': 'Klar kommunikasjon',
  'paths.riskEvaluation.noMLOrTerrorismFinancing': 'Hvitvasking og terrorfinansiering',
  'paths.riskEvaluation.noSignificantCashUse': 'Bruk av kontanter',
  'paths.riskEvaluation.notInConstruction': 'Bygg og anlegg',
  'paths.riskEvaluation.notInFishing': 'Fiskeri',
  'paths.riskEvaluation.notInRealEstateDevelopment': 'Eiendomsutvikling',
  'paths.riskEvaluation.withinLowCorruptionAndCrimeCountry': 'Korrupsjon',

  'paths.riskEvaluation.ceoAndBoard': adminRiskEvaluation['strise.ceoAndBoard'],
  'paths.riskEvaluation.relatedCompanies': adminRiskEvaluation['strise.relatedCompanies'],
  'paths.riskEvaluation.paymentMarkings': adminRiskEvaluation['strise.paymentMarkings'],
  'paths.riskEvaluation.lastEventsCapitalSeeker': 'Strise siste hendelsers',
  'paths.riskEvaluation.bankruptcyCheck': 'Konkurssjekk',
  'paths.riskEvaluation.kyc': adminRiskEvaluation['strise.kyc'],
  'paths.riskEvaluation.creditCheck': adminRiskEvaluation['strise.creditCheck'],

  'paths.riskEvaluation.interestInIssuer': 'Interessekonflikt i utsteder',
  'paths.riskEvaluation.concurrentIssues': 'Markedskonflikt',
  'paths.riskEvaluation.valuationOfOwnBusiness': 'Verdivurdering av egen virksomhet',
  'paths.riskEvaluation.assessmentOfRiskFactors': 'Vurdering av risiko i egen virksomhet',
  'paths.riskEvaluation.productionOfEconomicConditions': 'Økonomiske forhold',
  'paths.riskEvaluation.lackOfTransparency': 'Manglende transparens',
  'paths.riskEvaluation.propensityToProduceInvestment': 'Manglende tilbøyelighet',
  'paths.riskEvaluation.desireForQuickProcess': 'Rask prosess & vellykket plassering',

  'paths.riskEvaluation.beneficialOwner': 'Reell rettighetshaver',
}

export const emissions = {
  header: 'Kapitalutvidelseoversikt',
  addNewLead: 'Legg til ny lead',
  overview: 'Leads oversikt',
  emissionOverview: 'Emisjons-oversikt',
  filterActivity: 'Filtrer aktivitet',
  filterCompany: 'Filtrer selskap',
  filterPriority: 'Filtrer prioritet',
  filterLead: 'Filtrer lead',
  filterFacilitator: 'Filtrer behandler',
  filterMyEmissions: 'Vis mine emisjoner',
  'filter.multipleSelected': '{count} valgt',
  'filter.notFacilitator': 'Du er ikke registrert som en behandler',
  companyName: 'Selskapsnavn',
  firstContactAt: 'Første kontakt',
  lastProcessedAt: 'Siste behandlet',
  lastFiveUpdates: 'Siste 5 oppdateringer (når, hvem, hva)',
  nextContactAt: 'Neste kontakt',
  lastUpdatedAt: 'Sist oppdatert',
  activity: 'Aktivitet',
  priority: 'Prioritet',
  label: 'Lead',
  employee: 'Behandler',
  'priority.undefined': 'Udefinert',
  'priority.low': 'Lav',
  'priority.medium': 'Middels',
  'priority.high': 'Høy',
  noEmissions: 'Fant ingen emisjoner',
  noLeads: 'Fant ingen leads',
  noMeetings: 'Ingen møter booket',
  INFO_MEETING: 'Infomøte',
  CONTRACT_APPLICATION: 'Søknadskjema',
  CONTRACT_APPLICATION_SUBMITTED: 'Søkt om avtale',
  REGISTRATION_FORM: 'Registreringsskjema',
  RISK_EVALUATION: 'Risikovurdert',
  MANDATE: 'Innstilling til Mandat',
  MANDATE_APPROVED: 'Godkjent mandat',
  newLead: 'Ny lead',
  navigateToLead: 'Gå til lead',
}

export const issuerWebApp = {
  'header.previewLink': 'Forhåndsvisning',
  'header.invOfferLink': 'Se investeringstilbud',
  'header.marketingLink': 'Markedsføring',
  'header.myProfile': 'Min profil',

  'navigationHeaders.loanConversions': 'Lånekonverteringer',

  'navigationMenuLink.dashboard': 'Dashboard',
  'navigationMenuLink.campaignCard': 'Emisjonskort',
  'navigationMenuLink.emissionInformation': 'Grunnleggende informasjon',
  'navigationMenuLink.emissionInformation.companyInfo': 'Selskap',
  'navigationMenuLink.emissionInformation.financialInfo': 'Finansiell informasjon',
  'navigationMenuLink.emissionInformation.equityOffering': 'Emisjonsdetaljer',
  'navigationMenuLink.teamAndNetwork': 'Team, styre og partnere',
  'navigationMenuLink.teamAndNetwork.team': 'Team',
  'navigationMenuLink.teamAndNetwork.board': 'Styre',
  'navigationMenuLink.teamAndNetwork.partners': 'Partnere',
  'navigationMenuLink.shareholders': 'Aksjonærer',
  'navigationMenuLink.accounting': 'Underlag',
  'navigationMenuLink.presentation': 'Presentasjon',
  'navigationMenuLink.additionalDocuments': 'Tilleggsdokument',
  'navigationMenuLink.loanConversions': 'Lånekonverteringer',
  'navigationMenuLink.statistics': 'Statistikk',
  'navigationMenuLink.statistics.keyNumbers': 'Nøkkeltall',
  'navigationMenuLink.statistics.demography': 'Demografi',
  'navigationMenuLink.statistics.markers': 'Tidsmarkører',
  'navigationMenuLink.discussion': 'Diskusjon',
  'navigationMenuLink.followers': 'Interesserte',
  'navigationMenuLink.reservations': 'Tegninger',
  'navigationMenuLink.reservations.reservations': 'Tegninger',
  'navigationMenuLink.settings': 'Innstillinger',
  'navigationMenuLink.settings.contributors': 'Bidragsytere',
  'navigationMenuLink.settings.email': 'E-post',
  'navigationMenuLink.settings.campaignCard': 'Emisjonskort',
  'navigationMenuLink.documents': 'Dokumenter',
  'navigationMenuLink.singlePurposeVehicle': 'SPV',
  'signature.questionsOrOther': 'Har du spørsmål rundt dette kan du <contactUs>ta kontakt</contactUs>.',
  'signature.signDocument': 'Signer dokument',
  'signature.whenReadyClickButton': 'Når du er klar til å signere, klikk på knappen under',
  'signature.readyForSignature': 'Hei! Dokumentet er klart for signering.',
  'signature.mayCloseWindow': 'Du kan nå lukke dette vinduet.',
  'signature.signatureCompleted': 'Signering gjennomført!',
  'signature.documentIsProcessing': 'Dokumentet er til behandling.',
  'signature.documentNotFound': 'Fant ikke signeringsdokument!',
  'signature.customerAgreement.readyForSignature': 'Hei! Deres avtale er klar for signering.',
  'signature.customerAgreement.lookingForwardToCooporation': `Vi gleder oss til å få dere som kunde. Når du er klar, klikk 'Signer avtale' nedenfor.`,
  'signature.customerAgreement.signDeal': 'Signer avtale',
  'signature.customerAgreement.contractIsProcessing':
    'Vi vil behandle avtalen og gi beskjed når verktøyet blir tilgjengelig så snart som mulig.',
  'signature.documentNotFoundExtended':
    'Fant ingen aktive dokumenter til signering med gitt url. Sørg for at adressen er korrekt.',
}

export const additionalDocumentsTable = {
  heading: 'Legg til dokumenter',
  subHeading: `Her kan andre relevante dokumenter som ikke er forespurt tidligere lastes opp.
    {br}
    Se hjelpetekst for ytterligere beskrivelse.`,
  title: 'Tittel',
  name: 'Filnavn',
  date: 'Dato',
  addDocument: 'Legg til nytt dokument',
  noDocuments: 'Ingen dokumenter er lastet opp',
  moveUp: 'Flytt opp',
  moveDown: 'Flytt ned',
  missingTitle: 'Mangler tittel',
  missingFile: 'Mangler fil',
  showDeletedDocuments: 'Vis slettede dokumenter',
}

export const investmentOfferInformationFields = {
  approvalCanceled: 'Ferdigstillingen er kansellert',
  bankingInfo: 'Bankkonto for kapitalutvidelsen',
  cancelApproval: 'Kanseller ferdigstilling',
  cancelApprovalWarning:
    'Når du kansellerer ferdigstillingen, mister du alle nye kommentarer og godkjent/avslått statuser.',
  companyCertificateDocument: 'Firmaattest',
  companyValuation: 'Verdsettelse av selskapet',
  coreCompetencyPossession: 'Besittelse av kjernekompetanse',
  customerGroup: 'Kundegruppe',
  equity: 'Egenkapital',
  expectedPositiveOperatingResult: 'Driftsresultat',
  foundingDocument: 'Stiftelsesdokument',
  fundraisingTarget: 'Anslått kapitalbehov (målsum)',
  furtherFinancing: 'Videre finansiering',
  incubatorOrAccelerator: 'Inkubator, akselerator, næringshage eller tilsvarende',
  investmentLimits: 'Investeringsgrenser for den enkelte investor',
  liquidity: 'Likviditet',
  loanFromShareholders: 'Lån fra aksjonærer',
  loans: 'Låneavtaler',
  market: 'Markeder',
  nationalDevelopmentBankLoan: 'Har dere fått tilsagn på lån fra Innovasjon Norge?',
  numberOfEmployees: 'Antall ansatte',
  numberOfManYears: 'Antall årsverk siden stiftelse',
  options: 'Opsjonsavtaler/tegningsretter',
  paidUpCapital: 'Tidligere kapitalutvidelser',
  partOfCorporation: 'Konsernstruktur',
  paymentRemarks: 'Betalingsanmerkninger',
  previousStartupExperience: 'Oppstartserfaring',
  runway: 'Runway',
  sendForApproval: root.sendForApproval,
  shareholderInformationManagement: 'Informasjonshåndtering',
  sharesToIssue: 'Andel som utstedes',
  softFunding: 'Har dere mottatt offentlig støtte (tilskudd)?',
  statutesChanges: 'Vedtektsendringer',
  statutesDocument: 'Vedtekter',
  taxIncentiveScheme: 'Skatteinsentivordningen',
}

export const investmentOfferInformationFieldLabels = {
  'market.marketArea.norway': 'Norge',
  'market.marketArea.scandinavia': 'Skandinavia',
  'market.marketArea.europe': 'Europa',
  'market.marketArea.global': 'Verden',
  'coreCompetencyPossession.coreCompetencies.founders': 'Gründere',
  'coreCompetencyPossession.coreCompetencies.board': 'Styret',
  'coreCompetencyPossession.coreCompetencies.employees': 'Ansatte',
  'coreCompetencyPossession.coreCompetencies.hiredConsultants': 'Innleide konsulenter',
  'coreCompetencyPossession.coreCompetencies.notCovered': 'Ikke dekket',
  'coreCompetencyPossession.coreCompetencies.hiring': 'Det arbeides med å skaffe resurser',
  'previousStartupExperience.experience.hasEstablished': 'Gründer(e) har tidligere etablert selskap',
  'previousStartupExperience.experience.hasSold': 'Gründer(e) har tidligere solgt selskap med overskudd',
  'previousStartupExperience.experience.wentBankrupt':
    'Grundere har tidligere hatt en formell rolle i et selskaps som har gått konkurs',
  'previousStartupExperience.experience.boardHasSold': 'Noen i styret har tidligere solgt et selskap med overskudd',
  'previousStartupExperience.experience.noExperience': 'Ikke tidligere oppstartserfaring',
  'numberOfEmployees.fullTime': 'Antall fulltidsansatte',
  'numberOfEmployees.partTime': 'Antall deltidsansatte',
  'numberOfManYears.manYears': 'Antall årsverk',
  incubatorOrAcceleratorHasNotParticipated: 'Ikke tilknyttet',
  'incubatorOrAccelerator.name': 'Navn på inkubator',
  'incubatorOrAccelerator.startDate': 'Start dato',
  'incubatorOrAccelerator.endDate': 'Slutt dato',
  'incubatorOrAccelerator.inProgress': 'Pågående',
  'softFunding.hasNotRecievedFunding': 'Ingen støtte mottatt',
  'softFunding.softFundingRounds.amount': 'Beløp',
  'softFunding.softFundingRounds.amount.placeholder': 'f.eks 2 000 000',
  'softFunding.softFundingRounds.nameOfInstitution': 'Navn på institusjon',
  'softFunding.softFundingRounds.institutions': 'Institusjon',
  'softFunding.softFundingRounds.institutions.researchCouncil': 'Forskningsrådet',
  'softFunding.softFundingRounds.institutions.innovationNorway': 'Innovasjon Norge',
  'softFunding.softFundingRounds.institutions.other': 'Annet',
  'softFunding.softFundingRounds.documentation': 'Last opp dokumentasjon',
  'nationalDevelopmentBankLoan.noLoanRecieved': 'Ingen lån mottatt',
  'nationalDevelopmentBankLoan.amount': 'Beløp',
  'nationalDevelopmentBankLoan.amount.placeholder': 'f.eks 2 000 000',
  'nationalDevelopmentBankLoan.documentation': 'Last opp dokumentasjon',
  'expectedPositiveOperatingResult.today': 'I dag',
  'expectedPositiveOperatingResult.oneToTwo': '1-2 år',
  'expectedPositiveOperatingResult.threeToFive': '3-5 år',
  'expectedPositiveOperatingResult.overFive': 'Over 5 år',
  options: 'Selskapet har følgende opsjonsavtaler, tegningsretter eller lignende som kan påvirke eierstrukturen:',
  'options.hasNoOptions':
    'Selskapet har ikke opsjonsavtaler, tegningsretter eller lignende som kan påvirke eierstrukturen.',
  'options.numberOfShares': 'Antall aksjer',
  'options.title': 'Tittel',
  'options.period': 'Lengde',
  'options.pricePerShare': 'Pris per aksje',
  'options.numberOfShares.placeholder': 'f.eks 200',
  'options.title.placeholder': 'f.eks tittel',
  'options.period.placeholder': 'f.eks 2 måneder',
  'options.pricePerShare.placeholder': 'f.eks 20',
  'options.documentation': 'Last opp dokumentasjon',
  'paidUpCapital.paidUpCapitalAmount': 'Egenkapital',
  'paidUpCapital.dateOfEmission': 'Dato',
  'paidUpCapital.valuation': 'Verdsettelse (Pre-money)',
  'paidUpCapital.valuationPostMoney': 'Verdsettelse (Post-money)',
  'paidUpCapital.paidUpCapitalAmount.placeholder': 'f.eks. 500 000',
  'paidUpCapital.valuation.placeholder': 'f.eks. 500 000',
  'equity.hasEnoughAssetsToCoverDebt': 'Har selskapet eiendeler som ved salg kan dekke gjeld/forpliktelser?',
  'liquidity.hasCashToCoverCommitmentsOnDueDate': 'Har selskapet kontanter til å dekke forpliktelser ved forfall?',
  'liquidity.runway': 'Hvor lenge?',
  'liquidity.UNDER_THREE': 'Under 3 mnd',
  'liquidity.THREE_TO_SIX': '3-6 mnd',
  'liquidity.OVER_SIX': 'Over 6 mnd',
  'expectedPositiveOperatingResult.TODAY': 'I dag',
  'expectedPositiveOperatingResult.ONE_TO_TWO': '1-2 år',
  'expectedPositiveOperatingResult.THREE_TO_FIVE': '3-5 år',
  'expectedPositiveOperatingResult.OVER_FIVE': 'Over 5 år',
  'shareholderInformationManagement.': 'Forklaring',
  'companyValuation.numberOfShares': 'Antall aksjer (per dags dato)',
  'companyValuation.shareCapital': 'Aksjekapital',
  'companyValuation.subscriptionPricePerShare': 'Tegningskurs',
  'companyValuation.valuation': 'Verdsettelse',
  'companyValuation.nominalValuePerShare': 'Pålydende per aksje',
  'companyValuation.valueDifferentialPerShare': 'Overkurs',
  'fundraisingTarget.targetAmount': 'Anslått kapitalbehov (målsum)',
  'fundraisingTarget.minAmount': 'Min-sum',
  'fundraisingTarget.minAmount.description':
    '30% av målsum er minimumskravet til valideringskapital, som er terskelen for at investeringstilbudet kan offentliggjøres',
  'sharesToIssue.minSharesToIssue': 'Nedre grense ',
  'sharesToIssue.maxSharesToIssue': 'Øvre grense',
  'sharesToIssue.investedEquity': 'Egenkapital',
  'sharesToIssue.shareEmitted': 'Andel utstedt',
  'sharesToIssue.equity': 'Aksjekapital',
  'sharesToIssue.valuation': 'Selskapets verdi',
  'investmentLimits.minInvestmentInSharesPerInvestor': 'Nedre investeringsgrense',
  'investmentLimits.minInvestmentInSharesPerInvestor.error': 'Kan ikke overstige {amount} kr. ({shares} aksjer)',
  'investmentLimits.error.belowLowestInvestmentLimit': 'Øvre investeringsgrense kan ikke være lavere enn nedre grense.',
  'investmentLimits.error.aboveHighestInvestmentLimit':
    'Øvre investeringsgrense kan ikke være høyere enn maks antall utstedte aksjer',
  'investmentLimits.maxInvestmentInSharesPerInvestor': 'Øvre investeringsgrense',
  'investmentLimits.limitForSubscriptions': 'Grenser for tegninger',
  'investmentLimits.shares': 'Aksjer',
  'investmentLimits.amount': 'Beløp',
  'investmentLimits.pricePerShares': 'Pris per aksje: {value}',
  'investmentLimits.lowerLimit': 'Nedre grense',
  'investmentLimits.upperLimit': 'Øvre grense',
  'runway.numberOfMonths': 'Antall måneder',
  'bankingInfo.documentation': 'Dokumentasjon (pdf)',
  'bankingInfo.bankAccountNumber': 'Kontonummer',
  'bankingInfo.bankSwiftNumber': 'SWIFT (BIC) nummer',
  'bankingInfo.bankIbanNumber': 'IBAN nummer',
}

export const investmentOfferInformationFieldIntroText = {
  foundingDocument: 'Last opp selskapets stiftelsesdokument.',
  statutesDocument: 'Last opp selskapets gjeldende vedtekter.',
  companyCertificateDocument:
    'Last opp en firmaattest som er yngre enn 3 måneder. Firmaattesten kan <link>lastes ned her.</link>',
  incubatorOrAccelerator:
    'Tilknytning til inkubator-, akseleratorprogram, næringshage eller tilsvarende beskrives her.',
  market: 'Kryss av for alle relevante markeder. Forklar tilstedeværelse og/eller tid for inngang i disse markedene.',
  customerGroup:
    'Hvem er deres kundegruppe? Hvilken kundegruppe er det primært som vil betale for deres tjenester/produkter?',
  coreCompetencyPossession: 'Kompentansen som kreves for å kunne utvikle og levere produktet/tjenesten til selskapet.',
  previousStartupExperience: 'Tidligere oppstartserfaring for gründer/gründere.',
  numberOfEmployees: 'Antall som arbeider fulltid og deltid i selskapet per tidspunkt. Forklar.',
  numberOfManYears: 'Antall akkumulerte årsverk til dags dato. Eksempel: To ansatte i to år tilsvarer fire årsverk.',
  partOfCorporation: 'Er selskapet del av en konsernstruktur? Dersom ja, spesifiser.',
  bankingInfo: `Legg inn kontoinformasjon til bankkonto som skal brukes i forbindelse med kapitalutvidelsen.
    {br}
    Informasjonen vil kun bli delt med de som har tegnet seg i emisjonen dersom den oppnår suksess.
    {br}
    Dokumentasjonen som skal lastes opp er bekreftelse fra bank på sperret emisjonskonto i henhold til avtalen.`,

  softFunding: 'Om dere har søkt støtte og fått tilslag legges det inn i oversikten nedenfor.',
  nationalDevelopmentBankLoan: 'Dersom dere har fått tilsagn på lån fra Innovasjon Norge, spesifiser under.',
  loans: 'Har dere andre lån eller økonomiske forpliktelser?',
  loanFromShareholders: 'Har dere lån fra aksjonærer? Hvis ja, spesifiser.',
  options:
    'Eksisterer det avtaler om opsjoner, tegningsretter eller lignende som kan påvirke eiersits? Last opp vedtak og dokumentasjon.',
  equity: 'Har selskapet positiv egenkapital?',
  'equity.hasEnoughAssetsToCoverDebt': investmentOfferInformationFieldLabels['equity.hasEnoughAssetsToCoverDebt'],
  paymentRemarks: 'Har selskapet betalingsanmerkninger?',
  liquidity: 'Har dere likviditet til å dekke økonomiske forpliktelser før emisjonen har passert?',
  'liquidity.hasCashToCoverCommitmentsOnDueDate':
    investmentOfferInformationFieldLabels['liquidity.hasCashToCoverCommitmentsOnDueDate'],
  'liquidity.howLong': 'Hvor lenge',
  expectedPositiveOperatingResult: 'Når tilsier prognosene at selskapet vil ha positivt driftsresultat?',
  'expectedPositiveOperatingResult.estimatedTime': 'Estimert tid',
  taxIncentiveScheme:
    'Selskapet tilfredsstiller kravene for personlig skattefradrag ved investering jmf skatteloven §6-53 (tidligere kjent som kapitalfunn). <link>Se informasjon her.</link>',
  shareholderInformationManagement:
    'Beskriv hvordan dere skal informere og oppdatere deres nye aksjonærer i tiden fremover. Dere benytter verktøyet til Caplist til å informere og oppdatere.',
  companyValuation: 'Legg inn antall aksjer per dags dato, dagens aksjekapital og tegningskurs i denne emisjonen.',
  fundraisingTarget: 'Legg inn beløpet dere trenger for å oppnå målene i investeringstilbudet deres.',
  sharesToIssue: 'Fyll inn hvor mange nye aksjer som skal utstedes, øvre og nedre grense.',
  investmentLimits:
    'Sett begrensninger for antall aksjer en investor kan tegne i emisjonen. Nedre grense kan ikke være høyere enn 5000 kr.',
  statutesChanges:
    'Skal det gjøres vedtektsendringer i forbindelse med emisjonen? Folkeinvest krever at medsalgsplikt/rett reguleres enten i vedtekter eller i en aksjonæravtale.',
  runway: 'Hvor mange måneder har selskapet midler til å drifte forutsatt laveste emisjonsbeløp og gjeldende budsjett?',
  furtherFinancing:
    'Planlegger selskapet med flere emisjoner? Hvis ja, beskriv tidspunkt og antatt beløp. Hvis nei, beskriv eventuell annen finansiering eller beskriv at selskapet budsjetterer for positivt resultat. Dette må samsvare med budsjettet.',
}

export const ioInfoEmissionFields = {
  approvalCanceled: investmentOfferInformationFields.approvalCanceled,
  bankingInfo: investmentOfferInformationFields.bankingInfo,
  cancelApproval: investmentOfferInformationFields.cancelApproval,
  cancelApprovalWarning: investmentOfferInformationFields.cancelApprovalWarning,
  companyCertificateDocument: investmentOfferInformationFields.companyCertificateDocument,
  companyValuation: investmentOfferInformationFields.companyValuation,
  coreCompetencyPossession: investmentOfferInformationFields.coreCompetencyPossession,
  customerGroup: investmentOfferInformationFields.customerGroup,
  equity: investmentOfferInformationFields.equity,
  expectedPositiveOperatingResult: investmentOfferInformationFields.expectedPositiveOperatingResult,
  foundingDocument: investmentOfferInformationFields.foundingDocument,
  fundraisingTarget: investmentOfferInformationFields.fundraisingTarget,
  furtherFinancing: investmentOfferInformationFields.furtherFinancing,
  incubatorOrAccelerator: investmentOfferInformationFields.incubatorOrAccelerator,
  investmentLimits: investmentOfferInformationFields.investmentLimits,
  liquidity: investmentOfferInformationFields.liquidity,
  loanFromShareholders: investmentOfferInformationFields.loanFromShareholders,
  loans: investmentOfferInformationFields.loans,
  market: investmentOfferInformationFields.market,
  nationalDevelopmentBankLoan: 'Lån fra Innovasjon Norge',
  numberOfEmployees: investmentOfferInformationFields.numberOfEmployees,
  numberOfManYears: investmentOfferInformationFields.numberOfManYears,
  options: investmentOfferInformationFields.options,
  paidUpCapital: investmentOfferInformationFields.paidUpCapital,
  partOfCorporation: investmentOfferInformationFields.partOfCorporation,
  paymentRemarks: investmentOfferInformationFields.paymentRemarks,
  previousStartupExperience: investmentOfferInformationFields.previousStartupExperience,
  runway: investmentOfferInformationFields.runway,
  sendForApproval: investmentOfferInformationFields.sendForApproval,
  shareholderInformationManagement: investmentOfferInformationFields.shareholderInformationManagement,
  sharesToIssue: investmentOfferInformationFields.sharesToIssue,
  softFunding: 'Offentlig støtte (tilskudd)',
  statutesChanges: investmentOfferInformationFields.statutesChanges,
  statutesDocument: investmentOfferInformationFields.statutesDocument,
  taxIncentiveScheme: investmentOfferInformationFields.taxIncentiveScheme,
}

export const ioInfoEmissionFieldLabels = {
  'market.marketArea.norway': investmentOfferInformationFieldLabels['market.marketArea.norway'],
  'market.marketArea.scandinavia': investmentOfferInformationFieldLabels['market.marketArea.scandinavia'],
  'market.marketArea.europe': investmentOfferInformationFieldLabels['market.marketArea.europe'],
  'market.marketArea.global': investmentOfferInformationFieldLabels['market.marketArea.global'],
  'coreCompetencyPossession.coreCompetencies.founders':
    investmentOfferInformationFieldLabels['coreCompetencyPossession.coreCompetencies.founders'],
  'coreCompetencyPossession.coreCompetencies.board':
    investmentOfferInformationFieldLabels['coreCompetencyPossession.coreCompetencies.board'],
  'coreCompetencyPossession.coreCompetencies.employees':
    investmentOfferInformationFieldLabels['coreCompetencyPossession.coreCompetencies.employees'],
  'coreCompetencyPossession.coreCompetencies.hiredConsultants':
    investmentOfferInformationFieldLabels['coreCompetencyPossession.coreCompetencies.hiredConsultants'],
  'coreCompetencyPossession.coreCompetencies.notCovered':
    investmentOfferInformationFieldLabels['coreCompetencyPossession.coreCompetencies.notCovered'],
  'coreCompetencyPossession.coreCompetencies.hiring':
    investmentOfferInformationFieldLabels['coreCompetencyPossession.coreCompetencies.hiring'],
  'previousStartupExperience.experience.hasEstablished':
    investmentOfferInformationFieldLabels['previousStartupExperience.experience.hasEstablished'],
  'previousStartupExperience.experience.hasSold':
    investmentOfferInformationFieldLabels['previousStartupExperience.experience.hasSold'],
  'previousStartupExperience.experience.wentBankrupt':
    investmentOfferInformationFieldLabels['previousStartupExperience.experience.wentBankrupt'],
  'previousStartupExperience.experience.boardHasSold':
    investmentOfferInformationFieldLabels['previousStartupExperience.experience.boardHasSold'],
  'previousStartupExperience.experience.noExperience':
    investmentOfferInformationFieldLabels['previousStartupExperience.experience.noExperience'],
  'numberOfEmployees.fullTime': investmentOfferInformationFieldLabels['numberOfEmployees.fullTime'],
  'numberOfEmployees.partTime': investmentOfferInformationFieldLabels['numberOfEmployees.partTime'],
  'numberOfManYears.manYears': investmentOfferInformationFieldLabels['numberOfManYears.manYears'],
  incubatorOrAcceleratorHasNotParticipated:
    'Selskapet har ikke deltatt i noen inkubatorprogram, akseleratorprogram, næringshage eller tilsvarende.',
  'incubatorOrAccelerator.name': investmentOfferInformationFieldLabels['incubatorOrAccelerator.name'],
  'incubatorOrAccelerator.startDate': investmentOfferInformationFieldLabels['incubatorOrAccelerator.startDate'],
  'incubatorOrAccelerator.endDate': investmentOfferInformationFieldLabels['incubatorOrAccelerator.endDate'],
  'incubatorOrAccelerator.inProgress': investmentOfferInformationFieldLabels['incubatorOrAccelerator.inProgress'],
  'softFunding.hasNotRecievedFunding': 'Selskapet har ikke søkt/fått tilslag på offentlig støtte/tilskudd.',
  'softFunding.softFundingRounds.amount': investmentOfferInformationFieldLabels['softFunding.softFundingRounds.amount'],
  'softFunding.softFundingRounds.amount.placeholder':
    investmentOfferInformationFieldLabels['softFunding.softFundingRounds.amount.placeholder'],
  'softFunding.softFundingRounds.nameOfInstitution':
    investmentOfferInformationFieldLabels['softFunding.softFundingRounds.nameOfInstitution'],
  'softFunding.softFundingRounds.institutions':
    investmentOfferInformationFieldLabels['softFunding.softFundingRounds.institutions'],
  'softFunding.softFundingRounds.institutions.researchCouncil':
    investmentOfferInformationFieldLabels['softFunding.softFundingRounds.institutions.researchCouncil'],
  'softFunding.softFundingRounds.institutions.innovationNorway':
    investmentOfferInformationFieldLabels['softFunding.softFundingRounds.institutions.innovationNorway'],
  'softFunding.softFundingRounds.institutions.other':
    investmentOfferInformationFieldLabels['softFunding.softFundingRounds.institutions.other'],
  'softFunding.softFundingRounds.documentation':
    investmentOfferInformationFieldLabels['softFunding.softFundingRounds.documentation'],
  'nationalDevelopmentBankLoan.noLoanRecieved':
    'Selskapet har ikke søkt om/ikke fått tilsagn på lån fra Innovasjon Norge.',
  'nationalDevelopmentBankLoan.amount': investmentOfferInformationFieldLabels['nationalDevelopmentBankLoan.amount'],
  'nationalDevelopmentBankLoan.amount.placeholder':
    investmentOfferInformationFieldLabels['nationalDevelopmentBankLoan.amount.placeholder'],
  'nationalDevelopmentBankLoan.documentation':
    investmentOfferInformationFieldLabels['nationalDevelopmentBankLoan.documentation'],
  'expectedPositiveOperatingResult.today':
    investmentOfferInformationFieldLabels['expectedPositiveOperatingResult.today'],
  'expectedPositiveOperatingResult.oneToTwo':
    investmentOfferInformationFieldLabels['expectedPositiveOperatingResult.oneToTwo'],
  'expectedPositiveOperatingResult.threeToFive':
    investmentOfferInformationFieldLabels['expectedPositiveOperatingResult.threeToFive'],
  'expectedPositiveOperatingResult.overFive':
    investmentOfferInformationFieldLabels['expectedPositiveOperatingResult.overFive'],
  options: 'Selskapet har følgende opsjonsavtaler, tegningsretter eller lignende som kan påvirke eierstrukturen:',
  'options.hasNoOptions': investmentOfferInformationFieldLabels['options.hasNoOptions'],
  'options.numberOfShares': investmentOfferInformationFieldLabels['options.numberOfShares'],
  'options.title': investmentOfferInformationFieldLabels['options.title'],
  'options.period': investmentOfferInformationFieldLabels['options.period'],
  'options.pricePerShare': investmentOfferInformationFieldLabels['options.pricePerShare'],
  'options.numberOfShares.placeholder': investmentOfferInformationFieldLabels['options.numberOfShares.placeholder'],
  'options.title.placeholder': investmentOfferInformationFieldLabels['options.title.placeholder'],
  'options.period.placeholder': investmentOfferInformationFieldLabels['options.period.placeholder'],
  'options.pricePerShare.placeholder': investmentOfferInformationFieldLabels['options.pricePerShare.placeholder'],
  'options.documentation': investmentOfferInformationFieldLabels['options.documentation'],
  'paidUpCapital.hasNoPaidUpCapital': 'Selskapet har ikke gjennomført kapitalutvidelser tidligere.',
  'paidUpCapital.paidUpCapitalAmount': investmentOfferInformationFieldLabels['paidUpCapital.paidUpCapitalAmount'],
  'paidUpCapital.dateOfEmission': investmentOfferInformationFieldLabels['paidUpCapital.dateOfEmission'],
  'paidUpCapital.valuation': investmentOfferInformationFieldLabels['paidUpCapital.valuation'],
  'paidUpCapital.valuationPostMoney': investmentOfferInformationFieldLabels['paidUpCapital.valuationPostMoney'],
  'paidUpCapital.paidUpCapitalAmount.placeholder':
    investmentOfferInformationFieldLabels['paidUpCapital.paidUpCapitalAmount.placeholder'],
  'paidUpCapital.valuation.placeholder': investmentOfferInformationFieldLabels['paidUpCapital.valuation.placeholder'],
  'equity.hasEnoughAssetsToCoverDebt': investmentOfferInformationFieldLabels['equity.hasEnoughAssetsToCoverDebt'],
  'liquidity.hasCashToCoverCommitmentsOnDueDate':
    investmentOfferInformationFieldLabels['liquidity.hasCashToCoverCommitmentsOnDueDate'],
  'liquidity.runway': investmentOfferInformationFieldLabels['liquidity.runway'],
  'liquidity.UNDER_THREE': investmentOfferInformationFieldLabels['liquidity.UNDER_THREE'],
  'liquidity.THREE_TO_SIX': investmentOfferInformationFieldLabels['liquidity.THREE_TO_SIX'],
  'liquidity.OVER_SIX': investmentOfferInformationFieldLabels['liquidity.OVER_SIX'],
  'expectedPositiveOperatingResult.TODAY':
    investmentOfferInformationFieldLabels['expectedPositiveOperatingResult.TODAY'],
  'expectedPositiveOperatingResult.ONE_TO_TWO':
    investmentOfferInformationFieldLabels['expectedPositiveOperatingResult.ONE_TO_TWO'],
  'expectedPositiveOperatingResult.THREE_TO_FIVE':
    investmentOfferInformationFieldLabels['expectedPositiveOperatingResult.THREE_TO_FIVE'],
  'expectedPositiveOperatingResult.OVER_FIVE':
    investmentOfferInformationFieldLabels['expectedPositiveOperatingResult.OVER_FIVE'],
  'shareholderInformationManagement.': investmentOfferInformationFieldLabels['shareholderInformationManagement.'],
  'companyValuation.numberOfShares': investmentOfferInformationFieldLabels['companyValuation.numberOfShares'],
  'companyValuation.shareCapital': investmentOfferInformationFieldLabels['companyValuation.shareCapital'],
  'companyValuation.subscriptionPricePerShare':
    investmentOfferInformationFieldLabels['companyValuation.subscriptionPricePerShare'],
  'companyValuation.valuation': investmentOfferInformationFieldLabels['companyValuation.valuation'],
  'companyValuation.nominalValuePerShare':
    investmentOfferInformationFieldLabels['companyValuation.nominalValuePerShare'],
  'companyValuation.valueDifferentialPerShare':
    investmentOfferInformationFieldLabels['companyValuation.valueDifferentialPerShare'],
  'fundraisingTarget.targetAmount': investmentOfferInformationFieldLabels['fundraisingTarget.targetAmount'],
  'fundraisingTarget.minAmount': investmentOfferInformationFieldLabels['fundraisingTarget.minAmount'],
  'fundraisingTarget.minAmount.description':
    investmentOfferInformationFieldLabels['fundraisingTarget.minAmount.description'],
  'sharesToIssue.minSharesToIssue': investmentOfferInformationFieldLabels['sharesToIssue.minSharesToIssue'],
  'sharesToIssue.maxSharesToIssue': investmentOfferInformationFieldLabels['sharesToIssue.maxSharesToIssue'],
  'sharesToIssue.investedEquity': investmentOfferInformationFieldLabels['sharesToIssue.investedEquity'],
  'sharesToIssue.shareEmitted': investmentOfferInformationFieldLabels['sharesToIssue.shareEmitted'],
  'sharesToIssue.equity': investmentOfferInformationFieldLabels['sharesToIssue.equity'],
  'sharesToIssue.valuation': investmentOfferInformationFieldLabels['sharesToIssue.valuation'],
  'investmentLimits.minInvestmentInSharesPerInvestor':
    investmentOfferInformationFieldLabels['investmentLimits.minInvestmentInSharesPerInvestor'],
  'investmentLimits.minInvestmentInSharesPerInvestor.error':
    investmentOfferInformationFieldLabels['investmentLimits.minInvestmentInSharesPerInvestor.error'],
  'investmentLimits.error.belowLowestInvestmentLimit':
    investmentOfferInformationFieldLabels['investmentLimits.error.belowLowestInvestmentLimit'],
  'investmentLimits.error.aboveHighestInvestmentLimit':
    investmentOfferInformationFieldLabels['investmentLimits.error.aboveHighestInvestmentLimit'],
  'investmentLimits.maxInvestmentInSharesPerInvestor':
    investmentOfferInformationFieldLabels['investmentLimits.maxInvestmentInSharesPerInvestor'],
  'investmentLimits.limitForSubscriptions':
    investmentOfferInformationFieldLabels['investmentLimits.limitForSubscriptions'],
  'investmentLimits.shares': investmentOfferInformationFieldLabels['investmentLimits.shares'],
  'investmentLimits.amount': investmentOfferInformationFieldLabels['investmentLimits.amount'],
  'investmentLimits.pricePerShares': investmentOfferInformationFieldLabels['investmentLimits.pricePerShares'],
  'investmentLimits.lowerLimit': investmentOfferInformationFieldLabels['investmentLimits.lowerLimit'],
  'investmentLimits.upperLimit': investmentOfferInformationFieldLabels['investmentLimits.upperLimit'],
  'runway.numberOfMonths': investmentOfferInformationFieldLabels['runway.numberOfMonths'],
  'bankingInfo.documentation': investmentOfferInformationFieldLabels['bankingInfo.documentation'],
  'bankingInfo.bankAccountNumber': investmentOfferInformationFieldLabels['bankingInfo.bankAccountNumber'],
  'bankingInfo.bankSwiftNumber': investmentOfferInformationFieldLabels['bankingInfo.bankSwiftNumber'],
  'bankingInfo.bankIbanNumber': investmentOfferInformationFieldLabels['bankingInfo.bankIbanNumber'],
}

export const ioInfoEmissionIntroTexts = {
  foundingDocument: investmentOfferInformationFieldIntroText.foundingDocument,
  statutesDocument: investmentOfferInformationFieldIntroText.statutesDocument,
  companyCertificateDocument: investmentOfferInformationFieldIntroText.companyCertificateDocument,
  incubatorOrAccelerator:
    'Selskapet har deltatt i følgende inkubatorprogram, akseleratorprogram, næringshage eller tilsvarende:',
  market: 'Produktet/tjenesten skal selges i følgende geografiske markeder:',
  customerGroup: 'Følgende kundegruppe(r) vil primært betale for tjenesten/produktet:',
  coreCompetencyPossession:
    'Følgende i selskapet har kjernekompetanse knyttet til utvikling og leveranse av produktet/tjenesten til selskapet:',
  previousStartupExperience: 'Personer i/tilknyttet selskapet har følgende oppstartserfaring:',
  numberOfEmployees: 'Antall som arbeider fulltid og deltid i selskapet per tidspunkt. Forklar.',
  numberOfManYears: 'Antall akkumulerte årsverk til dags dato. Eksempel: To ansatte i to år tilsvarer fire årsverk.',
  partOfCorporation: 'Er selskapet del av en konsernstruktur?',
  bankingInfo: investmentOfferInformationFieldIntroText.bankingInfo,
  softFunding: investmentOfferInformationFieldIntroText.softFunding,
  nationalDevelopmentBankLoan: investmentOfferInformationFieldIntroText.nationalDevelopmentBankLoan,
  loans: investmentOfferInformationFieldIntroText.loans,
  loanFromShareholders: 'Har selskapet lån fra aksjonærer?',
  options: investmentOfferInformationFieldIntroText.options,
  equity: investmentOfferInformationFieldIntroText.equity,
  'equity.hasEnoughAssetsToCoverDebt': investmentOfferInformationFieldLabels['equity.hasEnoughAssetsToCoverDebt'],
  paymentRemarks: investmentOfferInformationFieldIntroText.paymentRemarks,
  liquidity: 'Har selskapet likviditet til å dekke økonomiske forpliktelser frem til at emisjonen er plassert?',
  'liquidity.hasCashToCoverCommitmentsOnDueDate':
    investmentOfferInformationFieldLabels['liquidity.hasCashToCoverCommitmentsOnDueDate'],
  'liquidity.howLong': investmentOfferInformationFieldIntroText['liquidity.howLong'],
  expectedPositiveOperatingResult: investmentOfferInformationFieldIntroText.expectedPositiveOperatingResult,
  'expectedPositiveOperatingResult.estimatedTime':
    investmentOfferInformationFieldIntroText['expectedPositiveOperatingResult.estimatedTime'],
  taxIncentiveScheme:
    'Tilfredsstiller selskapet kravene for personlig skattefradrag ved investering jmf skatteloven §6-53 (tidligere kjent som kapitalfunn)? <link>Se informasjon her.</link>',
  shareholderInformationManagement: 'Selskapet vil holde sine aksjonærer informert og oppdatert på følgende måte:',
  companyValuation: investmentOfferInformationFieldIntroText.companyValuation,
  fundraisingTarget: investmentOfferInformationFieldIntroText.fundraisingTarget,
  sharesToIssue: investmentOfferInformationFieldIntroText.sharesToIssue,
  investmentLimits: investmentOfferInformationFieldIntroText.investmentLimits,
  statutesChanges: 'Skal selskapet gjøre vedtektsendringer i forbindelse med emisjonen?',
  runway: 'Antall måneder selskapet har midler til å drifte forutsatt laveste emisjonsbeløp og gjeldende budsjett:',
  furtherFinancing: 'Planlegger selskapet med flere emisjoner?',
}

export const ioInfoEmissionClient = {
  heading: 'Grunnleggende informasjon',
  'navigation.overview': 'Oversikt',
  'navigation.companyInfo': 'Selskap',
  'navigation.singlePurposeVehicle': 'SPV',
  'navigation.financialInfo': 'Finansiell informasjon',
  'navigation.emission': 'Kapitalutvidelse',
  textOnlyInfoCardContent: '* Se dokumenter for eventuelle uutløste opsjoner og konvertible lån',
  startingDate: 'Startdato',
  endingDate: 'Sluttdato',
  amountOfShares: 'Antall aksjer',
  loanConversion: 'Gjeldskonvertering',
  minInvestment: 'Minsteinvestering',
  maxInvestment: 'Maksinvestering',
  pricePerShare: 'Pris per aksje',
  valueBeforeEmission: 'Verdi før emisjon *',
  afterEmission: 'Etter emisjon',
  minumumLimit: 'Minumumgrense',
  maximumLimit: 'Maksimumgrense',
  obtainedCapital: 'Innhentet kapital',
  shareOfCompanySold: 'Andel av selskap solgt',
  newShares: 'Nye aksjer',
  newCompanyValue: 'Ny selskapsverdi',
}

export const softFundingEnums = {
  RESEARCH_COUNCIL: investmentOfferInformationFieldLabels['softFunding.softFundingRounds.institutions.researchCouncil'],
  INNOVATION_NORWAY:
    investmentOfferInformationFieldLabels['softFunding.softFundingRounds.institutions.innovationNorway'],
}

export const emissionRunwayEnums = {
  UNDER_THREE: 'Under 3 måneder',
  THREE_TO_SIX: '3-6 måneder',
  OVER_SIX: 'Over 6 måneder',
}

export const emissionExpectedPositiveOperatingResultEnums = {
  TODAY: 'I dag',
  ONE_TO_TWO: '1-2 år',
  THREE_TO_FIVE: '3-5 år',
  OVER_FIVE: 'Over 5 år ',
}

export const emissionInformationGeneralFormFields = {
  explanation: 'Forklaring',
  add: 'Legg til',
  addMore: 'Legg til flere',
  remove: 'Fjern',
  yes: 'Ja',
  no: 'Nei',
  emissionInformationNotFound: 'Fant ikke selskap seksjonen.',
  currency: 'kr',
  shares: 'aksjer',
}

export const emissionNotificationBar = {
  autoSave: 'Endringer blir automatisk lagret',
  lockedSection: 'Seksjonen er låst',
}

export const emissionsOverview = {
  applyForCapital: 'Søk kapital',
  applyForEmission: 'Søk her',
  approved: 'Godkjent',
  subscriptionPeriod: 'Tegningsvindu',
  cancelEmission: 'Vil du avslutte prosessen, {link}',
  contact: 'ta kontakt',
  NOT_STARTED: 'Ikke påbegynt',
  IN_PROGRESS: 'Påbegynt',
  REVIEW: 'Til behandling',
  COMPLETED: 'Fullført',
  UNAVAILABLE: 'Ikke utført',
  emissionsAccess: 'Emisjoner du har tilgang til',
  emissionCancelled: 'Prosessen ble avsluttet. Ny emisjon? {link}',
  emissionCompleted: 'Emisjonen er ferdig. Ny emisjon? {link}',
  newEmission: 'Ny emisjon?',
  info: 'Her kan du se, endre og følge med på dine søknader',
  agreementConcludedAt: 'Avtale inngått: {date}',
  navigateToContractApplication: 'Gå til søknadsskjema',
  navigateToDashBoard: 'Gå til dashbord',
  navigateToOverview: 'Gå til oversikt',
  navigateToPreMeetingForm: 'Gå til registreringsskjema',
  noEmissions: 'Vi fant ingen emisjoner',
  INFO_MEETING: 'Infomøte',
  CONTRACT_APPLICATION: 'Søknad',
  INVESTMENT_OFFER: 'Utforming av investeringstilbud',
  PUBLISHED_OFFER: 'Investeringstilbud',
  POST_CAMPAIGN: 'Sluttfase',
  SUBSCRIPTIONS_OPEN: 'Åpent tegningsvindu',
}

export const emissionInformationFields = {
  existingShareholders: 'Eksisterende aksjonærer',
}

export const facilitatorDashboard = {
  heading: 'Investeringstilbud-godkjenning',
  updateLog:
    'Siste oppdatering fra {committer, select, ISSUER {utsteder} other {tilrettelegger}} kl. {dateTime, time, short} den {dateTime, date, DD.MM.YYYY}',
  conclusionLog:
    '{section, select, CAMPAIGN_CARD {Emisjonskort} ACCOUNTING {Underlag} other {Investeringstilbud}} {status, select, APPROVED {godkjent} DECLINED {avvist} other {levert}} av {committer, select, ISSUER {utsteder} other {tilrettelegger}} kl. {dateTime, time, short} den {dateTime, date, DD.MM.YYYY}',
  cancelCompletion: 'Kanseller ferdigstilling',
  complete: 'Ferdigstill godkjenning for {field, select, ACCOUNTING {underlag} other {investeringstilbud}}',
  showOverview: 'Vis oversikt',
  'dialog.heading': 'Godkjenn ferdigstilling',
  'dialog.subHeading': 'Kommentarene vil være synlig i veilederen, slik at utsteder kan foreta ønsket endringer',
  'dialog.changesToBeShown': 'Endringer som skal vises:',
  'dialog.column.section': 'SEKSJON',
  'dialog.column.status': 'STATUS',
  'dialog.column.dateTime': 'TID OG DATO',
  'dialog.column.unlock': 'LÅS OPP',
  'dialog.status':
    '{status, select, APPROVED {Godkjent} APPROVING {Godkjennes} REVIEW {Kontroll} CHANGES_REQUESTED {{comments} kommentarer} other {Avslått}}',
  'dialog.unlock': '{unlock, select, true {Ja} other {Nei}}',
  'dialog.uploadDocument': 'Last opp dokument fra godkjenningskommiteen',
  'dialog.approvalDocument': 'Godkjenningsdokument',
  'dialog.upload': 'Dra hit eller <link>velg fil</link>',
  'dialog.cancelEmission.header': 'Kanseller ferdigstilling',
  'dialog.cancelEmission.info':
    'Når du kansellerer ferdigstillingen, mister du alle nye kommentarer og godkjent/avslått statuser',
  cancelEmission: 'Kanseller',
  noInvestmentOfferFound: 'Fant ikke noe investeringstilbud',
  updateApprovalDocumentFailed: 'Kunne ikke oppdatere godkjenningsdokumentet',
  deleteApprovalDocumentFailed: 'Kunne ikke slette godkjenningsdokumentet',
}

export const authError = {
  header: 'En feil oppsto',
  hideError: 'Skjul feil',
  showError: 'Se feil',
  tryAgainOrContactSupport: 'Prøv igjen eller kontakt teknisk avdeling med detaljene under.',
  loginError: 'Kunne ikke logge inn, prøv igjen eller kontakt support.',
  registerError: 'Feil under registrering av bruker, prøv igjen eller kontakt support.',
}

export const declarationOfCompleteness = {
  title: 'Fullstendighetserklæring',
  description: `Signering for fullstendighet kreves før start av valideringsfase, samt ved oppdatering av innhold i veileder.
    {br} 
    Endringer som utføres etter valideringsfasen har startet vil ikke ta effekt før ny signering er gjennomført.
    {br}
    Alternativt kan tilbudet oppdateres manuelt av tilrettelegger med begrunnelse. Minst en reell signering av fullstendighet må være utført før denne funksjonaliteten kan benyttes.`,
  header: 'Signering for fullstendighet / Oppdatering av publisert investeringstilbud',
  wasSignedBy: 'Fullstendighetserklæring ble signert av',
  'manualUpdate.manuallyUpdatedByFacilitator': 'Oppdatert manuelt',
  'manualUpdate.startProcessButton': 'Oppdater manuelt',
  'manualUpdate.title': 'Manuell oppdatering av investeringstilbud',
  'manualUpdate.continue': 'Gå videre med oppdatering',
  'manualUpdate.saved': 'Manuell oppdatering av investeringstilbud gjennomført',
  'manualUpdate.disclaimerDescription': `
  Du er i ferd med å oppdatere et publisert investeringstilbud.
  {br}
  Denne funksjonaliteten er ment for små justeringer, skrivefeil, endring av bilder og andre småting.
  {br}
  Ved å gjennomføre denne prosessen vil investeringstilbudet bli oppdatert med data fra veilederen.
  {br}
  Vær oppmerksom på at endring av investeringstilbud under tegningsfasen må være begrunnet. 
  {br}
  {br}
  Vær ekstremt varsom med oppdateringer som medfører endring av selve tilbudet mot investor, nøkkeltall som f.eks pris per aksje, antall aksjer utstedt, verdivurdering etc.
  {br}
  I slike tilfeller anbefales det ikke å benytte manuell oppdatering, men heller kreve ny signering av fullstendighet, samt informasjonshåndtering mot investor.
  `,
  showSignedDocument: 'Vis signert fullstendighetserklæring',
}

export const userSearchDialog = {
  findUser: 'Finn brukerprofil',
  hits: '{number} treff',
  noUsersFound: 'Ingen brukerprofiler funnet',
}

export const subscriptionPeriod = {
  notAvailableYet: 'Investeringstilbudet er ikke klargjort for tegninger',
  missingCompletenessSignature: 'Mangler fullstendighetserklæring',
  completenessActive: 'Venter på signatur av fullstendighetserklæring',
  completenessSignedBy: 'Fullstendighetserklæring signert {date} av {name}',
  copyPreorderUrlTooltip: 'Kopier lenke for forhåndstegning',
  header: 'Tegningsvindu',
  targetAmount: 'Målbeløp',
  maxAmount: 'Maksimumbeløp',
  minAmount: 'Minimumbeløp',
  progress: 'Fremgang',
  currentAmount: 'Nåværende beløp',
  validationPercentage: 'VALIDERINGSPROSENT',
  validationAmount: 'Valideringsbeløp',
  publishCampaign: 'Publiser Investeringstilbud',
  targetNotReached: 'Valideringsbeløp er ikke nådd',
  publishes: 'Publiseres {date}',
  published: 'Publisert {date}',
  opens: 'Åpnes {date}',
  opened: 'Åpnet {date}',
  closes: 'Lukkes {date}',
  closed: 'Lukket {date}',
  choosePublishingDate: 'Velg publiseringsdato',
  chooseOpeningDate: 'Velg startdato',
  chooseClosingDate: 'Velg sluttdato',
  filterInvestor: 'Filtrer investor',
  filterColumns: 'Filtrer kolonner',
  clearFilters: 'Nullstill filter',
  export: 'Eksporter',
  presets: 'Presets',
  activeFilter: 'Aktivt filter',
  GAProtocolAttachement: 'Vedlegg til GF-protokoll',
  subscriberInformation: 'Informasjon om tegnere',
  paymentReminder: 'Purring',
  startValidationPhase: 'Start valideringsfase',

  'table.nameAndEmail': 'Brukernavn & E-post',
  'table.paymentTarget': 'Tilfaller',
  'table.tags': 'Markør',
  'table.numberOfShares': 'Aksjer tegnet',
  'table.amount': 'Beløp i KR',
  'table.signee': 'Tegnet av',
  'table.digitallySignedAt': 'Digitalt signert',
  'table.phone': 'Telefon',
  'table.birthdate': 'Fødselsdato',
  'table.identifier': 'Fnr/OrgNr',
  'table.publicIdentifier': 'F.dato/OrgNr',
  'table.postalCode': 'Postnummer',
  'table.postalTown': 'By',
  'table.address': 'Adresse',
  'table.nationality': 'Nasjonalitet',
  'table.userName': 'Brukernavn',
  'table.email': 'Epost',
  'table.vpsAccount': 'VPS-konto',
  'table.signeeType': 'Investortype',
  'table.paymentRef': 'Referanse',
  'table.paymentRegistered': 'Betalingsregistrering',

  'subscriptionsInfo.header': 'Tegningsinformasjon',
  'subscriptionsInfo.investors': 'Investorer',
  'subscriptionsInfo.subscriptions': 'Tegninger',

  'modal.header': 'Tegningsinformasjon',
  'modal.userInfo': 'Brukerinfo',
  'modal.companyInfo': 'Tegnet på',
  'modal.paymentInfo': 'Innbetalingsinfo',
  'modal.vpsInfo': 'VPS-konto',
  'modal.tags': 'Markører',
  'modal.activeTags': 'Aktive Markører',
  'modal.inactiveTags': 'Inaktive markører',
  'modal.subsriptionIsActive': 'Tegningen er aktiv',
  'modal.deletingRequested': 'Utsteder har etterspørt sletting',
  'modal.currentPaymentStatus': '{isPaid, select, true {Betalt} other {Ikke betalt}}',
  'modal.paymentInfoNotSent': 'Innbetalingsinfo ikke sendt',
  'modal.paymentDueDateNotSet': 'Betalingsfrist ikke satt',
  'modal.status': 'Status',
  'modal.subscriptionIsActive': 'Tegningen er aktiv',
  'modal.subscriptionIsNotActive': 'Tegningen er ikke aktiv',
  'modal.sendNewPaymentReminder': 'Send ny påminnelse',
  'modal.paymentReceivedAt': 'Betaling registrert {date}',
  'modal.newPaymentReminderSent': 'Betalingspåminnelse sendt',
  'modal.paymentInfoSentAt': 'Innbetalingsinfo sendt {date}',
  'modal.paymentReminderSentAt': 'Betalingspåminnelse sendt {date}',
  'modal.cancelRejectionRequest': 'Avslå forespørsel',
  'modal.cancelReservationInformation': `<heading>Kansellering av tegning</heading>
    {br}
    Tegningen kan kanselleres av administrator så lenge den ikke er markert som betalt.
    {br}
    Ved kansellering må det skrives inn en begrunnelse som vil vises på profilsiden til investor.
    {br}
    Det vil ikke sendes epost fra systemet. Eventuell informasjon om kansellering må videreformidles manuelt.
    {br}
    <warning>Merk at denne handlingen <b>ikke</b> kan reverseres.</warning>
    `,
  'modal.confirmCancelReservationButton': 'Kanseller med begrunnelse',
  'modal.reservationWasCancelled': 'Tegning kansellert',
  'modal.approveRejectionRequest': 'Godta forespørsel',
  'modal.cancelReservedSubscription': 'Start kansellering',
  'modal.expediencyPassed': 'Bestått hensiktmessighetstest',
  'modal.expediencyFailed': 'Ikke bestått hensiktmessighetstest',
  'modal.rejectionNoReason': 'Ingen begrunnelse',
  'modal.rejectionRequested': 'Utsteder har forespurt avvisning',
  'modal.rejectionDenied': 'Folkeinvest har avslått forespørsel',
}

export const postCampaign = {
  header: 'Sluttfase',
  'failed.header': 'Emisjon feilet',
  'failed.description': 'Emisjon nådde ikke minstemålet og er dermed avsluttet.',
  'paymentCalculator.header': 'Honorar-kalkulator',
  'checklist.header': 'Sjekkliste',
  'status.header': 'Status',
  'status.subscriptionWindowOpen': 'Tegningsvinduet er fremdeles åpent.',
  'status.rejectedSubscriptions': 'Utsteder har etterspurt avvisning av {count} tegninger.',
  'status.shareholderApproval': 'Venter på utsteders godkjenning av tegningsliste.',
  'status.noProtocol': 'GF-protokoll ikke mottatt.',
  'status.protocolApprovalPending': 'GF-protokoll avventer godkjenning.',
  'status.protocolApproved': 'GF-protokoll godkjent.',
  'status.noUpdatedCertificate': 'Oppdatert firmaattest ikke mottatt.',
  'status.updatedCertificateApprovalPending': 'Oppdatert firmaattest avventer godkjenning.',
  'status.updatedCertificateApproved': 'Oppdatert firmaattest godkjent.',
  'status.paymentInfoNotSent': 'Betalingsinfo ikke sendt.',
  'status.paymentInfoSent': 'Betalingsinfo sendt {date}.',
  'status.paymentReminderPending': 'Betalingspåminnelse vil sendes {date}.',
  'status.paymentReminderSentAt': 'Betalingspåminnelse sendt {date}.',
  'status.button.documents': 'Gå til dokumenter',
  'status.button.subscriptions': 'Gå til tegningsliste',
  'status.button.paymentReminder': 'Send ny betalingspåminnelse',
  'status.dialog.paymentReminderSuccess': 'Betalingspåminnelse sendt.',
  'status.dialog.header': 'Bekreft sending av betalingsinfo',
  'status.dialog.description': 'Dette vil sende betalingsinformasjon til {investors} investorer. Er du sikker?',
  'status.dialog.cancel': 'Nei, avbryt',
  'status.dialog.confirm': 'Ja, send betalingsinfo',
  'status.reminder.dialog.header': 'Bekreft sending av betalingspåminnelse',
  'status.reminder.dialog.description':
    'Dette vil sende en betalingspåminnelse til alle tegnere som ikke er markert som betalt. Er du sikker?',
  'status.reminder.dialog.cancel': 'Nei, avbryt',
  'status.reminder.dialog.confirm': 'Ja, send påminnelse',
}

export const subscriptionsDashboard = {
  header: 'Tegninger',
  'section.title.paymentInfo': 'Betalingsinfo',
  'section.title.availablePaymentTargets': 'Kontoopplysninger',
  gotoDashboard: 'Gå til dashboard',
  gotoDocuments: 'Gå til dokumenter',
  processSubscriptions: 'Behandle tegninger',
  'paymentStatus.rejectedSubscriptions': 'Utsteder har etterspurt avvisning av {count} tegninger.',
  'paymentStatus.shareholderApproval': 'Venter på utsteders godkjenning av tegningsliste.',
  'paymentStatus.noGAProtocol': 'GF-protokoll ikke mottatt.',
  'paymentStatus.protocolNotApproved': 'GF-protokoll ikke godkjent.',
  'paymentStatus.spvAndPaymentTargetsNotAssigned': 'Tegningsliste klar for sortering mellom målselskap og SPV.',
  'paymentStatus.paymentInfoReady':
    'Betalingsinfo for {subscriptions} tegninger er klar til å sendes til {investors} investorkunder.',
  'paymentStatus.spvPaymentInfoReady':
    '<b>{spvSubscriptionsCount} tegninger</b> ({spvInvestorsCount} investorkunder) som totalt har tegnet under <b>{sharesLimit} aksjer</b> blir tilknyttet SPV.',
  'paymentStatus.assignPaymentTargets': 'Marker tegninger for SPV',
  'paymentStatus.assignPaymentTargetsAgain': 'Marker tegninger for SPV på nytt',
  'paymentStatus.assignPaymentTargets.description': `
    Ved å benytte automatisk markering vil investorer med totalt antall aksjer under satt grenseverdi
    automatisk bli delegert aksjer i SPV. Øvrige vil bli delegert til målselskap.
    {br}
    Merk at dette skjer per "Tegnet på" og ikke investorprofiler.
    {br}
    Hvis det har blitt utført manuelle delegerings-justeringer per tegning/investor, vil disse overskrives.
  `,
  'paymentStatus.sendPaymentInfo': 'Send betalingsinfo',
  'paymentStatus.paymentInfoSent': 'Betalingsinfo sendt',
  'paymentStatus.assignedPaymentTargetsSpv': 'Tegningsliste sortert for SPV',
  'paymentStatus.paymentInfoSentAt': 'Betalingsinfo sendt {date}',
  'paymentStatus.targetAccount': 'Målselskap',
  'paymentStatus.spvAccount': 'SPV',
  'paymentTarget.orgnrShort': 'Orgnr.',
  'paymentTarget.accountNumber': 'Kontonummer',
  'paymentTarget.ibanNumber': 'IBAN',
  'paymentTarget.swiftNumber': 'Swift (BIC)',
}

export const tags = {
  OWNER: 'EIER',
  BOARD: 'STYRE',
  TOP_TEN: 'TOP 10',
  MANAGEMENT: 'STYRE',
  TEAM: 'TEAM',
  VALIDATION: 'VALIDERING',
  FAMILY: 'FAMILIE',
  NEW: 'NY',
  PROSPECT: 'PROSPEKT',
  WARM: 'VARM',
  COLD: 'KALD',
  CANCELLED: 'AVBRUTT',
  COMPLETED: 'ARKIVERT',
}

export const richTextEditor = {
  text: 'Tekst',
  url: 'URL',
  addUrl: 'Legg til lenke',
  removeUrl: 'Fjern lenke',
  updateUrl: 'Oppdater lenke',
  removeColor: 'Fjern farge',
  'error.unsupportedMimeType': 'Kun bilde eller video kan legges til i beskrivelsen',
  'error.upload': 'Noe gikk galt under opplasting, prøv igjen eller kontakt support',
}

export const footer = {
  description: 'Folkeinvest er et uavhengig verdipapirforetak under tilsyn av Finanstilsynet.',
  allRightsReserved: 'Alle rettigheter reservert.',
  organizationNumber: 'Organisasjonsnummer 916 545 061',
}

export const investmentOfferPage = {
  reserveShares: 'Kjøp aksjer',
  follow: 'Følg emisjon',
  isFollowing: 'Emisjon fulgt',
  showKeyInformation: 'Vis nøkkelinfo',
  hideKeyInformation: 'Skjul nøkkelinfo',
  coverImageAlt: 'Coverbilde for investeringstilbud: {fileName}',
  followCaption: 'Følg emisjonen for å få varsel ved åpning',
  readonly:
    'Dette er en "kun lesbar" versjon av investeringstilbudet. For deltagelse, sørg for at du benytter korrekt URL',
  isClosed: 'Emisjonen er avsluttet og det er derfor ikke mulig å tegne seg for aksjer',
  riskWarning: 'Før du kjøper aksjer, bør du forstå risikoen dette innebærer. Ikke invester mer enn det du kan tape.',
  'stats.currentProgressLabel': '{status, select, max {Fulltegnet} other {Minstemål}}',
  'stats.currentNumberOfSharesLabel': '{size, select, full {Antall} other {Ant.}} tegninger',
  'stats.pricePerShareLabel': 'Pris {size, select, full {per} other {pr.}} aksje',
  'stats.timeToLabel': '{time, select, notopen {Åpner om} open {Lukkes om} other {Avsluttet}}',

  'keyInfo.companyValuationLabel': 'Verdsettelsen',
  'keyInfo.sharesToIssueLabel': 'Aksjer for salg',
  'keyInfo.remainingSharesLabel': 'Gjenstående aksjer',
  'keyInfo.minSubscriptionLabel': 'Minste tegning',
  'keyInfo.maxSubscriptionLabel': 'Maks tegning',
  'keyInfo.subscriptionNumberWithPrice': '{shares} aksjer ({value} kr)',

  'nav.description': 'Beskrivelse',
  'nav.emission': 'Grunnleggende Info',
  'nav.discussion': 'Diskusjon',
  'nav.teamAndOwners': 'Team og eiere',
  'nav.documents': 'Dokumenter',
  'nav.subscriptionList': 'Tegningsliste',
  subscriptionList: 'Tegningsliste',
  'subscriptionList.description': 'En oversikt over tegninger som er foretatt i denne emisjonen.',
  'subscriptionList.description2': 'Aksjonærlister er offentlig informasjon.',
  'subscriptionList.inaccessible': 'Tegningslisten blir tilgjengelig etter åpning av tegningsvindu.',
  'subscriptionList.table.name': 'Navn',
  'subscriptionList.table.createdAt': 'Når',
  'subscriptionList.table.shares': 'Tegnet aksjer',
  'subscriptionList.noName': 'Investor',
  'subscriptionList.notLoggedIn': 'Visning av tegningsliste krever innlogging.',
  'subscriptionList.noBankId': 'For å kunne se tegningslisten, må profilen din være verifisert med BankID.',
  'subscriptionList.goToProfile': '<link>Gå til Min Profil</link>',
  documents: 'Dokumenter',
  additionalDocuments: 'Tilleggsdokumenter',
  loginRequired: 'For å se innhold i denne seksjonen må du være logget inn',
}

export const publishedDocuments = {
  header: 'Dokumentpublisering',
  title: 'Tittel',
  fileName: 'Filnavn',
  date: 'Dato',
  isPublished: 'Publiseres',
}

export const documentNames = {
  annualAccounts: 'Signert årsregnskap',
  annualAccountsPreviousYears: 'Kontospesifisert årsregnskap',
  auditReports: 'Revisorbekreftelser, attestasjoner og nummererte brev',
  budgetPrognosis: 'Budsjett/prognose',
  captable: 'Cap table',
  companyCertificateDocument: 'Firmaattest',
  missionCriticalAgreements: 'Virksomhetskritiske avtaler',
  foundingDocument: 'Stiftelsesdokument',
  legalMatters: 'Juridiske forhold - Nøkkelpersoner',
  loans: 'Låneavtaler',
  nationalDevelopmentBankLoan: 'Innovasjon Norge',
  nationalProspectus: 'Nasjonalt prospekt',
  options: 'Opsjonsavtaler/tegningsretter',
  otherObligations: 'Juridiske forhold',
  previousSalesProcesses: 'Tidligere salgsprosesser',
  previousTransactionProcesses: 'Tidligere kjøpsprosesser',
  previousValuations: 'Tidligere verdivurderinger',
  reconciledAccountBalanceReport: 'Kontospesifisert balanserapport',
  reconciledAccountProfitReport: 'Kontospesifisert resultatrapport',
  shareholderAgreement: 'Aksjonæravtale',
  softFunding: 'Offentlig støtte',
  statutesChanges: 'Vedtektsendringer',
  statutesDocument: 'Vedtekter',
  valuationDocument: 'Verdivurdering',
  spvOperatingAgreement: 'Driftsavtale (SPV)',
  spvShareholderAgreement: 'Aksjonæravtale (SPV)',
  spvStatutes: 'Vedtekter (SPV)',
}

export const investmentOfferTeamAndOwners = {
  header: 'Team og eiere',
  'companyInfo.companyName': 'Firmanavn',
  'companyInfo.website': 'Hjemmeside',
  'companyInfo.organizationNumber': 'Org. nr.',
  'companyInfo.companyAddress': 'Adresse',
  'shareholders.header': 'Eiere',
  'shareholders.name': 'Navn',
  'shareholders.shares': 'Aksjer',
  'shareholders.share': 'Andel (%)',
  team: 'Teamet',
  board: 'Styret',
  partners: 'Partnere',
  readMoreButton: 'Les mer',
}

export const publicInvestmentOfferEmissionListFields = {
  norway: investmentOfferInformationFieldLabels['market.marketArea.norway'],
  scandinavia: investmentOfferInformationFieldLabels['market.marketArea.scandinavia'],
  europe: investmentOfferInformationFieldLabels['market.marketArea.europe'],
  global: investmentOfferInformationFieldLabels['market.marketArea.global'],
  founders: investmentOfferInformationFieldLabels['coreCompetencyPossession.coreCompetencies.founders'],
  board: investmentOfferInformationFieldLabels['coreCompetencyPossession.coreCompetencies.board'],
  employees: investmentOfferInformationFieldLabels['coreCompetencyPossession.coreCompetencies.employees'],
  hiredConsultants: investmentOfferInformationFieldLabels['coreCompetencyPossession.coreCompetencies.hiredConsultants'],
  notCovered: investmentOfferInformationFieldLabels['coreCompetencyPossession.coreCompetencies.notCovered'],
  hiring: investmentOfferInformationFieldLabels['coreCompetencyPossession.coreCompetencies.hiring'],
  hasEstablished: investmentOfferInformationFieldLabels['previousStartupExperience.experience.hasEstablished'],
  hasSold: investmentOfferInformationFieldLabels['previousStartupExperience.experience.hasSold'],
  wentBankrupt: investmentOfferInformationFieldLabels['previousStartupExperience.experience.wentBankrupt'],
  boardHasSold: investmentOfferInformationFieldLabels['previousStartupExperience.experience.boardHasSold'],
  noExperience: investmentOfferInformationFieldLabels['previousStartupExperience.experience.noExperience'],
}

export const issuerPublicInvestmentOfferDashboard = {
  header: 'Investeringstilbud-dashboard',
  progress: 'Fremgang',
  'progress.targetSum': 'Målbeløp ({amount})',
  'progress.maxSum': 'Maksimumbeløp ({amount})',
  'progress.minSum': 'Minimumbeløp ({amount})',
  'dates.PUBLISH': 'Publiseres om',
  'dates.OPEN': 'Åpner om',
  'dates.CLOSE': 'Lukkes om',
  campaign: 'INVESTERINGSTILBUD',
  stats: 'Innsikt',
  'stats.followerCount': 'Følgere',
  'stats.recentFollowerCount': 'Følgere 24h',
  'stats.subscribersCount': 'Investorer',
  'stats.recentSubscribersCount': 'Investorer 24h',
  'stats.totalAmountReserved': 'Sum',
  'stats.recentTotalReservedAmount': 'Sum 24h',
  documents: 'Last opp sluttfasedokumenter',
  'documents.GFprotocol': 'GF-protokoll',
  'documents.companyCertificate': 'Oppdatert firmaattest',

  'steps.step': '{step} av {maxSteps}',
  'steps.VALIDATION.header': 'Validering',
  'steps.VALIDATION.text': `
    <bold>Velkommen!</bold>
    <para>Dette er deres nye dashboard. Her finner dere all funksjonalitet som trengs for å følge med gjennom investeringstilbud-løpet.</para>
    <bold>Validering</bold>
    <para>Vi venter på at det validerende kapitalet oppnår 30% av målsummen. Akkurat nå er det bare investorer med privat lenke (se ned til venstre) som kan se deres investeringstilbud.</para>
    <bold>Diskusjon</bold>
    <para>Bli kjent med deres tidlige investorer i diskusjonsforumet.</para>
  `,

  'steps.PREORDER.header': 'Spredning & forhåndstegning',
  'steps.PREORDER.text': `
    <bold>Dere er igang!</bold>
    <para>Deres investeringstilbud har har blitt validert og fått en publiseringsdato, og snart kan hver Folkeinvestor ta del av hele deres investeringstilbud. Investorer som etterspør mulighet til forhåndstegning har fått sendt lenke via e-post. Spre emisjonskortet deres og samle følgere før investeringstilbudet åpner!</para>
    <bold>Interesserte</bold>
    <para>Dere har fått tilgang til en liste med investorer som har meldt interesse i deres investeringstilbud. Vi anbefaler sterkt at dere kontakter disse investorene individuelt for å invitere dem til å investere. Klikk på «Interesserte» i menyen til venstre for å begynne.</para>
  `,

  'steps.OPEN.header': 'Åpent tegningsvindu',
  'steps.OPEN.text': `
    <bold>Investeringstilbudet er live!</bold>
    <para>Nå er det mulig for hver eneste Folkeinvestor å investere i deres emisjon. Vær fortsatt aktiv på deres forskjellige plattformer og i diskusjonsforumet, og lykke til!</para>
    <bold>Sanntids-visning</bold>
    <para>Finn lenken Sanntids-visning” i headern og sett opp en skjerm på kontoret for å følge med investeringstilbud-løpet live.</para>
  `,

  'steps.CLOSED.header': 'Sluttfase',
  'steps.CLOSED.text': `
    <bold>Gratulerer!</bold>
    <para>Nå når investeringstilbudet deres er vellykket er det dags for det formelle. Ved å holde en ekstraordinær generalforsamling å melde det inn til Brønnøysundregistrene blir kapitalutvidelsen offisiell.</para>
    <bold>Godkjenn investorliste</bold>
    <para>I følge investeringstilbudet må dere nå godkjenne tegningslisten. Det finnes nå ny funksjonalitet under «Tegninger».</para>
    <bold>Dokumenter</bold>
    <para>I følge av generalforsamling og registrering i Brønnøysundregistrene må dere laste opp to dokumenter. Se seksjonen “Dokumenter” til venstre i menyen.</para>
  `,
}

export const investmentOfferReserveSubscription = {
  'reservationBox.addInvestmentCompany': 'Ønsker du å investere gjennom et investeringsselskap?',
  'reservationBox.headline': 'Kjøp Aksjer',
  'reservationBox.disclamerText':
    'Før du bekrefter din tegning bør du forsikre deg om at du forstår risikoen dette innebærer. Ikke invester mer enn det du har råd til å tape.',
  'reservationBox.signeeLabel': 'Tegn på vegne av (påkrevd)',
  'reservationBox.signeePlaceholder': 'Velg person eller firma',
  'reservationBox.minimum': 'Minimum',
  'reservationBox.plusTenThousand': '+ 10 000 kr',
  'reservationBox.amountLabel': 'Beløp',
  'reservationBox.pricePerShare': 'Pris per aksje',
  'reservationBox.selectedSharesAtMaxLimit': 'Antall aksjer valgt er maks tillatt.',
  'reservationBox.currentSigneeIsAtMaximumAllowedShares':
    'Maks antall aksjer tillatt per investor for denne emisjonen er allerede nådd for <bold>{signeeName}</bold>',
  'reservationBox.selectedSharesUnderLimit': 'Antall aksjer valgt er under minste antall tillatt ({shares}).',
  'reservationBox.mobileRiskInfoButton': 'Les mer om risiko',
  'reservationBox.missingCompanyCertificate': '(Mangler firmaattest)',
  'faq.readMore': 'Les mer',
  'faq.close': 'Lukk',
  'faq.readMoreAboutRisk': 'Du kan lese mer om risiko her ',
  'faq.riskHeader': 'Risiko',
  'faq.riskParagraph':
    'Investering i unoterte aksjer innebærer høy risiko. Det er viktig at du som investor leser investeringstilbudet nøye og gjør deg din egen formening om hvilken risiko den eventuelle investeringen innebærer for deg. Ikke invester mer enn det du har råd til å tape.',
  'faq.faqHeader': ' Spørsmål og svar',
  'faq.economicRiskHeader': ' Tegningen forplikter økonomisk, kan jeg angre meg?',
  'faq.economicRiskParagraph.cancellation':
    'Du kan kansellere tegningen inntil 48 timer før investeringstilbudet avsluttes, etter dette er tegningen bindende.',
  'faq.economicRiskParagraph.subscriptionWindowCloses': 'Tegningsvindu avsluttes',
  'faq.economicRiskParagraph.lastCall': 'Siste frist',
  'faq.economicRiskParagraph.writtenReasoning': 'Ved kansellering krever vi en skriftlig begrunnelse på årsaken.',
  'faq.economicRiskParagraph.possibleExtention':
    'NB: Utstederen kan velge å forlenge tegningsvinduet, noe som medfører at fristen for kansellering vil utsettes.',
  'faq.economicRiskParagraph.stakeholdersNotAbleToWithdraw':
    'NBB: Styre, ansatte og tidligere aksjonærer i utstederselskapet kan ikke trekke tegningen sin.',
  'faq.whatHappendsNextHeader': 'Hva skjer videre?',
  'faq.whatHappendsNextSubheader': 'Emisjonen kan avsluttes med to utfall: ',
  'faq.whatHappendsNextSectionHeaderA': 'a. Minimumsbeløp oppnådd: ',
  'faq.whatHappendsNextParagraphA': `
  <li>Du vil motta e-post med sluttresultatet av aksjetegning i emisjonen.</li>
  <li>Kapitalutvidelsen vedtas i selskapets generalforsamling og tildelte aksjer tegnes i generalforsamlingsprotokollen.</li>
  <li>Innbetalingsinformasjon til din registrerte e-post og blir synlig på din profilside på folkeinvest.no.</li>
  <li>Du betaler inn korrekt beløp ved ordinær bankoverføring.</li>
  `,
  'faq.whatHappendsNextSectionHeaderB': 'b. Minimumsbeløp IKKE oppnådd: ',
  'faq.whatHappendsNextParagraphB': `
  <li>Du vil motta epost om emisjonens status.</li>
  <li>Emisjonen blir avbrutt og du skal ikke betale.</li>
  `,
  'footer.cancel': 'Avbryt',
  'footer.back': 'Tilbake',
  'footer.confirmReservation': 'Bekreft tegning',
  'footer.continue': 'Gå videre',
  'step.selectShares': 'Kjøp aksjer',
  'step.expediencyTest': 'Hensiktsmessighet',
  'step.vpsInput': 'VPS-konto',
  'step.confirmation': 'Bekreftelse',
  'step.receipt': 'Kvittering',
  'cart.headline': 'Din tegning',
  'cart.changeAmount': 'Endre beløp',
  'cart.campaign': 'Emisjon:',
  'cart.numberOfShares': 'Antall aksjer:',
  'cart.numberOfSharesSuffix': 'stk',
  'cart.pricePerShare': 'Pris per aksje:',
  'cart.signedBy': 'Tegnet av:',
  'cart.signedType': 'Tegners type:',
  'cart.totalAmount': 'Totalbeløp',
  'cart.signeeType.PERSON': 'Privatperson',
  'cart.signeeType.BUSINESS': 'Inversteringsselskap',
  'expediency.heading': 'Hensiktsmessighet',
  'expediency.isNotCompletedHeader': 'Test er ikke gjennomført',
  'expediency.isNotCompletedIntroText':
    'Du har ikke gjennomført hensiktsmessighetstest. Uten denne kan du ikke tegne unoterte aksjer på folkeinvest.no. ',
  'expediency.isOutdatedIntroText':
    'Du har gjennomført hensiktsmessighetstest tidligere, men gyldigheten er nå utgått. Vi ber deg ta testen på nytt, og hvis temaet trenger en oppfriskning søk ytterligere kompetanse ved å klikke på lenken. ',
  'expediency.isPassed': 'Testen er gjennomført',
  'expediency.isOutdated': 'Testen er gjennomført, men den er utgått og må gjøres på nytt.',
  'expediency.isFailed': 'Testen er ikke bestått',
  'expediency.isFailedParagraph':
    'Dine besvarelser på hensiktsmessighetstesten fører til at vi fortsatt fraråder deg å gjennomføre handelen. Søk ytterligere kompetanse ved å klikke på lenken.',
  'expediency.isFailedCheckbox': 'Jeg velger å ignorere frarådingen basert på hensiktsmessighetstesten',
  'expediency.link': 'Les mer om hensiktsmessighet',
  'expediency.goToTest': 'Gå til test (2 minutter)',
  'expediency.retakeTest': 'Ta testen på nytt',
  'expediency.dialogHeader': 'Hensiktsmessighetstest',
  'expediency.dialogIntroText': `
  <para>Alle investorer på folkeinvest.no regnes som ikke-profesjonelle.</para>
  <para>Hensiktsmessighetstesten har til formål å avdekke om denne type investering er hensiktsmessig for deg.</para>
  <para>Dersom kunnskapsnivået anses som utilstrekkelig frarådes du å handle aksjer på folkeinvest.no.</para>`,
  'expediency.test.takeTheTestButton': 'Ta testen',
  'expediency.test.sharesCanBeHardToSell.title': 'Spørsmål 1',
  'expediency.test.sharesCanBeHardToSell.label':
    'På folkeinvest.no selges unoterte aksjer. Det vil si aksjer som ikke handles på børs. Kan det være vanskelig å få solgt aksjer du har kjøpt på folkeinvest.no?',
  'expediency.test.rightsToGeneralAssembly.title': 'Spørsmål 2',
  'expediency.test.rightsToGeneralAssembly.label': 'Har du, som aksjonær, rett til å møte på generalforsamling?',
  'expediency.test.canLoseMoreThanInvested.title': 'Spørsmål 3',
  'expediency.test.canLoseMoreThanInvested.label':
    'Kan du som aksjonær stilles ansvarlig for Foretaket, og tape mer enn pengene du har investert?',
  'expediency.test.hasPreviousExperience.title': 'Spørsmål 4',
  'expediency.test.hasPreviousExperience.label':
    'Har du tidligere erfaring med aksjer som ikke er notert på børs, såkalte unoterte aksjer?',
  'expediency.test.tradedLastFiveYears.title': 'Spørsmål 5a',
  'expediency.test.tradedLastFiveYears.label': 'Hva er riktig om din erfaring med unoterte aksjer?',
  'expediency.test.tradedLastFiveYears.subTitle': 'Tidsrom (velg én)',
  'expediency.test.tradedLastFiveYears.trueLabel': 'Jeg har handlet i unoterte aksjer siste fem år',
  'expediency.test.tradedLastFiveYears.falseLabel': 'Det er mer enn fem år siden min forrige handel i unoterte aksjer',
  'expediency.test.tradedMoreThanThreeTimes.title': 'Spørsmål 5b',
  'expediency.test.tradedMoreThanThreeTimes.label': 'Hva er riktig om din erfaring med unoterte aksjer?',
  'expediency.test.tradedMoreThanThreeTimes.subTitle': 'Hyppighet (velg én)',
  'expediency.test.tradedMoreThanThreeTimes.trueLabel': 'Jeg har handlet i unoterte aksjer mer enn tre ganger',
  'expediency.test.tradedMoreThanThreeTimes.falseLabel': 'Handlet i unoterte aksjer mindre enn tre ganger',
  'expediency.test.tradedForMoreThan100kNok.title': 'Spørsmål 5c',
  'expediency.test.tradedForMoreThan100kNok.label': 'Hva er riktig om din erfaring med unoterte aksjer?',
  'expediency.test.tradedForMoreThan100kNok.subTitle': 'Omfang (velg én)',
  'expediency.test.tradedForMoreThan100kNok.trueLabel': 'Jeg har handlet i unoterte aksjer for mer enn 100 000kr',
  'expediency.test.tradedForMoreThan100kNok.falseLabel': 'Jeg har handlet i unoterte aksjer for mindre enn 100 000kr',
  'expediency.test.hasTheKnowledgeNeeded.title': 'Spørsmål 6',
  'expediency.test.hasTheKnowledgeNeeded.label':
    'Har du nødvendig kunnskap til å forstå risikoen ved investering i unoterte aksjer?',
  'expediency.test.canAffordToLoseInvestment.title': 'Spørsmål 7',
  'expediency.test.canAffordToLoseInvestment.label':
    'Har du råd til å tape hele investeringsbeløpet du planlegger å investere for på folkeinvest.no?',
  'expediency.test.hasRelevantEducation.title': 'Spørsmål 8',
  'expediency.test.hasRelevantEducation.label': 'Har du fullført høyere utdanning innen økonomi eller finans?',
  'expediency.test.hasRelevantWorkExperience.title': 'Spørsmål 9',
  'expediency.test.hasRelevantWorkExperience.label': 'Har du relevant yrkeserfaring innenfor økonomi eller finans?',
  'expediency.test.confirmInformationButton': 'Bekreft',
  'expediency.test.hasSteadyIncome.title': 'Spørsmål 10',
  'expediency.test.hasSteadyIncome.label': 'Har du fast inntekt?',
  'expediency.test.backButton': 'Tibake',
  'expediency.test.nextButton': 'Gå videre',
  'expediency.test.cancelButton': 'Avbryt',
  'expediency.test.yourProfile': 'Din profil',
  'expediency.test.name': 'Navn:',
  'expediency.test.dateOfBirth': 'Fødselsdato:',
  'expediency.test.postalCode': 'Postnummer:',
  'expediency.test.postalArea': 'Poststed:',
  'expediency.test.country': 'Land:',
  'expediency.test.email': 'Epost:',
  'expediency.test.phoneNumber': 'Telefonnummer:',
  'expediency.test.finishedConfirmation': 'Jeg bekrefter at disse opplysningene stemmer *',
  'expediency.test.finishedConfirmationAstrix': '* Må bekreftes for å fortsette',
  'expediency.test.conclusionHeader': 'Konklusjon',
  'expediency.test.conclusionWarning': 'Folkeinvest AS fraråder investering i unoterte aksjer på folkeinvest.no.',
  'expediency.test.conclusionPassed':
    'Hensiktsmessighetstest bestått. Folkeinvest AS ser ingen grunn til fraråding. Lykke til!',
  'expediency.test.conclusionNextStepInfo': "Ved å trykke 'Fullfør' vil du godta konklusjonen av dette skjemaet.",
  'expediency.test.finishButton': 'Fullfør',
  'expediency.failedContinueCheckboxLabel': 'Jeg velger å ignorere frarådingen basert på hensiktsmessighetstesten',
  'confirmation.heading': 'Bekreftelse',
  'confirmation.chairmanAuthorization':
    'Jeg gir med dette min fullmakt til styreleder i utsteder til å tegne aksjer på mine vegne under fremsatte vilkår i forbindelse med vedtak om kapitalutvidelse i selskapets generalforsamling',
  'confirmation.riskAccepted':
    'Jeg bekrefter at jeg har satt meg inn i investeringstilbudet, og aksepterer risikoen denne investeringen innebærer.',
  'confirmation.createVpsAccount':
    'Jeg bekrefter at det vil bli opprettet en VPS-konto for å imøtekomme selskapets krav om dette innen gitt frist.',
  'confirmation.withinValidation':
    'Jeg bekrefter at min tegning blir utført under emisjonens valideringsfase og vil ikke kunne trekkes.',
  'confirmation.validationWarning':
    'Emisjonen er for øyeblikket i sin valideringsfase og tegninger utført under denne fasen kan ikke trekkes. Det kreves en ekstra bekreftelse at dette er forstått for å kunne tegne.',
  'confirmation.lastCallWarning':
    'Det er mindre en 48 til emisjonen avsluttes, det betyr at tegninger som gjennomføres nå ikke kan trekkes.',
  'confirmation.reserveSubscriptionError':
    'Det har skjedd en feil ved tegningen, vennligst prøv på nytt. Hvis det vedvarer ta kontakt med kundestøtte.',
  'confirmation.reserveSubscriptionSuccess': 'Tegningen ble vellykket gjennomført.',
  'confirmation.singlePurposeVehicle':
    'Jeg er inneforstått med at emisjonen er tilrettelagt for holdingselskap (SPV), og at investeringer under en grenseverdi vil bli tilknyttet holdingselskapet.',
  'success.title': 'Gratulerer med din tegning!',
  'success.congratulationText':
    'En kvittering er sendt til din e-post adresse. Du kan finne info om alle dine tegninger på din profilside. Denne tegningen vil også stå oppført under emisjonens tegningsliste.',
  'success.yourSubscription': 'Din tegning',
  'success.campaign': 'Emisjon:',
  'success.numberOfShares': 'Antall aksjer:',
  'success.totalAmount': 'Totalbeløp:',
  'success.moreCompaignsButton': 'Se flere emisjoner',
  'success.goToProfile': 'Gå til kjøp og reservasjoner',
  'success.beforeYouMoveOnHeader': 'Før du går videre',
  'success.beforeYouMoveOnParagraph':
    'Som ny eier i {title} kan du hjelpe selskapet ved å fortelle dine venner om investeringstilbudet. Med det øker sannsynligheten for at {title} får inn nok penger, og at din investering blir lønnsom.',
  'success.review': 'Gi en vurdering',
  'success.giveFeedback': 'Gi tilbakemelding',
  'success.feedbackSuccess': 'Oversending av vuderingen vellykket',
  'vpsInput.title': 'VPS konto',
  'vpsInput.subTitle': 'Denne emisjonen krever en VPS-konto.',
  'vpsInput.description':
    '{companyName} skal registrere sine aksjer i Verdipapirsentralen. Det betyr at alle investorer i denne emisjonen har behov for en VPS-konto. Hvis du ikke har en VPS-konto per dags dato, kan du gå videre med tegningen nå for senere å registrere kontoen i din profil.',
  'vpsInput.readMoreLink': 'Les mer om VPS',
  'vpsInput.trueLabel': 'Jeg vil registrere VPS-kontoen min nå',
  'vpsInput.falseLabel': 'Jeg vil registrere VPS-konto senere, og senest 1 døgn etter lukket emisjon',
  'vpsInput.inputLabel': 'VPS kontonummer (12 siffer)',
  'vpsInput.formatErrorMessage': 'Må ha 12 siffer og kan ikke være en ASK-konto.',
  'vpsInput.howToRegisterLater': 'Hvordan etter-registrerer jeg en VPS-konto?',
  'vpsInput.registerLaterInfo':
    'Du kan etter-registrere VPS-konto direkte på din tegning under Min Profil.{br}Dette kan du gjøre frem til 24 timer etter tegningsvinduet har lukket.',
}

export const issuerPublicInvestmentOfferFollowers = {
  heading: 'Interesserte',
  missingValidationCapital: 'Etter valideringssum er oppnådd vil følgerliste bli tilgjengelig.',
  noData: 'Noe gikk galt under henting av følgere.',
  copyEmail: 'Kopier e-post',
  emailCopied: 'Epost kopiert til utklippstavle',
  'toggle.interested': 'Interesserte',
  'toggle.approved': 'Godkjente',
  'toggle.invited': 'Inviterte',
  'header.email': 'E-post',
  'header.name': 'Navn',
  'header.status': 'Status',
  'status.interested': 'Ønsker forhåndstegning',
  'status.approved': 'Kan forhåndstegne',
  'status.invited': 'Invitert til forhåndstegning',
  'button.interested': 'Godkjenn for forhåndstegning',
  'button.approved': 'Marker som invitert',
  'confirm.interested.title': 'Godkjenn forhåndstegningsmulighet',
  'confirm.interested.content':
    'Dette vil gi mulighet til {count, plural, one {1 interessent} other {{count} interessenter}} for å forhåndstegne. Er du sikker?',
  'confirm.approved.title': 'Marker som invitert',
  'confirm.approved.content':
    'Dette vil markere at {count, plural, one {1 person} other {{count} personer}} har blitt invitert til å forhåndstegne. Har dere direkte kontaktet disse personene for forhåndstegning?',
}

export const investorProfile = {
  reservations: 'Kjøp og reservasjoner',
  'reservations.empty': 'Ingen kjøp eller reservasjoner registrert',
  declinedByIssuer: 'Tegningen er avvist av utstederselskap',
  cancelled: 'Tegningen er kansellert',
  investmentOffer: 'Investeringstilbud',
  viewInvestmentOffer: 'Se investeringstilbud',
  openDate: 'Tegningsvindu åpner: {date}',
  closeDate: 'Tegningvindu stenger: {date}',
  hasClosed: 'Tegningsvinduet er stengt',
  company: 'Selskap',
  'company.organizationNumber': 'Orgnr:',
  reservedShares: 'Tegnede aksjer',
  'reservedShares.shares': 'Aksjer:',
  'reservedShares.amount': 'Beløp:',
  'reservedShares.signee': 'Tegnet på:',
  'reservedShares.date': 'Dato tegnet:',
  'spv.title': 'SPV',
  'spv.accomodatedForSPV':
    'Denne emisjonen har tilrettelagt for SPV.{br} Den totale summen på tegningene dine vil avgjøre om du får et indirekte eierskap via et holdingselskap (SPV) eller direkte i <b>{companyName}</b>.',
  'spv.amountNeededForSharesInTarget':
    'Grenseverdien for bli allokert aksjer direkte i <b>{companyName}</b> er <b>{amount}</b> ({shares} aksjer).',
  'spv.conversion.subscriptionIsAssigned': 'Denne tegningen er tilknyttet <b>{companyName}</b>',
  'spv.conversion.sharesValue': 'Tegningens beløp: <b>{amount}</b>',
  'spv.conversion.sharesIn': '<b>{shares}</b> aksjer i <b>{companyName}</b>',
  'spv.conversion.isEqualTo': 'Tilsvarer {arrow}',
  'spv.conversion.pricePerShare': 'Aksjepris: <b>{amount}</b>',
  paymentInfo: 'Betalingsinformasjon',
  'paymentInfo.paymentTo': 'Betales til:',
  'paymentInfo.organizationNumber': 'Org.nr:',
  'paymentInfo.refcode': 'Melding:',
  'paymentInfo.accountNumber': 'Kontonummer:',
  'paymentInfo.swift': 'SWIFT/BIC nummer:',
  'paymentInfo.iban': 'IBAN nummer:',
  'paymentInfo.amount': 'Beløp:',
  'paymentInfo.dueDate': 'Forfallsdato:',
  'paymentInfo.infoSent': 'Betalingsinformasjon sendt:',
  'paymentInfo.paymentFromAbroad': 'Betaling fra utland',
  'paymentInfo.investmentOfferOngoing':
    'Betalingsinformasjon blir tilgjengelig etter emisjonen er lukket og aksjer er allokert. \nDersom emisjonen ikke blir finansiert, skal ikke beløpet betales.',
  'paymentInfo.pending':
    'Betalingsinformasjon blir tilgjengelig når tegningslisten er behandlet og aksjer er allokert.',
  'paymentInfo.investmentOfferFailed': 'Emisjonen ble ikke finansiert. Beløpet skal ikke betales.',
  'cancelSubscription.cancelButton': 'Kanseller tegning',
  'cancelSubscription.hideButton': 'Skjul tegning',
  'cancelSubscription.dialog.title': 'Kansellering av tegning',
  'cancelSubscription.dialog.subtitle': 'Bekreftelse av kansellering.',
  'cancelSubscription.dialog.cancelDescription':
    'For å kunne behandle ditt ønske om å trekke din tegning trenger vi å vite hva ved investeringstilbudet som gjør at du ikke lenger ønsker å bli medeier.',
  'cancelSubscription.dialog.cancelQuestion': 'Hvorfor ønsker du å trekke tegningen?',
  'cancelSubscription.dialog.cancelLength': 'Må inneholde minst 30 tegn',
  'cancelSubscription.error.withdraw': 'Kunne ikke trekke tegning, prøv igjen eller kontakt support',
  'cancelSubscription.tooltip.withdrawTags':
    'Styret, ansatte, tidligere eiere og forhåndserklærte investorer har ikke mulighet til å kansellere tegninger.',
  'cancelSubscription.tooltip.afterDeadline': 'Fristen for sletting av reservasjoner har løpt ut.',
  paymentRegistered: 'Betaling registrert',
  'pdf.download': 'Last ned PDF',
  'pdf.show': 'Vis bekreftelse',
  'pdf.hide': 'Skjul bekreftelse',
  'pdf.error': 'Kunne ikke generere bekreftelse',
  'pdf.error.message': 'Kunne ikke generere bekreftelse, oppfrisk siden og prøv igjen eller kontakt support.',
  'vps.accountRegistered': 'VPS-kontoen din er registrert på denne tegningen',
  'vps.account': 'VPS-konto',
  'vps.description':
    'Selskapet du har tegnet aksjer i skal registrere sine aksjer i Verdipapirsentralen og din tegning krever dermed en VPS-konto.',
  'vps.inputLabel': 'VPS-konto (12 siffer)',
  'vps.submitSuccess': 'VPS-konto oppdatert',
}

export const publicInvestmentOfferDiscussion = {
  heading: 'Diskusjon',
  'locked.message':
    'Emisjonen er avsluttet og diskusjonsforumet er låst. For ytterlige spørsmål, ta kontakt direkte med selskapet.',
  tip: '<b>Tips: </b>Du kan få epost-varsler om aktivitet i diskusjoner ved å klikke på bjelle-ikonet til høyre for tema/tittel.',
  toParticipateMessage: 'For å kunne delta i diskusjoner må du <link>autentiseres med BankID</link>',
  startNewDiscussion: 'Start ny diskusjon',
  noDiscussionsCreated: 'Ingen diskusjoner opprettet',
  beTheFirst: 'Vær den første ved å trykke "Start ny diskusjon"',
  helperText: 'Vær oppmerksom at det er ikke mulighet til å endre kommentaren i etterkant',
  createDiscussion: 'Opprett ny diskusjon',
  commentSaved: 'Kommentar lagret',
  unableToCreateComment: 'Kunne ikke opprette kommentar. Er diskusjonsforumet låst?',
  associatedToCompany: ' (Tilknyttet selskapet)',
  notLoggedIn: 'Du er ikke logget inn, oppfrisk siden for å oppdatere innhold',
  verifyEmail: 'Du må verifisere din epost for å kunne benytte diskusjons-varsler',
  'updateSubscription.subscribed': 'Du vil nå motta epost om aktivitet i denne diskusjonen',
  'updateSubscription.notSubscribed': 'Du vil ikke motta epost om aktivitet i denne diskusjonen',
  created: 'Opprettet: ',
  comments: '{numComments} kommentar{postfix}',
  addComment: 'Legg til kommentar',
  latestActivity: 'Siste aktivitet: ',
  yourComment: 'Din kommentar',
  titleTheme: 'Tittel/tema',
  emailNotification: 'Epost-notifikasjon',
  discussionSaved: 'Diskusjon lagret',
  unknown: 'UKJENT',
}

export const requiredEmissionDocuments = {
  header: 'Dokumenter',
  generalAssemblyProtocol: 'Generalforsamlingsprotokoll',
  'generalAssemblyProtocol.description': 'Generalforsamlingsprotokoll hvor kapitalutvidelsen blir vedtatt.',
  spvGeneralAssemblyProtocol: 'Generalforsamlingsprotokoll (SPV)',
  'spvGeneralAssemblyProtocol.description':
    'Generalforsamlingsprotokoll hvor kapitalutvidelsen for holdingselskapet blir vedtatt.',
  updatedCompanyCertificate: 'Oppdatert firmaattest',
  'updatedCompanyCertificate.description': 'Firmaattest med oppdatert aksjekapital etter kapitalutvidelsen.',
  spvUpdatedCompanyCertificate: 'Oppdatert firmaattest (SPV)',
  'spvUpdatedCompanyCertificate.description':
    'Firmaattest for holdingselskapet med oppdatert aksjekapital etter kapitalutvidelsen.',
}

export const notifications = {
  comments: '{comments, plural, one {1 kommentar} other {{comments} kommentarer}}',
  header: 'Kommentar',
}

export const deleteUser = {
  areYouSure: 'Er du sikker på at du ønsker å slette din brukerprofil?',
  confirmDeleteHeader: 'Bekreft sletting av brukerprofil',
  enterRegisteredEmail: 'Fyll inn registrert epost-adresse',
  confirmCheckboxLabel: 'Jeg bekrefter at jeg ønsker å slette min brukerprofil',
  confirmDeleteButton: 'Slett brukerprofil',
  deactivationUnavailable: `
    <header>Sletting ikke tilgjengelig</header>
    <para>Følgende kriterier må være oppfylt for at brukerprofilen kan bli slettet:</para>
    <li>Ingen påbegynte kundeforhold med Folkeinvest AS</li>
    <li>Ingen pågående eller ubetalte tegninger i aktive emisjoner</li>
    
    <para>Det er registrert <bold>{agreementsCount} kundeforhold</bold> på din profil</para>
    <para>Det er registert <bold>{reservationsCount} aktive tegninger</bold> på din profil</para>
  `,
  deleteDescriptionHeader: 'Sletting av brukerprofil',
  deactivateDescription: `
    <header>Hva mister jeg tilgang til?</header>
    <para>
      Ved å slette din brukerprofil, vil du miste tilgang til tjenester på folkeinvest.no 
      som krever innlogging. 
    </para>
    
    <para>Dette inkluderer blant annet:</para>
    <li>Lese fullstendig investeringstilbud</li>
    <li>Investere i emisjoner</li>
    <li>Delta i diskusjonsforum</li>
    <li>Motta nyhetsbrev eller oppdatering om investeringsmuligheter</li>
    <li>Inngå kundeavtale med Folkeinvest AS som utsteder</li>
  `,
  processDescription: `
    <header>Hva skjer med mine personopplysninger?</header>
    <li>Navn, adresse og telefonnummer vil slettes fra våre systemer</li>
    <li>All informasjon rundt pålogging vil slettes. (Passord, BankID, Google)</li>
    <li>Din epostadresse vil fjernes fra alle epost-lister</li>
    <li>Fulgte diskusjonstråder i diskusjonsforum vil fjernes</li>
    <li>Dine innlegg på diskusjonsforum vil bli anonymisert</li>
    <li>Tilknytning til investeringsselskap vil fjernes</li>
    
    <para>
      Folkeinvest AS, under tilsyn av Finanstilsynet, er pålagt å ta vare på informasjon om
      finansielle transaksjoner i minst 10 år. 
    </para>
    <para>
      Dersom din profil har registrerte tegninger, eller inngått kundeavtale med Folkeinvest AS 
      som utsteder, vil disse opplysningene <bold>ikke</bold> slettes fra våre systemer.
    </para>
  `,
}

export const helpTextHeaders = {
  dashboard: 'Velkommen til veilederen',
  additionalDocuments: 'Tilleggsdokumenter',
  requiredMeetings: meetings.requiredMeetings,
  ownersAndContributors: emissionProcessContributor.ownersAndContributors,
  'emission.equityOffering': issuerWebApp['navigationMenuLink.emissionInformation.equityOffering'],
  foundingDocument: investmentOfferInformationFields.foundingDocument,
  market: investmentOfferInformationFields.market,
  previousStartupExperience: investmentOfferInformationFields.previousStartupExperience,
  partOfCorporation: investmentOfferInformationFields.partOfCorporation,
  softFunding: investmentOfferInformationFields.softFunding,
  shareholderInformationManagement: investmentOfferInformationFields.shareholderInformationManagement,
  companyValuation: investmentOfferInformationFields.companyValuation,
  fundraisingTarget: investmentOfferInformationFields.fundraisingTarget,
  sharesToIssue: investmentOfferInformationFields.sharesToIssue,
  investmentLimits: investmentOfferInformationFields.investmentLimits,
  statutesChanges: investmentOfferInformationFields.statutesChanges,
  runway: investmentOfferInformationFields.runway,
  furtherFinancing: investmentOfferInformationFields.furtherFinancing,
  teamAndNetwork: issuerWebApp['navigationMenuLink.teamAndNetwork'],
  'teamAndNetwork.team': issuerWebApp['navigationMenuLink.teamAndNetwork.team'],
  'teamAndNetwork.board': issuerWebApp['navigationMenuLink.teamAndNetwork.board'],
  'teamAndNetwork.partners': issuerWebApp['navigationMenuLink.teamAndNetwork.partners'],
  annualAccounts: accounting.annualAccounts,
  budgetPrognosis: accounting.budgetPrognosis,
  captable: accounting.captable,
  previousValuations: accounting.previousValuations,
  valuationDocument: accounting.valuationDocument,
  auditReports: accounting.auditReports,
  isCompanySubjectToAudit: accounting.isCompanySubjectToAudit,
  shareholderAgreement: accounting.shareholderAgreement,
  nationalProspectus: accounting.nationalProspectus,

  economy: investmentOfferPresentation.economy,
  issueConditions: investmentOfferPresentation.issueConditions,

  convertibleLoans: issuerLoanConversions['convertibleLoan.title'],
}

export const helpTextContent = {
  dashboard: `
    <para>Veilederen er verktøyet hvor utstederselskap blir veiledet gjennom prosessen fra oppstart av arbeid og frem til ferdig utarbeidet investeringstilbud, som til slutt skal offentliggjøres på folkeinvest.no.</para>
    {br}
    <para><bold>Et balansert, klart og ikke villedende investeringstilbud</bold></para>
    <para>I arbeidet med investeringstilbudet må det gis et sannferdig inntrykk av selskapet, og informasjonen som presenteres må være balansert, klar og ikke-villedende. Det må gis et rettmessig bilde av hva som kreves for å nå de målsettingene som er satt for selskapet, hvor det også fremkommer hvilken risiko en investering i deres selskap innebærer. Det legges til grunn at all informasjon som fylles inn og alle dokumenter som lastes opp inneholder korrekt informasjon, noe som deres styreleder også signerer for gjennom en fullstendighetserklæring når all informasjon er lagt inn.</para>
    {br}
    <para><bold>Oppbygning av veilederen og arbeidsprosess </bold></para>
    <para>Veilederen er bygd opp av ulike moduler med sine tilhørende felter, samt et dashbord.</para>
    <para>I veilederen vil dere møte ulike moduler som skal fylles ut. På høyresiden av dashbordet er det veiledning til utfylling som bygger på anbefalt prosess fra Folkeinvest. Det er mulig å jobbe i alle modulene samtidig, men det anbefales å følge veiledningen. Underveis i utarbeidelsen vil det være behov for godkjenning av spesifikke underlagsdokumenter, før det til slutt gjennomføres en godkjenningsprosess for investeringstilbudet i sin helhet.</para>
    <para>For de ulike seksjonene vil det være et klikkbart «spørsmålstegn-symbol» { helpicon } tilgjengelig hvor det finnes utvidet informasjon om den spesifikke modulen. Dette er også tilgjengelig for noen av de enkeltstående feltene som skal fylles ut. Det anbefales å lese hjelpeteksten som er tilgjengelig bak «spørsmålstegnsymbolet» før arbeidet på ulike moduler eller felt påstartes.</para>
    {br}
    <para><bold>Bruk av opplastet og innlagt innhold</bold></para>
    <para>Noe av informasjonen som skal deles er kun for bruk internt av tilretteleggerteamet i Folkeinvest. Dette vil fremkomme i beskrivelsen der dette er tilfellet.</para>
    {br}
    <para><bold>Tilgangsnivåer og bidragsytere</bold></para>
    <para>Det er kun én person som står som eier av kapitalutvidelsen. Dette er den personen som originalt sendte inn onboardingskjema for selskapet. Det er mulig å bytte eier ved at eierskapet overføres til ny person fra den som er eier av kapitalutvidelsen per tid.</para>
    <para>Det er mulig for flere å bidra til utarbeidelsen av investeringstilbudet. Eier av kapitalutvidelsen kan legge til flere bidragsytere i dashbordet. Alle som skal ha tilgang til å bidra til utarbeidelsen må ha en brukerprofil på folkeinvest.no.</para>
    {br}
    <para>Ovenstående informasjon vil være tilgjengelig ved «spørsmålstegn-symbolet» { helpicon } ved siden av overskriften for dashbordet til veilederen.</para>
    <para>Lykke til med arbeidet!</para>
  `,
  additionalDocuments: `
    <para>Følgende dokumenter kan lastes opp, dersom de foreligger:</para>
    <ul>
    <li>Business Model Canvas</li>
    <li>Markedsanalyser</li>
    <li>Andre dokumenter dere ønsker å presentere for potensielle investorer</li>
    </ul>
    {br}
    Følgende dokumenter lastes opp på forespørsel fra Folkeinvest: 
    <ul>
    <li>Skatteattest (RF1316), hentes ut fra Altinn</li>
    <li>Saldobalanse per 31.12 siste 3 år samt for siste periode i inneværende år (i Excel)</li>
    <li>Aldersfordelt kunde- og leverandørreskontro per 31.12 foregående år samt for siste periode i inneværende år</li>
    <li>Dokumentasjon av andre eiendelsposter, gjeldsposter og resultatposter, eksempelvis «andre fordringer», «annen gjeld» og «andre kostnader»</li>
    <li>Oversikt over, og spesifisering av, ikke bokførte driftsmidler og eiendeler som ikke er driftsrelaterte (eiendommer, aksjer, finansielle plasseringer, garantistillelser, pensjonsforpliktelser o.l.)</li>
    <li>Oversikt over eiendeler som har en høyere verdi enn bokført verdi</li>
    <li>Dokumentasjon knyttet til morselskap/datterselskap/tilknyttet selskap</li>
    <para>- Eventuelle andre dokumenter</para>
    </ul>
  `,
  requiredMeetings:
    '<para>Her kan du velge hvem i Folkeinvest du skal booke møte med. Du vil få anledning til å booke et tidspunkt det passer deg.</para>',
  ownersAndContributors:
    '<para>Emisjonseier er primærkontakten deres og vil motta alle automatiske e-poster og varslinger. Bidragsytere er ofte andre i teamet eller styret som bidrar i utarbeidelsen av investeringstilbudet.</para>',
  foundingDocument:
    '<para>Hvis privatperson har stiftet selskapet må dere huske å fjerne siste del av personnummer før dere laster opp dokumentet.</para>',
  market:
    '<para>Markedspotensiale må ha sammenheng med budsjettperioden som legges til grunn i investeringstilbudet.</para>',
  previousStartupExperience: '<para>Knytt navn til hvilke selskaper og personer dette gjelder.</para>',
  partOfCorporation: '<para>Beskriv konsernstrukturen.</para>',
  softFunding: '<para>Dette kan gjelde Innovasjon Norge, Forskningsrådet, kommune, eller andre.</para>',
  shareholderInformationManagement:
    '<para>Det er vanlig å oppdatere aksjonærer månedlig eller kvartalsvis med nyhetsbrev eller aksjonæroppdatering. </para>',
  'emission.equityOffering': '<para>Her skal dere fylle ut de viktigste parameterene for emisjonen. </para>',
  companyValuation:
    '<para>Kalkulatoren regner ut verdsettelse, pålydende og overkurs basert på informasjonen dere legger inn.</para>',
  'fundRaisingTarget.requestChange': 'Send forespørsel om endring av målsum til {email}',
  fundraisingTarget:
    '<para>Vær oppmerksom på at anslått kapitalbehov (målsum) må være innenfor nedre og øvre grense. Målsum vil ofte være i den øvre delen av intervallet.</para>',
  sharesToIssue:
    '<para>Vær oppmerksom på at anslått kapitalbehov (målsum) må være innenfor nedre og øvre grense. Målsum vil ofte være i den øvre delen av intervallet.</para>',
  investmentLimits:
    '<para>Normalt setter selskaper nedre grense i området 500-2000 kr, mens øvre grense settes høyt. </para>',
  statutesChanges:
    '<para>Hensikten med medsalgsplikt/rett er å beskytte både de opprinnelige eierne og de nye investorene i en oppkjøpssituasjon. </para>',
  runway:
    '<para>Folkeinvest bruker arbeidskapital (omløpsmidler minus kortsiktig gjeld) til å beregne tilgjengelige midler for runway.</para>',
  furtherFinancing:
    '<para>Det er vanlig at selskaper må hente ytterligere midler senere (emisjon, lånefinansiering, osv), eller at selskapet planlegger å gå med positiv drift. Investorer må gjøres kjent med hva som er planen til selskapet. Dette må samsvare med budsjettet.</para>',

  annualAccounts: `<para>Små foretak omfatter regnskapspliktige foretak som to år på rad oppfyller minst to av disse tre kravene:</para>
  <ul>
  <li>Salgsinntekter er mindre enn 70 millioner kroner</li>
  <li>Balansesum (summen av eiendelene i balansen) er mindre enn 35 millioner kroner</li>
  <li>Færre enn 50 ansatte målt i gjennomsnittlig antall årsverk i regnskapsåret</li>
  </ul>
  {br}
  <para>Aksjeselskap (AS) er revisjonspliktige. Unntak gjelder for de som har: </para>
  <ul>
  <li>Driftsinntekter mindre enn 5 millioner kroner</li>
  <li>Balansesum under 20 millioner kroner</li>
  <li>Ansatte under 10 årsverk</li>
  </ul>
  <para>Hvis en av disse tre overstiger maksimumsgrensen, er selskapet revisjonspliktig neste år.</para>`,
  budgetPrognosis: `<ul><li>Detaljert budsjett per måned på kort sikt (inneværende år) og overordnet på lang sikt (3-5 år)</li>
  <li>Budsjettet må være kontospesifisert</li>
  <li>Eventuelle fremtidige låneopptak og nye kapitalutvidelser må komme frem</li>
  <li>Oppsettet må inneholde hvilke elementer som går inn i de ulike postene, eksempelvis vil salgsinntekter ofte være et produkt av pris på produktet og volum.</li>
  <li>Kommentarer på eventuelle kontrakter som avviker fra markedsmessige vilkår, eksempelvis lønn til eiere/gründere, husleie m.m.</li>
  </ul>
  `,
  captable: `<para>En cap table er en sammenstilling som viser frem selskapets egenkapitaleiere og alle finansieringsrunder selskapet har vært gjennom.</para> 
  <para>Et godt cap table bør vise selskapets aksjeklasser, opsjoner, tegningsretter og konvertible lån. Videre bør det vise hvordan tildelingen var ved de ulike finansieringsrundene, samt selskapets verdiutvikling fra runde til runde.</para>`,
  previousValuations: '<para>Her menes verdivurderinger som er utarbeidet både internt og av ekstern part. </para>',
  valuationDocument: `<para>Verdivurderingen kan utarbeides av:</para> 
  <ul>
  <li>Selskapet selv. Folkeinvest tilbyr begrenset bistand med, og kvalitetssikring av, selskapets verdivurdering inkludert i etableringsgebyret.</li>
  <li>Folkeinvest tilbyr utarbeidelse av indikativ verdivurdering og verdivurderingsdokumentet mot et honorar.</li> 
  <li>En ekstern uhildet part. Folkeinvest tilbyr i disse tilfeller en separat fremforhandlet avtale med eksterne aktører.</li>
  </ul>
  `,
  auditReports: `
  <para>Med revisorbekreftelse menes bekreftelse eller tilsvarende uttalelse avgitt av et revisjonsselskap eller en statsautorisert revisor. Dette kan være revisors attestasjon på SkatteFUNN-prosjekter, offentlige tilskudd, prosjektregnskaper, redegjørelser m.m.</para>
  {br}
  <para>Nummererte brev fra revisor informerer om vesentlige feil som må rettes. Revisors oppgave er å uttale seg om årsregnskapet og øvrige forhold i henhold til loven. Dersom revisor avdekker feil eller lignende gjennom revisjonen, og de ikke rettes, vil revisor i de fleste tilfeller ha en plikt til å påpeke en slik retting i et skriftlig nummerert brev. Dette gjelder når revisor vurderer feilene til å være vesentlige.</para>`,
  isCompanySubjectToAudit: `
  <para>Aksjeselskap (AS) er revisjonspliktige. Unntak gjelder for de som har:</para>
  <ul>
  <li>Driftsinntekter mindre enn 5 millioner kroner</li>
  <li>Balansesum under 20 millioner kroner</li>
  <li>Ansatte under 10 årsverk</li>
  </ul>
  <para>Hvis en av disse tre overstiger maksimumsgrensen, er selskapet revisjonspliktig neste år.</para>
  <para>Å være revisjonspliktig vil si, ifølge revisorloven, at et selskap er pliktet til å påse at årsregnskapet revideres av en statsautorisert eller registrert revisor.</para>`,
  shareholderAgreement:
    '<para>En aksjonæravtale er en avtale mellom to eller flere aksjeeiere som eier aksjer i det samme selskapet. Et selskap er ikke lovpålagt å opprette en aksjonæravtale, og en del forhold mellom aksjeeierne skal alltid være spesifisert i vedtektene til selskapet.</para>',
  nationalProspectus:
    '<para>Et prospekt skal inneholde opplysninger som er nødvendige for at investorer skal kunne foreta en grundig vurdering av utsteders økonomiske stilling og utsikter, og hvilke rettigheter som er knyttet til verdipapiret. Prospekt skal sendes Foretaksregisteret for registrering før prospektet offentliggjøres. Se verdipapirhandelloven § 7-5 for mer informasjon.</para>',

  economy: `<para>Det skal inkluderes:</para>
  <ul>
  <li>Diagram som viser utvikling i driftsinntekter, driftskostnader og EBITDA</li>
  <li>Diagram som viser inntektsutvikling fordelt på produkt/tjenestekategori</li>
  <li>Diagram som viser utvikling i salg per produktkategori</li>
  </ul>
  {br}
  <para>Det skal inkluderes et overordnet budsjett (se budsjettmal) som presenterer et oppsummert resultatbudsjett og kontantstrømoppstilling i budsjettperioden 3-5 år, inkl. prognose for inneværende. 
  {br}
  Siste regnskapsår med faktiske tall bør inkluderes i første kolonne: (evt med bilde av overordnet budsjett)</para>`,
  issueConditions: `<para>Flere forhold som det er naturlig å liste opp her kan være gjenstand for endring underveis i tegningsvinduet. Vi anbefaler derfor at det på flere av disse punktene henvises med lenke til fanen "Grunnleggende info"</para>`,
  convertibleLoans: '<para>Her legges inn lån som skal konverteres i denne emisjonen</para>',

  teamAndNetwork:
    '<para>I denne seksjonen skal dere presentere administrasjonen og andre sentrale aktører og personer tilknyttet selskapet.</para>',
  'teamAndNetwork.team':
    '<para>Legg inn hver enkelt ansatt og eventuelt innleide ressurser i selskapet. Lag en kortfattet CV om hver enkelt, som inkluderer bl.a. erfaringer, utdanning og meritter. Tidligere oppstartserfaring, inkl. salg av bedrifter og konkurser kan trekkes frem.</para>',
  'teamAndNetwork.board':
    '<para>Legg inn styreleder og hvert enkelt styremedlem i selskapet. Lag en kortfattet CV om hver enkelt, som inkluderer bl.a. erfaringer, utdanning og meritter. Tidligere oppstartserfaring, inkl. salg av bedrifter og konkurser kan trekkes frem.</para>',
  'teamAndNetwork.partners':
    '<para>Legg inn samarbeidspartnere, relevante leverandører, advisory board-medlemmer ol. som dere ønsker å fremheve. Fortell også hvorfor disse er relevante partnere for selskapet.</para>',
}

export const investmentOfferInformationToolAccess = {
  header: 'Tilgang til veileder',
  criteriaDescription: 'For å gi utsteder tilgang til veilederen må følgende kriterier være oppfylt:',
  generalInformation: `
    <para>Sørg for at alle punktene er gjennomført før veilederen åpnes</para>
    <para>Hvis det er behov for at etableringsgebyr er betalt, må dette sjekkes manuelt.</para> 
    <para>Når tilgang til veileder blir gitt, vil det ikke være mulig å reversere denne handlingen</para>
    `,
  toolWasOpenedAt: 'Veilderen ble åpnet <bold>{date}</bold>',
  'listItem.applicationReceived': 'Søknadsskjema mottatt',
  'listItem.riskEvaluationCompleted': 'Risikovurdering gjennomført',
  'listItem.positiveMandateSet': 'Positivt mandat satt',
  'listItem.customerAgreementSigned': 'Signering av kundeavtale gjennomført',
}

export const helpTextUsedBy = {
  folkeinvest: 'Brukes av: Folkeinvest',
  investmentOffer: 'Brukes av: Investeringstilbud',
  folkeinvestInvestmentOffer: 'Brukes av: Folkeinvest, Investeringstilbud',
}

export const adminApprovalContext = {
  editmodeDescription: 'Endringer vil lagres automatisk.',
  editmodeReviewDescription:
    'Endringer vil lagres automatisk.{br}Publiserte tilbud krever ny signering av fullstendighet før endringene vil ta effekt/vises på investeringstilbud-siden. Dette gjelder ALLE endringer.',
  lockContent: 'Lukk redigering',
  editContent: 'Rediger innhold',
  noPartners: 'Selskapet har ingen partnere',
}

export const issuerReservedSubscriptions = {
  exportDetailedList: 'Eksporter liste',
  failedEmission: 'Emisjonen var ikke velykket og liste over tegninger holdes skjult grunnet investorvern.',
  'heading.markReservedSubscriptionsAsPaid': 'Marker følgende tegninger som betalt?',
  'heading.markReservedSubscriptionsAsNotPaid': 'Fjern markering som betalt fra følgende tegninger?',
  'heading.investorInformation': 'Investor-informasjon',
  'heading.paymentInformation': 'Betalings-informasjon',
  'heading.completePaymentProcess': 'Ferdigstill tegningsliste',
  'description.completePaymentProcess': `
    <para>Alle tegninger er registrert betalt eller avvist grunnet manglende betaling og listen er komplett.</para>
    <para>Trykk Fullfør for å ferdigstille tegningsliste.</para>
    <para>Hvis det skal utføres en ekstra sjekk av listen for å sikre at alle markeringer er korrekt, lukk denne dialogen ved å trykke Avbryt.</para>
  `,
  amountMarked: '{amount} tegninger valgt',
  paymentProcessCompleted: 'Registrering av betalinger fullført',
  'tooltip.completePaymentProcessUnavailable':
    'Liste kan ikke ferdigstilles før alle gyldige tegninger er markert som betalt.',
  'label.completePaymentProcess': 'Ferdigstill liste',
  'label.name': 'Navn',
  'label.shares': 'Aksjer',
  'label.amount': 'Beløp',
  'label.onBehalfOf': 'Tegnet på',
  'label.createdAt': 'Signert',
  'label.paymentRefId': 'Referanse',
  'label.paymentState': 'Betalt',
  'label.paid': 'Betalt',
  'label.notPaid': 'Ikke betalt',
  'label.reject': 'Avvis',
  'label.paymentInfoSentAt': 'Sendt: {date}',
  'label.dueDateAt': 'Forfallsdato: {date}',
  'label.markAsPaid': 'Marker betalt ({amount})',
  'label.markAsNotPaid': 'Marker ikke betalt ({amount})',
  'label.paymentTarget': 'Tilfaller',
  'tooltip.dueDateNotPassed':
    'Tegninger kan ikke avvises på grunnlag av manglende betaling før <b>etter</b> forfallsdato har passert.',
  'tooltip.paymentReceivedAt': 'Betaling registrert mottatt {date}',
  'rejection.rejectionSuccessful': 'Tegning avvist',
  'rejection.rejectionNotSuccessful': 'Tegning kunne ikke avvises.',
  'rejection.rejectedDueToMissingPaymentReason': 'Tegning er avvist av utsteder grunnet manglende betaling',
  'rejection.requestSent': 'Etterspørsel om avvisning send til tilrettelegger.',
  'rejection.rejectSubscription': 'Avvis tegning',
  'rejection.rejectDueToMissingPayment': 'Avvis tegning på grunnlag av manglende betaling',
  'rejection.requestRejection': 'Etterspør avvisning',
  'rejection.requestDenied': 'Forespørsel om avvisning ble avslått',
  'rejection.waitingForRejectionProcessed': 'Avvisning etterspurt. Venter på behandling av tilrettelegger',
  'rejection.willNotChangeFee': 'Dette vil ikke påvirke Folkeinvest sitt faktureringsgrunnlag',
  'approval.approveList': 'Godkjenn tegningsliste',
  'approval.listIsApproved': 'Listen er godkjent',
  exportDetailedInvestorList: 'Eksporter detaljert investorinfo',
  'approval.approvalSuccessful': 'Gokjenning utført',
  'approval.approvalNotPermitted': 'Kan ikke godkjenne. Listen innholder forespørsler om avvisning',
}

export const emailTemplates = {
  goToWeeklyMarketingEmail: 'Gå til ukentlig e-post',
  issuer: 'Utsteder',
  internal: 'Internt',
  investor: 'Investor',
  selectTemplate: 'Velg epost-mal',
  desktopView: 'Visning: desktop',
  mobileView: 'Visning: mobil',
  subject: 'Emne',
  noSubject: 'Velg en epost-mal før emne kan vises',
  accountingApproved: 'Underlag godkjent',
  accountingRejected: 'Underlag avslått',
  aml100k: 'AML-sjekk 100k',
  applicationOpen: 'Søknad opprettet',
  campaignCardApproved: 'Emisjonskort godkjent',
  campaignCardRejected: 'Emisjonskort avslått',
  customerAgreementCreated: 'Kundeavtale klar for signering',
  declarationOfCompleteneddCreated: 'Fullstendighetserklæring klar for signering',
  discussionComment: 'Diskusjonsforum svar på kommentar',
  discussionThread: 'Diskusjonsforum kommentar',
  emissionProcessContributor: 'Ny bidragyter',
  emissionProcessOwner: 'Ny emisjonseier',
  investmentOfferApproved: 'Investeringstilbud godkjent',
  investmentOfferRejected: 'Investeringstilbud avslått',
  investmentOfferToolOpen: 'Veileder åpnet',
  reservedSubscriptionReceipt: 'Bekreftelse på tegning',
  reservedSubscriptionReceiptPreorder: 'Bekreftelse på forhåndstegning',
  investmentOfferSubscriptionWindowOpening: 'Åpning av tegningsvindu',
  investmentOfferSubscriptionWindowClosingSuccessful: 'Lukking av tegningsvindu (vellykket)',
  investmentOfferSubscriptionWindowClosingNotSuccessful: 'Lukking av tegningsvindu (ikke vellykket)',
  reservedSubscriptionPaymentInfo: 'Betalingsinformasjon',
  reservedSubscriptionPaymentReminder: 'Betalingspåminnelse',
  'weeklyMarketingEmail.title': 'Ukentlig e-post',
  'weeklyMarketingEmail.goBackButton': '<< Tilbake til Epost-maler',
}

export const utm = {
  website: 'Nettsted',
  source: 'Kilde',
  medium: 'Medium',
  campaignName: 'Emisjon navn',
  content: 'Innhold',
  websitePlaceholder: 'eg. folkeinvest.no',
  sourcePlaceholder: 'eg. facebook',
  mediumPlaceholder: 'eg. paid',
  campaignNamePlaceholder: 'eg. Sulten AS',
  contentPlaceholder: 'eg. tegningsvindu åpner snart',
}

export const queueJobs = {
  id: 'ID',
  'header.pending': 'Kommende',
  'header.completed': 'Fullførte',
  createdAt: 'Opprettet',
  willProcessAt: 'Utføres',
  processedAt: 'Prossesert',
  completed: 'Fullført',
  clearCompletedJobs: 'Fjern fullførte jobber',
  emissionProcessId: 'Emisjonsprosess-id',
  'jobNames.subscriptionWindowOpening': 'Åpning av tegningsvindu',
  'jobNames.subscriptionWindowClosing': 'Lukking av tegningsvindu',
  'jobNames.missingVpsAccountReminder': 'Påminnelse om manglende VPS-konto',
  'dialog.header': 'Informasjon om jobb',
  'dialog.failedReason': 'Feilbegrunnelse (hvis ikke fullført)',
  'dialog.stopJob': 'Fjern jobb',
  'confirm.stopJob': 'Fjerner jobb med navn {queueName} fra {title}, er du sikker?',
}

export const userProfile = {
  loginServices: 'Innloggingstjenester',
  noPasswordTitle: 'Ikke mulig å koble fra innloggingstjeneste',
  noPasswordDescription: 'Du må sette et passord før du kan koble fra innloggingstjenesten.',
  disconnectServiceTitle: 'Koble fra innloggings-tjeneste',
  disconnectServiceDescription: 'Er du sikker på at du vil koble deg fra innloggingstjenesten?',
}

export const iconTitle = {
  heading: 'Overskrift',
  bold: 'Fet',
  italic: 'Kursiv',
  underline: 'Understreket',
  strikethrough: 'Gjennomstreket',
  fontColor: 'Skriftfarge',
  backgroundColor: 'Bakgrunnsfarge',
  textAlign: 'Tekstjustering',
  unorderedList: 'Punktliste',
  orderedList: 'Nummerert liste',
  blockQuote: 'Sitat',
  link: 'Lenke',
  image: 'Bilde',
  video: 'Video',
  removeFormatting: 'Fjern formattering',
}

export const keyStatistics = {
  raised: 'Egenkapital tilrettelagt',
  investors: 'Registrerte investorer',
  companies: 'Registrerte investeringsselskap',
  fundedCampaigns: 'Selskap finansiert',
  'subtitle.onFolkeinvest': 'på Folkeinvest.no',
  'subtitle.throughFolkeinvest': 'via Folkeinvest.no',
}

export const paginator = {
  showingFromTo: '{from} - {to} av {total}',
}

export const issuerPresentationFields = {
  removeSection: 'Fjern seksjon',
  reorderDescription: 'Vil du endre rekkefølge på seksjoner, endre tittel, legge til eller slette innholds-felt?',
  deleteSectionDescription: 'Dette vil fjerne seksjonen. Er du sikker?',
  confirmDeleteSection: 'Ja, fjern seksjon',
  addCustomField: 'Legg til innholdsseksjon',
  'layoutMode.label': 'Layout-modus',
  'layoutMode.open': 'Åpne layout-modus',
  'layoutMode.close': 'Tilbake til innholds-modus',
  'layoutMode.status': '{open, select, true {Tilbake til innholds-modus} other {Åpne layout-modus}}',
  'layoutMode.description': `
    <strongitalic>Hvorfor har noen av seksjonene grå farge?</strongitalic>{br}
    Seksjoner uten innhold er markert med en dempet grå farge. Dette illustrerer at seksjonen for øyeblikket mangler innhold, og vil ikke vises i investeringstilbudet.
    {br}{br}
    <strongitalic>Hva betyr ikonene?</strongitalic>
  `,
  'layoutMode.legend.removeSection': 'Fjern seksjon',
  'layoutMode.legend.removeSection.subText': '(Kun egendefinerte seksjoner)',
  'layoutMode.legend.changeOrder': 'Endre rekkefølge',
  'layoutMode.legend.changeTitle': 'Endre tittel',
  'layoutMode.legend.changeTitle.subText': '(Kun egendefinerte seksjoner)',
  'layoutMode.legend.lockedOrder': 'Låst posisjon',
  'layoutMode.resetOrderDescription': `
    Ønsker dere å <b>tilbakestille rekkefølge</b> på seksjonene?
    Bruk knappen nedenfor. Rekkefølgen blir da satt tilbake til den opprinnelige rekkefølgen.{br}
    <b>Dette vil ikke påvirke innhold i seksjonene.</b>
  `,
  'layoutMode.resetOrderButton': 'Tilbakestill rekkefølge',
}

export const livePage = {
  notFound: 'Investeringstilbud ikke funnet',
  notFoundDescription: 'Påse at adresse er korrekt og at tilbudet er publisert',
  willOfferUpTo: 'Skal hente inntil',
  'timebar.notPublished': 'Tegningsvindu ikke satt',
  'timebar.closed': 'Avsluttet',
  'timebar.opens': 'Åpner om',
  'timebar.closes': 'Avsluttes om',
  'labels.currentAmount': 'Nåværende',
  'labels.finishedAmount': 'Resultat',
  'labels.minimum': 'Minstesum',
}

export const investmentOfferLinks = {
  title: 'Lenker',
  visitUrl: 'Besøk url',
  copyUrl: 'Kopier url',
  'nolinks.availableAfterValidationStart': 'Lenke blir tilgjengelig når tilbudet er klart for validering',
  'nolinks.availableAfterIssuerOpens': 'Lenke blir tilgjengelig når veileder åpnes',
  'nolinks.availableAfterPublished': 'Lenke blir tilgjengelig når tilbudet publiseres',
  'nolinks.availableAfterCampaignCardPublished': 'Lenke blir tilgjengelig når emisjonskort er publisert',
  'comingSoon.title': 'Kommer-snart-kort',
  'comingSoon.description':
    'Direktelenke til kommer-snart-kort under oversikten. Denne lenken benyttes ved markedsføring av emisjonen. Den vil automatisk navigere besøkende rett til investeringstilbudet dersom tilbudet er publisert.',
  'issuer.title': 'Lenke til emisjon i utsteder-appen',
  'issuer.description': `Denne lenken bør helst ikke benyttes direkte av tilretteleggere 
    da all informasjon skal være tilgjengelig via tilrettelegger-appen. 
    Det kan derimot være en god måte å dobbeltsjekke noe/gi direktelenke på. 
    {br}
    NB: Man må være logget inn med admin-bruker/være bidragsyter for å få tilgang.`,
  'primary.title': 'Publisert investeringstilbud',
  'primary.description': 'Denne lenken er primær-lenken og vil brukes for publiserte investeringstilbud',
  'preview.title': 'Forhåndsvisning',
  'preview.description':
    'Denne lenken viser investeringstilbudet med innhold rett fra veilederen. Merk at det som vises her ikke blir reflektert i de andre lenkene før tilbudet blir publisert på nytt. Som for eksempel etter signering av fullstendighet.',
  'preorder.title': 'Forhåndstegning',
  'preorder.description': 'Denne lenken benyttes ved validering og forhåndstegning.',
  'readonly.title': 'Kun lesbart investeringstilbud',
  'readonly.description':
    'Denne lenken viser et kun lesbart investeringstilbud. All dynamisk data er skjult og handlinger er deaktivert. Dvs, mulighet for tegning, status, tegningsliste og diskusjonsforum.',
}

export const contactForms = {
  bookMeeting: 'Book møte',
  bookAMeeting: 'Book et møte',
  iWantToBeContacted: 'Jeg ønsker å bli kontaktet',
  weAreExcitedYouAreConsideringUs:
    'Vi gleder oss over at du vurderer å søke egenkapital med tilrettelegging fra Folkeinvest.',
  beforeWeCanStartWorkingTogetherEtc: `Før vi kan inngå et formelt samarbeid, må ditt selskap gjennom deler av vår onboardingsprosess. Vår
    onboardingprosess og tilhørende informasjonsmøter er gratis og uforpliktende. Du får muligheten til å bli
    kjent med oss slik at du kan avgjøre hvorvidt et samarbeid er formålstjenlig med de ambisjoner og behov ditt
    selskap har.`,
  ifThisSoundsInterestingEtc: `Om dette høres interessant ut kan du enten booke et møte med oss, eller fylle ut vårt kontaktskjema slik at
  vi kan ta en prat om din bedrift.`,

  wantsToBeContactedBy: 'Jeg ønsker å bli kontaktet av Folkeinvest via',
  wantsToBookMeeting: 'Jeg ønsker å booke et møte med Folkeinvest',
  wantsToReceiveOtherInfo: 'Jeg ønsker å motta annen informasjon fra Folkeinvest.',
  weTakeOurDataPrivacySeriously:
    'I Folkeinvest tar vi personvern på alvor og vi vil kun bruke personlige opplysninger til å administrere kontoen din og gi deg informasjon om de produktene og tjenestene som du har bedt om. Fra tid til annen vil vi gjerne ta kontakt om våre produkter og tjenester samt annet innhold som kan være av interesse.',
  termsAndCondtiononsShort: `Når du benytter Folkeinvest sine tjenester, bekrefter du at du er kjent med vår <link>personvernserklæring</link>.
{br}
{br}
   Når du klikker på send nedenfor, gir du tillatelse til at Folkeinvest kan lagre og behandle de personlige opplysningene som ble sendt ovenfor for å gi forespurt innhold.`,
}

export const emissionSettings = {
  title: 'Innstillinger',
  'vps.toggleText': 'Utsteder har eller skal registrere sine aksjer i VPS',
  'vps.helpText': `Aktiverer VPS-funksjonalitet.
    {br}{br}
    Funksjonaliteten legger til ekstra steg i tegningsflyten og det kreves innmelding av VPS-konto fra investorkunde. 
    {br}{br}
    Funksjonaliteten kan aktiveres/deaktiveres frem til valideringsfase påstartes.`,
  'vps.confirmDialog.activate.description':
    'Aktiverer VPS-funksjonalitet. Funksjonaliteten legger til ekstra steg i tegningsflyten og det kreves innmelding av VPS-konto fra investorkunde.',
  'vps.confirmDialog.deactivate.description':
    'Deaktiver støtte for VPS-konto i denne emisjonen. Dette vil tilbakestille emisjonen til ordinær tegningsflyt.',
  'vps.confirmDialog.activate.title': 'Bekreft aktivering av VPS-funksjonalitet',
  'vps.confirmDialog.deactivate.title': 'Bekreft deaktivering av VPS-funksjonalitet',
  'vps.confirmDialog.activate.confirm': 'Ja, aktiver VPS',
  'vps.confirmDialog.deactivate.confirm': 'Ja, deaktiver VPS',
  'singlePurposeVehicle.toggleText': 'Utsteder skal benytte Single Purpose Vehicle (SPV)',
  'singlePurposeVehicle.helpText': `Aktiverer bruk av SPV.
    {br}{br}
    Støtte for SPV vil påvirke hva som må inkluderes i investeringstilbudet, og påkreve opplasting av SPV-relaterte dokumenter. 
    {br}
    Det kreves også at selskapet oppretter et SPV.
    {br}{br}
    Funksjonaliteten kan aktiveres/deaktiveres frem til valideringsfase påstartes, 
    men det anbefales ikke å endre etter man først har aktivert.`,
  'singlePurposeVehicle.confirmDialog.activate.description':
    'Aktiverer SPV-funksjonalitet. Dette vil påvirke hva som må inkluderes i investeringstilbudet, og påkreve opplasting av SPV-relaterte dokumenter. Det kreves også at selskapet oppretter et SPV..',
  'singlePurposeVehicle.confirmDialog.deactivate.description':
    'Deaktiver støtte for SPV. Dette vil påvirke investeringstilbudet og betalingsprosessen. SPV-relaterte data vil skjules men ikke slettes.',
  'singlePurposeVehicle.confirmDialog.activate.title': 'Bekreft aktivering av SPV-funksjonalitet',
  'singlePurposeVehicle.confirmDialog.deactivate.title': 'Bekreft deaktivering av SPV-funksjonalitet',
  'singlePurposeVehicle.confirmDialog.activate.confirm': 'Ja, aktiver SPV',
  'singlePurposeVehicle.confirmDialog.deactivate.confirm': 'Ja, deaktiver SPV',
}

export const clientNavbarLabels = {
  frontPage: 'Forside',
  campaigns: 'Invester nå',
  investor: 'Investor',
  'investor.becomeInvestor': 'Bli en investor',
  'investor.howItWorks': 'Slik fungerer det',
  issuer: 'Kapital',
  'issuer.seekCapital': 'Søk kapital',
  'issuer.bookMeeting': 'Book et møte',
  'issuer.howItWorks': 'Slik fungerer det',
  aboutUs: 'Om oss',
  'aboutus.contactUs': 'Kontakt oss',
  'aboutUs.aboutUs': 'Om oss',
  'aboutUs.press': 'Presse',
  'aboutUs.blog': 'Nyheter (Blogg)',
  'aboutUs.podcast': 'Podcast',
  'aboutUs.career': 'Karriere',
}

export const weeklyMarketingEmail = {
  currentWeek: 'Uke {week}',
  'contacts.title': 'Opplasting av mottakerliste',
  'contacts.fields.status': 'Status',
  'contacts.fields.requestedCount': 'Antall opplastet',
  'contacts.fields.startedAt': 'Starttidspunkt',
  'contacts.fields.finishedAt': 'Sluttidspunkt',
  'contacts.fields.id': 'ID',
  'contacts.fields.type': 'Type',
  'contacts.fields.updatedCount': 'Antall oppdatert',
  'contacts.fields.createdCount': 'Antall opprettet',
  'contacts.fields.deletedCount': 'Antall slettet',
  'contacts.fields.erroredCount': 'Antall feil',
  'contacts.fields.errorsUrl': 'Url ved feil',

  'contacts.fields.status.completed': 'Fullført',
  'contacts.fields.status.pending': 'Pågående',
  'contacts.fields.status.errored': 'Feilet',
  'contacts.fields.status.failed': 'Kunne ikke fullføres',
  'delete.deleteWeeklyEmail': 'Slett ukens epost',
  'delete.description': `
    Dette vil slette alt innhold om denne ukens epost fra våre systemer. 
    Det vil IKKE slette epost eller kontaktliste hos Sendgrid. 
    Bør kun benyttes der feil har oppstått og innhold ikke har blitt lastet 
    opp til Sendgrid.
  `,
  'generation.createOrUpdate': `{
    alreadyGenerated, select, 
    true {Oppdater} 
    other {Generer}}
    ukens epost`,
  'generation.description': `
    {alreadyGenerated, select, true {
      Oppdater innhold i ukens epost ved å generere data på nytt fra systemet. 
      Dette vil overskrive eventuelle endringer gjort i Sendgrid.
    } other {
      Oppdater innhold i ukens epost ved å generere data på nytt fra systemet. 
      Dette vil overskrive eventuelle endringer gjort i Sendgrid.
    }}`,
  'generation.title': 'Genererings-progresjon',
  'generation.scheduledDescription': `
    Eposten kan ikke oppdateres, da status hos sendgrid viser at 
    den klar til å sendes. Hvis oppdatering ønskes, må denne statusen endres.`,
  'generation.triggeredDescription': `
    Eposten kan ikke lengre oppdateres, da status hos sendgrid 
    viser at den allerede er sendt ut.
  `,
  'generation.state.step': 'Steg {currentStep} av {totalSteps}',
  'generation.state.started': 'Påbegynt',
  'generation.state.fetchingEmissions': 'Henter aktuelle investeringstilbud',
  'generation.state.generatingOngoingCardData': 'Genererer data for hvert tilbud',
  'generation.state.generatingHtml': 'Genererer epost-HTML',
  'generation.state.creatingContactList': 'Oppretter kontaktliste for utsendelse',
  'generation.state.creatingSendgridSingleSendItem': 'Oppretter "Single Send" i Sendgrid',
  'generation.state.startingJobGeneratingAndUploadingContacts': 'Genererer mottakere og begynner opplasting',
  'generation.state.done': 'Ferdig',
  'sendgrid.title': 'Sendgrid-data',
  'sendgrid.state.scheduled': 'Planlagt',
  'sendgrid.state.triggered': 'Sendt',
  'sendgrid.state.draft': 'Kladd',
  'sendgrid.labels.id': 'ID',
  'sendgrid.labels.createdAt': 'Opprettet',
  'sendgrid.labels.name': 'Navn',
  'sendgrid.labels.status': 'Status',
  'sendgrid.linkText': 'Åpne i Sendgrid for videre behandling',
  'sendgrid.missing.description': `
  <p>Kunne ikke hente informasjon fra Sendgrid. Er sendingen slettet?</p>
  {br}
  <p>
    Hvis ukens epost ikke finnes i listen (lenke nedenfor), kan den genereres 
    på nytt ved å benytte "oppdater ukens epost"-knappen.
  </p>
  `,
  'sendgrid.missing.linkToOverviewText': 'Åpne oversikt i Sendgrid',
}

export const manualAmlChecks = {
  title: 'Manuell AML-sjekk',
  'search.dateOfBirth': 'Fødseldsato (hvis tilgjengelig)',
  'table.label.createdAt': 'Søketidspunkt',
  'table.label.name': 'Søk på navn',
  'table.label.dateOfBirth': 'Søk på fødselsdato',
  'table.label.createdBy': 'Søk gjennomført av',
  'table.label.records': 'Treff',
  'table.label.id': 'Søk-ID',
}

export const facilitatorHeaderTitles = {
  emissions: 'Kapitalutvidelsesoversikt',
  emails: 'E-poster',
  aml: 'Aml',
  emissionGenerator: 'Emisjonsgenerator',
  users: 'Brukerprofiler',
  subscriptions: 'Tegninger',
}

export const noNb = {
  ...root,
  ...prefixObjectKeys(accounting, 'accounting'),
  ...prefixObjectKeys(additionalDocumentsTable, 'additionalDocumentsTable'),
  ...prefixObjectKeys(adminApprovalContext, 'adminApprovalContext'),
  ...prefixObjectKeys(adminCompanyInformation, 'adminCompanyInformation'),
  ...prefixObjectKeys(adminLogin, 'adminLogin'),
  ...prefixObjectKeys(adminMandateSetting, 'adminMandateSetting'),
  ...prefixObjectKeys(adminNavBar, 'adminNavBar'),
  ...prefixObjectKeys(adminRiskEvaluation, 'adminRiskEvaluation'),
  ...prefixObjectKeys(adminRoles, 'adminRoles'),
  ...prefixObjectKeys(adminTopBar, 'adminTopBar'),
  ...prefixObjectKeys(auth, 'auth'),
  ...prefixObjectKeys(authError, 'authError'),
  ...prefixObjectKeys(bookMeeting, 'bookMeeting'),
  ...prefixObjectKeys(clientNavbarLabels, 'clientNavbarLabels'),
  ...prefixObjectKeys(comments, 'comments'),
  ...prefixObjectKeys(commonLabels, 'commonLabels'),
  ...prefixObjectKeys(companySearch, 'companySearch'),
  ...prefixObjectKeys(contactForms, 'contactForms'),
  ...prefixObjectKeys(contract, 'contract'),
  ...prefixObjectKeys(contractApplication, 'contractApplication'),
  ...prefixObjectKeys(countries, 'countries'),
  ...prefixObjectKeys(declarationOfCompleteness, 'declarationOfCompleteness'),
  ...prefixObjectKeys(deleteUser, 'deleteUser'),
  ...prefixObjectKeys(documentNames, 'documentNames'),
  ...prefixObjectKeys(editInvestmentOffer, 'editInvestmentOffer'),
  ...prefixObjectKeys(emailTemplates, 'emailTemplates'),
  ...prefixObjectKeys(emissionApprovalHeaders, 'emissionApprovalHeaders'),
  ...prefixObjectKeys(emissionExpectedPositiveOperatingResultEnums, 'emissionExpectedPositiveOperatingResultEnums'),
  ...prefixObjectKeys(emissionInformationGeneralFormFields, 'emissionInformationGeneralFormFields'),
  ...prefixObjectKeys(emissionNotificationBar, 'emissionNotificationBar'),
  ...prefixObjectKeys(emissionProcessContributor, 'emissionProcessContributor'),
  ...prefixObjectKeys(emissionRunwayEnums, 'emissionRunwayEnums'),
  ...prefixObjectKeys(emissions, 'emissions'),
  ...prefixObjectKeys(emissionSettings, 'emissionSettings'),
  ...prefixObjectKeys(emissionsOverview, 'emissionsOverview'),
  ...prefixObjectKeys(entrepreneurLanding, 'entrepreneurLanding'),
  ...prefixObjectKeys(facilitatorDashboard, 'facilitatorDashboard'),
  ...prefixObjectKeys(facilitatorHeaderTitles, 'facilitatorHeaderTitles'),
  ...prefixObjectKeys(footer, 'footer'),
  ...prefixObjectKeys(formidlingSection, 'formidlingSection'),
  ...prefixObjectKeys(forms, 'forms'),
  ...prefixObjectKeys(helpTextContent, 'helpTextContent'),
  ...prefixObjectKeys(helpTextHeaders, 'helpTextHeaders'),
  ...prefixObjectKeys(helpTextUsedBy, 'helpTextUsedBy'),
  ...prefixObjectKeys(homepage, 'homepage'),
  ...prefixObjectKeys(iconTitle, 'iconTitle'),
  ...prefixObjectKeys(infoMeeting, 'infoMeeting'),
  ...prefixObjectKeys(investmentOfferInformationFieldIntroText, 'investmentOfferInformationFieldIntroText'),
  ...prefixObjectKeys(investmentOfferInformationFieldLabels, 'investmentOfferInformationFieldLabels'),
  ...prefixObjectKeys(investmentOfferInformationFields, 'investmentOfferInformationFields'),
  ...prefixObjectKeys(investmentOfferInformationToolAccess, 'investmentOfferInformationToolAccess'),
  ...prefixObjectKeys(investmentOfferLinks, 'investmentOfferLinks'),
  ...prefixObjectKeys(investmentOfferPage, 'investmentOfferPage'),
  ...prefixObjectKeys(investmentOfferPresentation, 'investmentOfferPresentation'),
  ...prefixObjectKeys(investmentOfferReserveSubscription, 'investmentOfferReserveSubscription'),
  ...prefixObjectKeys(investmentOfferTeamAndOwners, 'investmentOfferTeamAndOwners'),
  ...prefixObjectKeys(investorLanding, 'investorLanding'),
  ...prefixObjectKeys(investorProfile, 'investorProfile'),
  ...prefixObjectKeys(ioInfoEmissionClient, 'ioInfoEmissionClient'),
  ...prefixObjectKeys(ioInfoEmissionFieldLabels, 'ioInfoEmissionFieldLabels'),
  ...prefixObjectKeys(ioInfoEmissionFields, 'ioInfoEmissionFields'),
  ...prefixObjectKeys(ioInfoEmissionIntroTexts, 'ioInfoEmissionIntroTexts'),
  ...prefixObjectKeys(issuerDashboard, 'issuerDashboard'),
  ...prefixObjectKeys(issuerLoanConversions, 'issuerLoanConversions'),
  ...prefixObjectKeys(issuerPresentationFields, 'issuerPresentationFields'),
  ...prefixObjectKeys(issuerPublicInvestmentOfferDashboard, 'issuerPublicInvestmentOfferDashboard'),
  ...prefixObjectKeys(issuerPublicInvestmentOfferFollowers, 'issuerPublicInvestmentOfferFollowers'),
  ...prefixObjectKeys(issuerReservedSubscriptions, 'issuerReservedSubscriptions'),
  ...prefixObjectKeys(issuerShareholders, 'issuerShareholders'),
  ...prefixObjectKeys(issuerWebApp, 'issuerWebApp'),
  ...prefixObjectKeys(keyStatistics, 'keyStatistics'),
  ...prefixObjectKeys(livePage, 'livePage'),
  ...prefixObjectKeys(manualAmlChecks, 'manualAmlChecks'),
  ...prefixObjectKeys(meetings, 'meetings'),
  ...prefixObjectKeys(notifications, 'notifications'),
  ...prefixObjectKeys(paginator, 'paginator'),
  ...prefixObjectKeys(postCampaign, 'postCampaign'),
  ...prefixObjectKeys(preMeeting, 'preMeeting'),
  ...prefixObjectKeys(publicInvestmentOfferDiscussion, 'publicInvestmentOfferDiscussion'),
  ...prefixObjectKeys(publicInvestmentOfferEmissionListFields, 'publicInvestmentOfferEmissionListFields'),
  ...prefixObjectKeys(publicInvestmentOfferSinglePurposeVehicle, 'publicInvestmentOfferSinglePurposeVehicle'),
  ...prefixObjectKeys(publishedDocuments, 'publishedDocuments'),
  ...prefixObjectKeys(queueJobs, 'queueJobs'),
  ...prefixObjectKeys(requiredEmissionDocuments, 'requiredEmissionDocuments'),
  ...prefixObjectKeys(richTextEditor, 'richTextEditor'),
  ...prefixObjectKeys(riskEvaluation, 'riskEvaluation'),
  ...prefixObjectKeys(singlePurposeVehicle, 'singlePurposeVehicle'),
  ...prefixObjectKeys(softFundingEnums, 'softFundingEnums'),
  ...prefixObjectKeys(statistics, 'statistics'),
  ...prefixObjectKeys(statisticsPage, 'statisticsPage'),
  ...prefixObjectKeys(statusCard, 'statusCard'),
  ...prefixObjectKeys(statusMessages, 'statusMessages'),
  ...prefixObjectKeys(subscriptionPeriod, 'subscriptionPeriod'),
  ...prefixObjectKeys(subscriptionsDashboard, 'subscriptionsDashboard'),
  ...prefixObjectKeys(successfulIssues, 'successfulIssues'),
  ...prefixObjectKeys(tags, 'tags'),
  ...prefixObjectKeys(teamAndNetwork, 'teamAndNetwork'),
  ...prefixObjectKeys(userProfile, 'userProfile'),
  ...prefixObjectKeys(userSearchDialog, 'userSearchDialog'),
  ...prefixObjectKeys(utm, 'utm'),
  ...prefixObjectKeys(weeklyMarketingEmail, 'weeklyMarketingEmail'),
} as Messages
